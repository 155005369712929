import { SizeTable } from "../size-table";
import { MeasurementTable } from "../measurement-table";
import { useSizeGuide } from "../use-size-guide";
import { SizeTableInfo } from "../types";
interface ContentWrapperProps {
   currentCulture: string,
   brandName: string,
   gender?: string,
   productConcept?: string,
   isKidsDepartment: boolean,
   sizeGuideCategory?: string,
}

export const ContentWrapper = (props: ContentWrapperProps) => {
   const { currentCulture, brandName, gender, productConcept, isKidsDepartment, sizeGuideCategory } = props;
   let data = useSizeGuide(currentCulture, brandName, isKidsDepartment, gender, productConcept, sizeGuideCategory);

   return (
      <>
         {data?.sizeGuide.map((s, index) => (
            <SizeTable sizeTableInfo={s} key={"sizetable" + index} />
         ))}

         {data?.measurements.map((s, index) => (
            <MeasurementTable measureTableInfo={s} key={"measurement" + index} />
         ))}
      </>
   );
};
