import { ResponsiveContext } from "@afound/react";
import { createRoot } from "react-dom/client";
import { BackInStockWrapper } from "./back-in-stock-wrapper";
import { ProductPageServerConfig } from "../types";
import { Provider } from "react-redux";
import { store } from "../../../store";

const target = document.getElementById("back-in-stock-root");
if (target) {
   const pdpConfig = INITIALDATA.newProduct as ProductPageServerConfig;

   createRoot(target).render(
      <ResponsiveContext.Provider value={{ desktop: 992 }}>
         <Provider store={store}>
            <BackInStockWrapper
               productCode={pdpConfig.productCode}
               currentMarket={pdpConfig.currentCulture}
               sizes={pdpConfig.sizes}
               prefilledEmail={pdpConfig.customerEmail}
               texts={pdpConfig.translations}
               isLoggedIn={pdpConfig.isLoggedIn}
               enableHideBackInStock={pdpConfig.enableHideBackInStock}
            />
         </Provider>
      </ResponsiveContext.Provider>
   );
}
