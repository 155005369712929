import { addResponseInterceptor } from "@afound/common";
import { Breakpoints, NotificationBar, ResponsiveContext } from "@afound/react";
import { FC, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import styles from "./app.module.scss";
import { ApplicationError } from "./components/application-error";
import { error, fatal, reset, selectNotification } from "./notification/notification-slice";
import { fetchUserInfo, selectUserInfo } from "./profile/profile-slice";
import { logout, selectSession } from "./session/session-slice";
import { selectSettings } from "./settings/settings-slice";
import { useAppDispatch, useAppSelector } from "../../store";

const breakpoints: Breakpoints = {
   desktop: +styles.desktopWidth.replace(/px$/, ""),
};

const Layout: FC<{ locale: string }> = ({ locale }) => {
   const { type, message } = useAppSelector(selectNotification);
   const { status: userFetchStatus } = useAppSelector(selectUserInfo);
   const dispatch = useAppDispatch();

   const theme = type === "none" || type === "fatal" ? undefined : type;

   useEffect(() => {
      if (theme) {
         window.scrollTo(0, 0);
      }
   }, [theme]);

   useEffect(() => {
      if (locale) {
         dispatch(fetchUserInfo(locale));
      }
   }, [locale, dispatch]);

   useEffect(() => {
      if (userFetchStatus === "error") {
         dispatch(fatal());
      }
   }, [userFetchStatus, dispatch]);

   const handleErrorClose = () => {
      dispatch(reset());
   };

   return (
      <div className={styles.app}>
         {type === "fatal" ? (
            <ApplicationError />
         ) : (
            <>
               <NotificationBar
                  theme={theme}
                  visible={!!theme}
                  message={message || ""}
                  autoCloseSeconds={6}
                  relativeToParent
                  onClose={handleErrorClose}
               />
               <main className={styles.main}>
                  <Outlet />
               </main>
            </>
         )}
      </div>
   );
};

const App: FC<{ defaultRedirectPath?: string }> = ({ defaultRedirectPath }) => {
   const dispatch = useAppDispatch();

   const {
      locale,
      contentUrls: { storePage },
      isClosingModeEnabled,
   } = useAppSelector(selectSettings);

   const { logoutStatus } = useAppSelector(selectSession);
   const navigate = useNavigate();

   useEffect(() => {
      if (!isClosingModeEnabled || !defaultRedirectPath) {
         return;
      }

      navigate(defaultRedirectPath);
   }, [isClosingModeEnabled, defaultRedirectPath]);

   useEffect(() => {
      addResponseInterceptor((resp) => {
         if (resp.status === 401) {
            dispatch(logout());
         }
      });
   }, [storePage, dispatch]);

   useEffect(() => {
      // Success is handled in logout thunk in session slice
      if (logoutStatus === "error") {
         dispatch(error("Logout failed"));
      }
   }, [logoutStatus, dispatch]);

   return (
      <ResponsiveContext.Provider value={breakpoints}>
         <Layout locale={locale} />
      </ResponsiveContext.Provider>
   );
};

export default App;
