import styles from "./size-table.module.scss";
import { SizeTableInfo } from "../types";

interface SizeTableProps {
   sizeTableInfo: SizeTableInfo;
}

export const SizeTable = (props: SizeTableProps) => {
   const { categoryTitle, sizeGroups } = props.sizeTableInfo;

   return (
      <>
         <p className={styles.title}>{categoryTitle}</p>
         {sizeGroups.map((sizeGroup, index) => (
            <div className={styles.tableWrapper}>
               <p className={styles.catTitle}>{sizeGroup.title}</p>
               <table cellSpacing={0} className={styles.table} key={"id här" + index}>
                  <thead className={styles.thead}>
                     <tr className={styles.tr}>
                        {sizeGroup.header &&
                           sizeGroup.header.map((header, index) => {
                              return (
                                 <th className={styles.th} key={"th" + index}>
                                    {header}
                                 </th>
                              );
                           })}
                     </tr>
                  </thead>

                  <tbody>
                     {sizeGroup.body &&
                        sizeGroup.body.map((body, index) => {
                           return (
                              <tr className={styles.tr} key={"tr" + index}>
                                 {body.map((size, index) => {
                                    return (
                                       <td className={styles.td} key={"td" + index}>
                                          {size}
                                       </td>
                                    );
                                 })}
                              </tr>
                           );
                        })}
                  </tbody>
               </table>
            </div>
         ))}
      </>
   );
};
