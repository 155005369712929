// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YfAt5tRfa45bJuAZOpMk{font-family:p12-001, Helvetica Narrow, Arial Narrow, Tahoma, Arial, Helvetica, sans-serif;font-size:12px;padding-left:6px;cursor:pointer}.YfAt5tRfa45bJuAZOpMk .ctbFPgAzuRZ8UPak7K6E{z-index:10}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/product-page/promotion/promotion-tooltip/promotion-tooltip.module.scss"],"names":[],"mappings":"AAAA,sBACG,yFAA0F,CAC1F,cAAe,CACf,gBAAiB,CACjB,cAAe,CAJlB,4CAOM,UAAW","sourcesContent":[".tooltipWrapper {\r\n   font-family: p12-001, Helvetica Narrow, Arial Narrow, Tahoma, Arial, Helvetica, sans-serif;\r\n   font-size: 12px;\r\n   padding-left: 6px;\r\n   cursor: pointer;\r\n\r\n   .tooltipText {\r\n      z-index: 10;\r\n   }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipWrapper": "YfAt5tRfa45bJuAZOpMk",
	"tooltipText": "ctbFPgAzuRZ8UPak7K6E"
};
export default ___CSS_LOADER_EXPORT___;
