import { createRoot } from "react-dom/client";
import { PopupWrapper } from "./popup-wrapper";
import { Provider } from "react-redux";
import { store } from "../../store";
const newsletterPopupRoot = document.getElementById("newsletter-popup-root");
if (newsletterPopupRoot) {
   createRoot(newsletterPopupRoot).render(
      <Provider store={store}>
         <PopupWrapper {...INITIALDATA.newsletterPopup} />
      </Provider>
   );
}
