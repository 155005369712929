import { Button, ButtonWrapper, LinkButton } from "@afound/react";
import styles from "./recruitment-popup.module.scss";
import { MemberRecruitmentPopupServerConfig } from "./types";
import { useEffect, useRef, useState } from "react";
import { getMemberPopupEvent } from "../tracking";
import { LoginModalEventArgs, ShowLoginModal } from "../../login/types";
import { useDelay } from "../../../hooks/use-delay";
import { usePause } from "../../../hooks/use-pause";
import { SitePopup } from "../../../components";
import { dispatchCustomEvent } from "@afound/common";

interface RecruitmentPopupProps extends MemberRecruitmentPopupServerConfig {}

export const RecruitmentPopup = (props: RecruitmentPopupProps) => {
   const {
      imageUrl,
      popupDelaySeconds,
      texts: { heading, description, registerCta, loginCta },
   } = props;

   const popupRef = useRef<HTMLDivElement>(null);
   const [show, setShow] = useState(false);

   const [paused, setPaused] = usePause(3600, "memberPopup");
   const delay = useDelay(!paused, popupDelaySeconds, "memberPopup");

   useEffect(() => {
      if (delay === 0) {
         setShow(true);
      }
   }, [delay]);

   const showLoginModal = (action: "login" | "register") => {
      setShow(false);
      setPaused();
      dispatchCustomEvent<LoginModalEventArgs>(popupRef.current!, ShowLoginModal, { defaultTab: action });
   };

   const handleRegisterClick = () => {
      showLoginModal("register");
      window.dataLayer.push(getMemberPopupEvent("Be a member", "registeraccount:modal"));
   };

   const handleLoginClick = () => {
      showLoginModal("login");
      window.dataLayer.push(getMemberPopupEvent("Log in", "login:modal"));
   };

   const handleClose = () => {
      setShow(false);
      setPaused();
      window.dataLayer.push(getMemberPopupEvent("Closed GuestPopUp", "closed"));
   };

   return !paused ? (
      <SitePopup type="memberPopup" visible={show} onClose={handleClose}>
         <div ref={popupRef} className={styles.content}>
            <img src={imageUrl} />
            <h1 className={styles.heading}>{heading}</h1>
            <div className={styles.description}>{description}</div>
            <ButtonWrapper>
               <Button theme="member" onClick={handleRegisterClick}>
                  {registerCta}
               </Button>
               <LinkButton theme="underlined" onClick={handleLoginClick}>
                  {loginCta}
               </LinkButton>
            </ButtonWrapper>
         </div>
      </SitePopup>
   ) : null;
};
