// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AN9INvUxElNvMXFfYtkt{position:relative;display:flex;justify-content:center;align-items:center;font-size:20px;text-align:center;font-weight:300}.cnsNM9yC_9D9XYaFmfze{position:absolute;right:12px}.cnsNM9yC_9D9XYaFmfze svg{height:16px;width:16px}.uAWYbKQAw28AgXIgkn_X{margin:0 20px}._5we7fg3b1HwinjABkDFJ{max-width:160px}@media (min-width: 992px){.AN9INvUxElNvMXFfYtkt{font-size:30px}.cnsNM9yC_9D9XYaFmfze{right:4px}._5we7fg3b1HwinjABkDFJ{max-width:240px}}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/my-pages/home/recent-products/recent-products.module.scss","webpack://./Assets/src/features/my-pages/main.scss"],"names":[],"mappings":"AAEA,sBACG,iBAAkB,CAClB,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,cAAe,CACf,iBAAkB,CAClB,eAAgB,CAOnB,sBACG,iBAAkB,CAClB,UAAW,CAFd,0BASM,WAAY,CACZ,UAAW,CACb,sBAID,aClBgB,CDmBlB,uBAGE,eAAgB,CAxBhB,0BATH,sBAUM,cAAe,CAIrB,sBAKM,SAAU,CAahB,uBAIM,eAAgB,CAxBrB","sourcesContent":["@import \"../../main.scss\";\r\n\r\n.heading {\r\n   position: relative;\r\n   display: flex;\r\n   justify-content: center;\r\n   align-items: center;\r\n   font-size: 20px;\r\n   text-align: center;\r\n   font-weight: 300;\r\n\r\n   @media (min-width: $desktop) {\r\n      font-size: 30px;\r\n   }\r\n}\r\n\r\n.dsaTrigger {\r\n   position: absolute;\r\n   right: 12px;\r\n\r\n   @media (min-width: $desktop) {\r\n      right: 4px;\r\n   }\r\n\r\n   svg {\r\n      height: 16px;\r\n      width: 16px;\r\n   }\r\n}\r\n\r\n.wrapper {\r\n   margin: 0 $page-spacing;\r\n}\r\n\r\n.productCard {\r\n   max-width: 160px;\r\n\r\n   @media (min-width: $desktop) {\r\n      max-width: 240px;\r\n   }\r\n}\r\n","$color-brand: #bb2058;\r\n$color-member: #051cf7;\r\n$color-border: #e5e5e5;\r\n\r\n$color-faded-text: #979797;\r\n\r\n$color-bg-pink: #ff00001a;\r\n$color-bg-green: #d2e6db;\r\n$color-bg-light-gray: #f7f7f7;\r\n$color-bg-light-blue: #b8cbf4;\r\n\r\n$desktop: 992px;\r\n\r\n$page-spacing: 20px;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "AN9INvUxElNvMXFfYtkt",
	"dsaTrigger": "cnsNM9yC_9D9XYaFmfze",
	"wrapper": "uAWYbKQAw28AgXIgkn_X",
	"productCard": "_5we7fg3b1HwinjABkDFJ"
};
export default ___CSS_LOADER_EXPORT___;
