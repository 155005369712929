// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sXBFXw7ss0ys_jglfP51{max-width:860px;margin:0 auto;text-align:center;padding:15px 25px 0}.T_Kgr2at6qEoZEIAmwjn{max-width:160px}.En9DiRxndDFOAof8wT0n{height:200px}@media (min-width: 992px){.T_Kgr2at6qEoZEIAmwjn{max-width:200px}.En9DiRxndDFOAof8wT0n{height:250px}}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/upselling-popup/upselling-popup.module.scss"],"names":[],"mappings":"AAEA,sBACG,eAAgB,CAChB,aAAc,CACd,iBAAkB,CAClB,mBAAoB,CACtB,sBAGE,eAAgB,CAOnB,sBACG,YAAa,CANb,0BAHH,sBAIM,eAAgB,CAItB,sBAIM,YAAa,CANlB","sourcesContent":["@import \"main.scss\";\r\n\r\n.upsellingModal {\r\n   max-width: 860px;\r\n   margin: 0 auto;\r\n   text-align: center;\r\n   padding: 15px 25px 0;\r\n}\r\n\r\n.card {\r\n   max-width: 160px;\r\n\r\n   @media (min-width: $desktop) {\r\n      max-width: 200px;\r\n   }\r\n}\r\n\r\n.cardImageContainer {\r\n   height: 200px;\r\n\r\n   @media (min-width: $desktop) {\r\n      height: 250px;\r\n   }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upsellingModal": "sXBFXw7ss0ys_jglfP51",
	"card": "T_Kgr2at6qEoZEIAmwjn",
	"cardImageContainer": "En9DiRxndDFOAof8wT0n"
};
export default ___CSS_LOADER_EXPORT___;
