// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MAFyi4qed44Eap3BBq3M{font-family:p12-desc, \"Helvetica Narrow\", \"Arial Narrow\", Tahoma, Arial, Helvetica, sans-serif;font-weight:bold;font-size:16px;text-transform:none;color:#000;line-height:1}@media (min-width: 992px){.MAFyi4qed44Eap3BBq3M{font-size:18px}}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/product-listing/filter-clear-button/filter-clear-button.module.scss"],"names":[],"mappings":"AAAA,sBACG,8FAA+F,CAC/F,gBAAiB,CACjB,cAAe,CACf,mBAAoB,CACpB,UAAW,CACX,aAAc,CAEd,0BARH,sBASM,cAAe,CAEnB","sourcesContent":[".button {\r\n   font-family: p12-desc, \"Helvetica Narrow\", \"Arial Narrow\", Tahoma, Arial, Helvetica, sans-serif;\r\n   font-weight: bold;\r\n   font-size: 16px;\r\n   text-transform: none;\r\n   color: #000;\r\n   line-height: 1;\r\n\r\n   @media(min-width: 992px) {\r\n      font-size: 18px;\r\n   }\r\n }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "MAFyi4qed44Eap3BBq3M"
};
export default ___CSS_LOADER_EXPORT___;
