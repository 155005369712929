import { waitForElement } from "../../../shared/dom";
import { DsaStep1 } from "./dsa-step1";
import { DsaStep2 } from "./dsa-step2";
import { DsaStep3 } from "./dsa-step3";
import { DsaStep4 } from "./dsa-step4";
import { DsaReportingElements } from "./types";

const DsaReporting = () => {
   waitForElement(".dsa-page").then((dsaPage) => {
      if (!dsaPage) {
         return;
      }

      const elements: DsaReportingElements = {
         backButton: dsaPage.querySelector(".dsa-page__back") as HTMLButtonElement,
         step1Container: dsaPage.querySelector("#dsa-flow-step1") as HTMLElement,
         step1Form: dsaPage.querySelector("#dsa-flow-step1 form") as HTMLFormElement,
         reportReasonInput: dsaPage.querySelector("#dsa-flow-step1 textarea[name='reason']") as HTMLTextAreaElement,
         reportReasonInputError: dsaPage.querySelector(
            "#dsa-flow-step1 textarea[name='reason'] + span.error"
         ) as HTMLSpanElement,
         step2Container: dsaPage.querySelector("#dsa-flow-step2") as HTMLElement,
         reportReasonSummaryContainer: dsaPage.querySelector(".dsa-page__reason-summary-container") as HTMLElement,
         step2Submit: dsaPage.querySelector("#dsa-flow-step2 .dsa-page__submit") as HTMLButtonElement,
         step3Container: dsaPage.querySelector("#dsa-flow-step3") as HTMLElement,
         step3Form: dsaPage.querySelector("#dsa-flow-step3 form") as HTMLFormElement,
         step3AccordionHeading: dsaPage.querySelector(".dsa-page__accordion-heading") as HTMLButtonElement,
         nameInput: dsaPage.querySelector("#dsa-flow-step3 input[name='fullName']") as HTMLInputElement,
         emailInput: dsaPage.querySelector("#dsa-flow-step3 input[name='email']") as HTMLInputElement,
         nameInputError: dsaPage.querySelector(
            "#dsa-flow-step3 input[name='fullName'] + span.error"
         ) as HTMLSpanElement,
         emailInputError: dsaPage.querySelector("#dsa-flow-step3 input[name='email'] + span.error") as HTMLSpanElement,
         productUrlInput: dsaPage.querySelector("#dsa-flow-step3 input[name='productUrl']") as HTMLInputElement,
         offerCodeInput: dsaPage.querySelector("#dsa-flow-step3 input[name='offerCode']") as HTMLInputElement,
         step3ServerError: dsaPage.querySelector("#dsa-flow-step3 .server-error") as HTMLElement,
         step3Submit: dsaPage.querySelector("#dsa-flow-step3 .dsa-page__submit") as HTMLButtonElement,
         step4Container: dsaPage.querySelector("#dsa-flow-step4") as HTMLElement,
      };

      const steps = [
         () => DsaStep1({ ...elements, next }),
         () => DsaStep2({ ...elements, next }),
         () => DsaStep3({ ...elements, next }),
         () => DsaStep4(),
      ];

      const cleanupQueue: Function[] = [];

      let stepIndex = 0;

      const navigate = (from: HTMLElement, to: HTMLElement, step: number) => {
         from.classList.remove("active");
         to.classList.add("active");

         elements.backButton.classList.toggle("visible", step >= 1);
         if (step > 2) elements.backButton.classList.remove("visible");

         const stepInitializer = steps[step];
         const cleanup = stepInitializer();

         const prevCleanup = cleanupQueue.pop();
         prevCleanup && prevCleanup();
         cleanupQueue.push(cleanup);
      };

      const next = () =>
         navigate(
            elements[`step${stepIndex + 1}Container` as keyof typeof elements],
            elements[`step${stepIndex + 2}Container` as keyof typeof elements],
            ++stepIndex
         );

      const prev = () =>
         navigate(
            elements[`step${stepIndex + 1}Container` as keyof typeof elements],
            elements[`step${stepIndex}Container` as keyof typeof elements],
            --stepIndex
         );

      elements.backButton.addEventListener("click", () => prev());

      steps[0]();
   });
};

DsaReporting();
