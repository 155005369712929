import { ReviewModel } from "./types";

const style = `
<style>

.reviews-time{
   display: inline-block;
   color: #999999;
   font-size: 12px;
   margin-left: 8px;
   }

.reviews-text {
   display: block;
   font-size: 16px;
}

.reviews-items {
   padding-top: 20px;
}

</style>`;

export class ProductReviews extends HTMLElement {
   pageNumber: number;
   pageSize: number;

   constructor() {
      super();
      const template = document.getElementById("product-reviews") as HTMLTemplateElement;
      template.insertAdjacentHTML("beforebegin", style);
      this.replaceChildren(template.content.cloneNode(true));
      this.pageNumber = 1;
      this.pageSize = 10;
   }
   static get observedAttributes() {
      return ["productRelationGuid", "culture"];
   }

   get productRelationGuid() {
      return this.getAttribute("productRelationGuid")!;
   }
   set productRelationGuid(value: string) {
      this.setAttribute("productRelationGuid", value);
   }

   get culture() {
      return this.getAttribute("culture")!;
   }
   set culture(value: string) {
      this.setAttribute("culture", value);
   }

   connectedCallback() {
      document.getElementById('wc-load-more')?.addEventListener('click', () => {
         this.getReviews();
      });

      this.getReviews();
   }

   attributeChangedCallback(name: string, oldValue: string, newValue: string) {
      if (oldValue === newValue) {
         return;
      }

      switch (name) {
         case "productRelationGuid":
            this.productRelationGuid = newValue;
            break;
         
         case "culture":
            this.culture = newValue;
            break;
      
         default:
            break;
      }
   }

   getReviews() {
      return new Promise((res, rej) => {
         fetch(`/api/v1/ProductReviews?productRelationGuid=${this.productRelationGuid}&culture=${this.culture}&pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`)

            .then(data => data.json())
            .then((json) => {
               
               if (!json.length || json.length < this.pageSize) {
                  document.getElementById('wc-load-more')?.remove();
               }

               //Update paging
               this.pageNumber++;

               this.mapTemplateItems(json);
               res(json);
            })
            .catch((error) => rej(error));
      });
   }
   mapTemplateItems(models: ReviewModel[]) {
      const filledStarsvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      filledStarsvg.setAttribute('class', 'rating-stars rating-stars--filled')

      const emptyStarSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      emptyStarSvg.setAttribute('class', 'rating-stars')

      const wrapperElement = document.createElement("div");
      wrapperElement.classList.add("wrapper-element");

      for (var i = 0; i < models.length; i++) {
         const reviewElement = document.createElement("div");
         const starsElement = document.createElement("div");
         for (var j = 1; j <= 5; j++) {
            let rating = parseInt(models[i].rating);
            if (j <= rating) {
               starsElement.appendChild(filledStarsvg.cloneNode(true));
            } else {
               starsElement.appendChild(emptyStarSvg.cloneNode(true));
            }
         }

         reviewElement.innerHTML = `
         <div class="reviews-items">
            ${starsElement.innerHTML}
            <span class="reviews-time">${models[i].timeAgo ?? ''}</span>
            <span class="reviews-title">${models[i].title}</span>
            <span class="reviews-text">${models[i].text}</span>
         <div>`
         wrapperElement.appendChild(reviewElement);

      }
      this.appendChild(wrapperElement);
   }
}

customElements.define("product-reviews", ProductReviews);
