import { FooterElements } from "./types";

type SharedElements = Pick<FooterElements, "backToTopButton">;

type SharedProps = SharedElements;

const Shared = (props: SharedProps) => {
   const { backToTopButton } = props;

   const handleBackToTopClick = () => {
      window.scrollTo(0, 0);
   };

   backToTopButton?.addEventListener("click", handleBackToTopClick);

   return () => {
      backToTopButton?.removeEventListener("click", handleBackToTopClick);
   };
};

export default Shared;
