import { CartWrapper, Loader, Modal } from "@afound/react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useEffect, useRef, useState } from "react";

import styles from "./cart-modal.module.scss";
import { useCart } from "../use-cart";
import { Cart, CartProduct, WishlistItemRef } from "@afound/types";
import { CartServerConfig } from "../types";
import { useAppDispatch, useAppSelector } from "../../../store";
import { addToWishlistFromCart, removeFromWishlist, selectWishlistModel } from "../../../store/wishlist-slice";
import { ApiResponse, getFaqGtmEvent } from "@afound/common";
import { useRecommendations } from "../use-recommendations";
import { useBackInStock } from "../../back-in-stock/use-back-in-stock";
import { getAddToCartEvent, getMinicartEvent, getRemoveFromCartEvent } from "../tracking";
import { getBackInStockSignupEvent } from "../../back-in-stock/tracking";
import { dispatchCustomEvent } from "../../../shared/dom";
import { WishlistOpenEvent } from "../../wishlist/types";
import { HeaderCartCountEvent, HeaderCartCountEventArgs } from "../../header/types";
import { selectModals, show } from "../../../store/modals-slice";
import { useCartEcomId } from "../use-cart-ecom-id";
import { ShowLoginModal } from "../../login/types";

interface CartModalProps extends CartServerConfig {
   modalToggle: HTMLElement;
   enableEcomIdV2?: boolean;
}

export const CartModal = (props: CartModalProps) => {
   const { modalToggle, userEmail, paymentLogos, userLoggedIn, contactUsPageUrl, enableEcomIdV2, ...rest } = props;

   const dispatch = useAppDispatch();
   const { items: wishlistItems } = useAppSelector(selectWishlistModel);
   const { chat, dsaPopup } = useAppSelector(selectModals);

   const scrollLockRef = useRef<HTMLDivElement>(null);

   const [showCart, setShowCart] = useState(false);
   const [cart, setCart] = useState<Cart>();
   const [chatAvailable, setChatAvailable] = useState(false);

   const [cartError, setCartError] = useState<string>();
   const [couponError, setCouponError] = useState<string>();

   const { getCart, removeFromCart, updateQuantity, updateSize, applyCoupon, removeCoupon } = useCart();
   const { addNotificationSubscriber } = useBackInStock();
   const ecomIdRenderer = enableEcomIdV2 ? useCartEcomId(cart) : undefined;

   const updateCartCount = (count: number) => {
      dispatchCustomEvent<HeaderCartCountEventArgs>(scrollLockRef.current!, HeaderCartCountEvent, { count });
   };

   const handleHeaderCartButtonClick = () => setShowCart(true);

   const handleCartResponse = (
      response: ApiResponse<Cart> | undefined,
      onSuccess?: (updatedCart: Cart) => void,
      onError?: (errorMsg: string) => void
   ) => {
      if (!response) {
         setCartError(props.texts.generalError);
         return false;
      }

      const { result, errorMessage } = response;

      if (result && !errorMessage) {
         onSuccess && onSuccess(result);

         setTimeout(() => {
            setCart(result);
            if (cart?.totalItemsCount !== result.totalItemsCount) {
               updateCartCount(result.totalItemsCount);
            }
         });

         return true;
      } else {
         (onError || setCartError)?.call(this, errorMessage || "");
         return false;
      }
   };

   useEffect(() => {
      modalToggle.addEventListener("click", handleHeaderCartButtonClick);
      return () => modalToggle.removeEventListener("click", handleHeaderCartButtonClick);
   }, []);

   useEffect(() => {
      const fetchCart = async () => {
         const resp = await getCart();
         handleCartResponse(resp);
      };

      if (showCart) {
         const chatTrigger = document.querySelector("#chat-root button") as HTMLButtonElement;
         setChatAvailable(!!chatTrigger);

         disableBodyScroll(scrollLockRef.current!);
         fetchCart();
      } else {
         enableBodyScroll(scrollLockRef.current!);
      }
   }, [showCart]);

   const [recommendations, recommendationsError] = useRecommendations(showCart);
   if (recommendationsError && recommendationsError !== cartError) {
      setCartError(recommendationsError);
   }

   const handleSellerLinkClick = (sellerName: string) =>
      window.dataLayer.push(getMinicartEvent("More from sellers", sellerName));

   const handleCouponFormToggle = (isOpen: boolean) => {
      if (isOpen) {
         window.dataLayer.push(getMinicartEvent("Coupon", "I have a coupon"));
      }
   };

   const handleQuantityChange = async (offerCode: string, quantity: number) => {
      const resp = await updateQuantity({ offerCode, quantity });

      return handleCartResponse(resp, (updated: Cart) => {
         const isAdd = updated.totalItemsCount > cart!.totalItemsCount;

         const product = (isAdd ? updated : cart)?.shops
            .reduce<CartProduct[]>((prev, cur) => [...prev, ...cur.products], [])
            .find((p) => p.offerCode === offerCode);

         if (product) {
            window.dataLayer.push(getMinicartEvent("Product", isAdd ? "+" : "-"));
            window.dataLayer.push(
               isAdd ? getAddToCartEvent(updated, product) : getRemoveFromCartEvent(updated, product)
            );
         }
      });
   };

   const handleSizeChange = async (oldOfferId: string, newOfferId: string, quantity: number, sizeName: string) => {
      const resp = await updateSize({ oldOfferId, newOfferId, quantity, sizeName });

      return handleCartResponse(resp, () => {
         window.dataLayer.push(getMinicartEvent("Size changed", sizeName));
      });
   };

   const handleRemove = async (offerCode: string) => {
      const resp = await removeFromCart(offerCode);

      return handleCartResponse(resp, (updated: Cart) => {
         const removed = cart?.shops
            .reduce<CartProduct[]>((prev, cur) => [...prev, ...cur.products], [])
            .find((p) => p.offerCode === offerCode);

         if (removed) {
            window.dataLayer.push(getMinicartEvent("Product", "removed product"));
            window.dataLayer.push(getRemoveFromCartEvent(updated, removed));
         }
      });
   };

   const handleCouponSubmit = async (couponCode: string) => {
      const resp = await applyCoupon({ couponCode });

      return handleCartResponse(
         resp,
         () => {
            window.dataLayer.push(getMinicartEvent("Coupon", "coupon applied:success"));
         },
         (errorMsg) => {
            window.dataLayer.push(getMinicartEvent("Coupon", "coupon applied:error"));
            setCouponError(errorMsg);
         }
      );
   };

   const handleCouponRemove = async (couponCode: string) => {
      const resp = await removeCoupon(couponCode);
      return handleCartResponse(resp, undefined, setCouponError);
   };

   const handleWishlistClick = async (type: "add" | "remove", itemRef: WishlistItemRef, offerCode: string) => {
      // Since wishlist items aren't size-specific, i.e. a product is considered to be in the wishlist as soon
      // as _any_ size is present, it's not guaranteed that the wishlist variant being removed actually corresponds
      // to the the variant saved in the wishlist. Hence we need to do the following lookup to get the correct
      // variant ID to remove. Going forward we might want to tweak the wishlist logic to require only productCode
      // when removing wishlist items, and not a specific variant ID.
      const findRemovedVariant = () => wishlistItems.find((item) => item.productCode === itemRef.productCode)!;

      if (!userLoggedIn) {
         dispatchCustomEvent(modalToggle, WishlistOpenEvent);
         return false;
      }

      const action =
         type === "add"
            ? addToWishlistFromCart({ ...itemRef })
            : type === "remove"
            ? removeFromWishlist(findRemovedVariant())
            : undefined;

      if (!action) {
         return false;
      }

      const {
         meta: { requestStatus },
         payload,
      } = await dispatch(action);

      const isSuccess = requestStatus === "fulfilled";

      if (!isSuccess) {
         setCartError(payload as string);
      } else if (type === "add") {
         setTimeout(async () => {
            await handleRemove(offerCode);
         }, 1000);
      }

      return isSuccess;
   };

   const handleNotifyClick = async (variantId: string, offerCode: string) => {
      if (!userEmail) {
         return false;
      }

      const success = await addNotificationSubscriber({ email: userEmail, variantId, marketCulture: cart!.market });

      if (success) {
         setTimeout(async () => {
            await handleRemove(offerCode);
         }, 1000);

         window.dataLayer.push(getBackInStockSignupEvent("submitted", variantId));
      }

      return success;
   };

   const handleFaqLinkClick = () => window.dataLayer.push(getFaqGtmEvent("minicart"));

   const handleContactClick = () => {
      if (chatAvailable) {
         dispatch(show("chat"));
      } else {
         window.open(contactUsPageUrl, "_blank");
      }
   };

   const handleDsaIconClick = () => dispatch(show("dsaPopup"));

   const handleCartClose = () => {
      window.dataLayer.push(getMinicartEvent("Exit cart", "Close cart"));

      setCartError(undefined);
      setCouponError(undefined);
      setShowCart(false);
   };

   const handleCtaClick = () => window.dataLayer.push(getMinicartEvent("Exit cart", "Continue to checkout"));

   const paymentLogosWithClasses = paymentLogos.map((p) => {
      if (p.title !== "Visa") {
         return p;
      }

      return {
         ...p,
         className: styles.visaLogo,
      };
   });
   const openLoginModal = () => {
      handleCartClose();
      const defaultTab = "login";
      dispatchCustomEvent(scrollLockRef.current!, ShowLoginModal, { defaultTab });
   };

   const handlePromotionMessageClick = (event: React.MouseEvent<HTMLDivElement>) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains("inline-link")) {
         openLoginModal();
      }
   };

   return (
      <Modal
         ref={scrollLockRef}
         modalRootId="cart-root"
         className={styles.modalWrapper}
         visible={showCart}
         includePadding={false}
         disableStackingContext={{
            active: showCart,
            targetElementQuery: "header",
            zIndexValue: 201,
         }}
         disableOutsideClickClose={chat.visible || dsaPopup.visible}
         onClose={handleCartClose}
      >
         {!cart && !cartError ? (
            <Loader visible />
         ) : (
            <CartWrapper
               {...rest}
               className={styles.cartWrapper}
               userLoggedIn={userLoggedIn}
               helpContactMode={chatAvailable ? "chat" : "contactuslink"}
               cartError={cartError}
               couponError={couponError}
               cartModel={cart}
               paymentLogos={paymentLogosWithClasses}
               renderEcomIdPlaceholder={ecomIdRenderer}
               recommendations={recommendations}
               onSellerLinkClick={handleSellerLinkClick}
               onCouponFormToggle={handleCouponFormToggle}
               onQuantityChange={handleQuantityChange}
               onSizeChange={handleSizeChange}
               onRemove={handleRemove}
               onCouponSubmit={handleCouponSubmit}
               onCouponRemove={handleCouponRemove}
               onWishlistClick={handleWishlistClick}
               onNotifiyClick={handleNotifyClick}
               onFaqLinkClick={handleFaqLinkClick}
               onContactClick={handleContactClick}
               onContinueShoppingClick={() => {}}
               onDsaIconClick={handleDsaIconClick}
               onCtaClick={handleCtaClick}
               onCouponMessageClick={openLoginModal}
               onPromotionMessageClick={handlePromotionMessageClick}
            />
         )}
      </Modal>
   );
};
