import { Device, currentDevice } from "@afound/common";

export type ModalType =
   | "chat"
   | "dsaPopup"
   | "newsletterPopup"
   | "memberPopup"
   | "welcomePopup"
   | "backInStockPopup"
   | "upsellingPopup";

export const currentDeviceDefault = (onChange?: (device: Device) => void) => currentDevice({ desktop: 992 }, onChange);
