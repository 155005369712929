import { useCallback } from "react";
import { UpsellingProductsResult } from "./types";
import { get, post } from "@afound/common";

import ai from "../../ai";
import { AddToCartRequest, AddToCartResponse } from "../product-page/types";

const isCartResponse = (response: AddToCartResponse | string[] | undefined): response is AddToCartResponse =>
   !!(response as AddToCartResponse)?.result;

export const useUpselling = () => {
   const fetchUpsellingProducts = async (variantId: string, gender: string, sizeInfo: string) => {
      try {
         const baseUrl = "/api/product/upselling";
         return await get<UpsellingProductsResult>(
            `${baseUrl}?ProductKey=${variantId}&Gender=${gender}&SizeInfo=${sizeInfo}`
         );
      } catch (error) {
         ai.trackException({ exception: error as Error, properties: { feature: "upselling-modal" } });
         return undefined;
      }
   };

   const addToCart = async (
      offerCode: string,
      promotionId: string,
      promotionName: string,
      promotionCreative: string
   ) => {
      try {
         const baseUrl = "/api/cart";

         const data = {
            offerCode: offerCode,
            recommendationPanel: promotionName,
            promotionId: promotionId,
            promotionName: promotionName,
            promotionCreative: promotionCreative,
         };

         const response = await post<AddToCartRequest, AddToCartResponse | string[]>(baseUrl, data);

         if (!isCartResponse(response)) {
            return;
         }

         return response;
      } catch (error) {
         ai.trackException({ exception: error as Error, properties: { feature: "upselling-modal" } });
         return undefined;
      }
   };

   return {
      fetchUpsellingProducts: useCallback(fetchUpsellingProducts, []),
      addToCart: useCallback(addToCart, []),
   };
};
