// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VrOoz0ZMrqf0LjeC7gTT{border-bottom:1px solid #d9d9d9}.SAcWvNRdsG8e0f3ydtsc{font-family:p12-text, Helvetica Narrow, Arial Narrow, Tahoma, Arial, Helvetica, sans-serif;font-size:20px;font-weight:300;line-height:1;margin-bottom:7px;padding-left:20px;padding-right:20px;padding-top:10px;text-transform:uppercase}.EnoznW0VF6CwMsICvziH{padding-bottom:10px}.C9P3EX3F0HvEi_xTR3tI{box-shadow:0px 4px 10px 0px rgba(0,0,0,0.1);position:absolute;background:white;margin-left:auto;margin-right:auto;width:100%}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/search/search-results/search-results.module.scss"],"names":[],"mappings":"AAAA,sBACG,+BAAgC,CAClC,sBAGE,0FAA2F,CAC3F,cAAe,CACf,eAAgB,CAChB,aAAc,CACd,iBAAkB,CAClB,iBAAkB,CAClB,kBAAmB,CACnB,gBAAiB,CACjB,wBAAyB,CAC3B,sBAGE,mBAAoB,CAEpB,sBACG,2CAA2C,CAC3C,iBAAkB,CAClB,gBAAiB,CACjB,gBAAiB,CACjB,iBAAkB,CAClB,UAAW","sourcesContent":[".highlights {\r\n   border-bottom: 1px solid #d9d9d9;\r\n}\r\n\r\n.highlightsHeading {\r\n   font-family: p12-text, Helvetica Narrow, Arial Narrow, Tahoma, Arial, Helvetica, sans-serif;\r\n   font-size: 20px;\r\n   font-weight: 300;\r\n   line-height: 1;\r\n   margin-bottom: 7px;\r\n   padding-left: 20px;\r\n   padding-right: 20px;\r\n   padding-top: 10px;\r\n   text-transform: uppercase;\r\n}\r\n\r\n.resultsContainer {\r\n   padding-bottom: 10px;\r\n\r\n   &--is-ab-test {\r\n      box-shadow: 0px 4px 10px 0px rgba(0,0,0,.1);\r\n      position: absolute;\r\n      background: white;\r\n      margin-left: auto;\r\n      margin-right: auto;\r\n      width: 100%;\r\n   }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlights": "VrOoz0ZMrqf0LjeC7gTT",
	"highlightsHeading": "SAcWvNRdsG8e0f3ydtsc",
	"resultsContainer": "EnoznW0VF6CwMsICvziH",
	"resultsContainer--is-ab-test": "C9P3EX3F0HvEi_xTR3tI"
};
export default ___CSS_LOADER_EXPORT___;
