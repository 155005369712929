import { Button, Loader } from "@afound/react";
import { FC, useEffect, useMemo } from "react";
import { Container } from "../components/container";
import { Hero } from "../components/hero";
import { NavLinkModel, NavList } from "../components/nav-list";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectUserInfo } from "../profile/profile-slice";
import { logout } from "../session/session-slice";
import { selectSettings, selectTranslations } from "../settings/settings-slice";
import { Contact } from "./contact";
import styles from "./home.module.scss";
import { RecentProducts } from "./recent-products";
import { aiFeatureName } from "../types";
import { MemberZone } from "./member-zone";
import ai from "../../../ai";

const Savings: FC<{ amount: number; currency: string; locale: string; template: string }> = ({
   amount,
   currency,
   locale,
   template = "",
}) => {
   const templateWithCurrency = template.replace("{currency}", currency);
   const amountTag = "{amount}";
   const amountIndex = templateWithCurrency.indexOf(amountTag);

   const formattedAmount = new Intl.NumberFormat(locale, {
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
   }).format(amount);

   return (
      <div className={styles.savings}>
         {templateWithCurrency.substring(0, amountIndex)}
         <span className={styles.savingsAmount}>{formattedAmount}</span>
         {templateWithCurrency.substring(amountIndex + amountTag.length)}
      </div>
   );
};

const Home = () => {
   const {
      locale,
      country,
      areaRoutes,
      contentUrls,
      isMyDealsEnabled,
      enableEcomIdV2,
      isMemberZoneEnabled,
      isFlexibleReturnFeeEnabled,
   } = useAppSelector(selectSettings);
   const {
      profile: { firstName, totalSavings = 0 },
      status,
      subscription,
   } = useAppSelector(selectUserInfo);

   const {
      profileSection,
      ordersAndMessagesSection,
      dealsSection,
      profile: { logOut },
      wishlistSection,
      myReturnFeeSection,
      home: { greeting, totalSavings: totalSavingsTemplate, contactUs, returnPolicy, ...homeTexts },
   } = useAppSelector(selectTranslations);

   const dispatch = useAppDispatch();

   const handleLogoutClick = () => dispatch(logout());

   const navLinks = useMemo(() => {
      const links: NavLinkModel[] = [
         {
            text: profileSection,
            url: areaRoutes.find((a) => a.area === "profile")?.path || "",
            icon: "User",
         },
         {
            text: ordersAndMessagesSection,
            url: areaRoutes.find((a) => a.area === "orders")?.path || "",
            icon: "Box",
         },
         {
            text: wishlistSection,
            url: "my-favorites",
            icon: "Heart",
         },
      ];

      if (isMyDealsEnabled) {
         links.push({
            text: dealsSection,
            url: "deals",
            icon: "Deals",
         });
      }

      if (isFlexibleReturnFeeEnabled) {
         links.push({
            text: myReturnFeeSection,
            url: "my-return-fee",
            icon: "Return",
         });
      }

      return links;
   }, [profileSection, areaRoutes, ordersAndMessagesSection, dealsSection, wishlistSection, isMyDealsEnabled]);

   useEffect(() => {
      if (status !== "success" || !enableEcomIdV2) {
         return;
      }

      const intervalId = setInterval(() => {
         if (window.ecomid && window.ecomid.widgets) {
            try {
               window.ecomid.widgets.info?.load();
               window.ecomid.widgets.user?.load();
            } catch (err) {
               ai.trackException({ exception: err as Error, properties: { feature: aiFeatureName } });
            }

            clearInterval(intervalId);
         }
      }, 50);
   }, [status]);

   return status === "success" ? (
      <>
         <Hero className={styles.greeting} title={`${greeting} ${firstName}`}>
            {totalSavings > 0 && (
               <Savings
                  amount={totalSavings}
                  currency={country.isoCurrencySymbol}
                  locale={locale}
                  template={totalSavingsTemplate}
               />
            )}
         </Hero>
         <Container className={styles.container}>
            <div className={styles.content}>
               <div className={styles.navList}>
                  <NavList links={navLinks} />
               </div>

               {enableEcomIdV2 && (
                  <div className={styles.ecomidWidgetContainer}>
                     <div data-location="profile" className={`ecomid-widget-container`}>
                        <div className="widget-container"></div>
                     </div>
                  </div>
               )}

               {isMemberZoneEnabled && (
                  <div className={styles.memberZone}>
                     <MemberZone subscription={subscription} texts={homeTexts} />
                  </div>
               )}

               <div className={styles.recent}>
                  <RecentProducts locale={locale} currency={country.isoCurrencySymbol} />
               </div>
               <div className={styles.contact}>
                  <Contact heading={contactUs!} returnPolicy={returnPolicy} links={contentUrls} texts={homeTexts} />
               </div>
               <div className={styles.logout}>
                  <Button theme="secondary" onClick={handleLogoutClick}>
                     {logOut}
                  </Button>
               </div>
            </div>
         </Container>
      </>
   ) : (
      <div className={styles.empty}>
         <Loader visible />
      </div>
   );
};

export default Home;
