import { H1, useBreakpoints } from "@afound/react";
import classNames from "classnames";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { Icon, IconType } from "../icon";
import styles from "./hero.module.scss";

export interface HeroProps {
   title: string;
   className?: string;
   icon?: IconType;
   backLink?: string;
   children?: ReactNode;
}

export const Hero = (props: HeroProps) => {
   const { title, backLink, children, className } = props;
   const { current } = useBreakpoints(true);
   const titleClasses = classNames(styles.title, className);
   const backIconSize = current === "mobile" ? 16 : 24;

   return (
      <div className={styles.hero}>
         <div className={styles.main}>
            <div className={styles.top}>
               {backLink && (
                  <Link to={backLink} className={styles.back}>
                     <Icon type="ArrowLeftBig" height={backIconSize} />
                  </Link>
               )}
               <H1 className={titleClasses}>
                  <span className={styles.titleContent}>{title}</span>
               </H1>
            </div>
         </div>
         {children}
      </div>
   );
};
