// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gZ_XgcVPLDkY9uvHzVzC{font-family:p12-text;font-size:18px;display:flex;align-items:center}.b9JLRAYW_npUkgyi7RYr{margin-right:12px}.ZkTgubNJXvGNPp_Ydvkw{border-bottom:2px solid #e5e5e5;flex:1;padding:16px 0;display:inline-flex;justify-content:space-between;align-items:center;line-height:1;font-weight:300}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/my-pages/components/nav-list/nav-list.module.scss","webpack://./Assets/src/features/my-pages/main.scss"],"names":[],"mappings":"AAEA,sBACG,oBAAqB,CACrB,cAAe,CACf,YAAa,CACb,kBAAmB,CACrB,sBAGE,iBAAkB,CACpB,sBAGE,+BCZmB,CDanB,MAAO,CACP,cAAe,CACf,mBAAoB,CACpB,6BAA8B,CAC9B,kBAAmB,CACnB,aAAc,CACd,eAAgB","sourcesContent":["@import \"../../main.scss\";\r\n\r\n.item {\r\n   font-family: p12-text;\r\n   font-size: 18px;\r\n   display: flex;\r\n   align-items: center;\r\n}\r\n\r\n.icon {\r\n   margin-right: 12px;\r\n}\r\n\r\n.content {\r\n   border-bottom: 2px solid $color-border;\r\n   flex: 1;\r\n   padding: 16px 0;\r\n   display: inline-flex;\r\n   justify-content: space-between;\r\n   align-items: center;\r\n   line-height: 1;\r\n   font-weight: 300;\r\n}\r\n","$color-brand: #bb2058;\r\n$color-member: #051cf7;\r\n$color-border: #e5e5e5;\r\n\r\n$color-faded-text: #979797;\r\n\r\n$color-bg-pink: #ff00001a;\r\n$color-bg-green: #d2e6db;\r\n$color-bg-light-gray: #f7f7f7;\r\n$color-bg-light-blue: #b8cbf4;\r\n\r\n$desktop: 992px;\r\n\r\n$page-spacing: 20px;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "gZ_XgcVPLDkY9uvHzVzC",
	"icon": "b9JLRAYW_npUkgyi7RYr",
	"content": "ZkTgubNJXvGNPp_Ydvkw"
};
export default ___CSS_LOADER_EXPORT___;
