import { Form, Formik } from "formik";
import { LoginModalServerConfig } from "../types";
import { Button, ButtonWrapper, InputField, LinkButton, Notification } from "@afound/react";
import * as yup from "yup";
import { useLogin } from "../use-login";
import { useState } from "react";

import styles from "./reset-password.module.scss";

interface ResetPasswordProps extends Pick<LoginModalServerConfig, "texts"> {
   onBackClick: () => void;
}

export const ResetPassword = (props: ResetPasswordProps) => {
   const {
      texts: {
         forgotPassword,
         resetPasswordText,
         email,
         resetPassword: resetPasswordLabel,
         backToLogin,
         required,
         generalError,
      },
      onBackClick,
   } = props;

   const [loading, setLoading] = useState(false);
   const [hasError, setHasError] = useState(false);
   const [apiMessage, setApiMessage] = useState<string>();

   const { resetPassword } = useLogin();

   const validationSchema = yup.object({ email: yup.string().required(required) });

   const handleSubmit = async (values: { email: string }) => {
      setLoading(true);
      setHasError(false);
      setApiMessage(undefined);

      const resp = await resetPassword(values.email);

      setLoading(false);

      if (!resp?.success) {
         setHasError(true);
         setApiMessage(resp?.message || generalError);
         return;
      }

      setApiMessage(resp.message);
   };

   return (
      <div>
         <h3>{forgotPassword}</h3>
         <p className={styles.resetDescription}>{resetPasswordText}</p>
         <Formik initialValues={{ email: "" }} validationSchema={validationSchema} onSubmit={handleSubmit}>
            <Form>
               <InputField name="email" label={email} />
               <ButtonWrapper className={styles.buttonRow}>
                  <Button type="submit" disabled={loading}>
                     {resetPasswordLabel}
                  </Button>
               </ButtonWrapper>
               <Notification
                  className={styles.notification}
                  visible={!!apiMessage}
                  theme={hasError ? "error" : "success"}
                  message={apiMessage!}
               />
            </Form>
         </Formik>
         <ButtonWrapper className={styles.buttonRow}>
            <LinkButton theme="underlined" onClick={onBackClick}>
               {backToLogin}
            </LinkButton>
         </ButtonWrapper>
      </div>
   );
};
