import { useEffect, useState } from "react";
import { localStorage } from "@afound/common";

const getPauseUntilKey = (id: string) => `${id}_pauseUntil`;

export type SetPaused = () => void;
export type PopupPause = [boolean, SetPaused];

const getPauseUntilDate = (pauseLength: number) => {
   const currentDate = new Date();
   currentDate.setTime(currentDate.getTime() + pauseLength * 1000);
   return currentDate;
};

/**
 * Provides pause functionality that is persisted across page loads. This is useful for features that
 * should re-appear periodically.
 * Usage:
 * ```ts
 * const [paused, setPaused] = usePause(30, "myFeature");
 * ```
 * @param pauseInSeconds The amount of time to pause, in seconds.
 * @param key A unique key for the consuming feature.
 * @returns A tuple containing the current paused status and a callback for initiating the pause.
 */
export const usePause = (pauseInSeconds: number, key: string): PopupPause => {
   const pauseUntilKey = getPauseUntilKey(key);

   const isWithinPauseTimeRange = () => {
      const pauseUntil = localStorage.getItem(pauseUntilKey);
      if (!pauseUntil) {
         return false;
      }

      const parsed = new Date(+pauseUntil);
      const now = new Date();

      return now <= parsed;
   };

   const [paused, setPausedState] = useState(false);

   useEffect(() => {
      const isPaused = isWithinPauseTimeRange();
      setPausedState(isPaused);

      if (!isPaused) {
         localStorage.remove(pauseUntilKey);
      }
   }, [pauseInSeconds]);

   const setPaused = () => {
      const pauseUntil = getPauseUntilDate(pauseInSeconds);
      localStorage.setItem(pauseUntilKey, pauseUntil.getTime().toString());
      setPausedState(true);
   };

   return [paused, setPaused];
};
