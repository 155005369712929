export default function pagingFactory(initialPaging) {
  let _totalPages = 1;
  let _currentPage = 1;
  let _firstVisiblePage = 1;
  let _lastVisiblePage = 1;

  if (initialPaging) {
    _totalPages = initialPaging.Pages || 1;
    _lastVisiblePage = initialPaging.Page || 1;
    _firstVisiblePage = initialPaging.Page || 1;
    _currentPage = _lastVisiblePage;
  }

  const update = (serverPaging, back) => {
    _totalPages = serverPaging && serverPaging.Pages || 1;
    _currentPage = serverPaging && serverPaging.Page || 1;
    if (!back) {
      _lastVisiblePage = _currentPage;
    } else {
      _firstVisiblePage = _currentPage;
    }
  };

  const current = () => _currentPage;
  const firstVisible = () => _firstVisiblePage;
  const total = () => _totalPages;
  const lastVisible = () => _lastVisiblePage;

  const nextPage = () => _lastVisiblePage + 1;
  const previousPage = () => _firstVisiblePage - 1 || 1;

  return { update, current, firstVisible, lastVisible, total, nextPage, previousPage };
}
