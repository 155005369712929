import { InputField } from "@afound/react";
import { Form, Formik } from "formik";
import styles from "./search-form.module.scss";
import { useEffect, useRef } from "react";
import { SearchServerConfig } from "../types";

interface SearchFormProps extends SearchServerConfig {
   isVisible: boolean;
   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
   onSubmit: (value: string) => void;
   onClose: () => void;
}

export const SearchForm = ({ isVisible, onClose, onSubmit, onChange, translations }: SearchFormProps) => {
   const searchInputRef = useRef<HTMLInputElement | null>(null);

   useEffect(() => {
      if (isVisible) {
         searchInputRef.current?.focus();
      }
   }, [isVisible]);

   const handleSubmit = (values: { search: string }) => {
      if (!values.search) {
         return;
      }

      onSubmit(values.search);
   };

   return (
      <div className={styles.searchForm}>
         <Formik initialValues={{ search: "" }} onSubmit={handleSubmit}>
            <Form className={styles.form}>
               <button type="submit" className={styles.searchButton}>
                  <span className="icon p12icon-search"></span>
               </button>
               <InputField
                  autoComplete="off"
                  name="search"
                  placeholder={translations.placeholder}
                  as="input"
                  className={styles.searchInput}
                  onChange={onChange}
                  onFocus={onChange}
                  ref={searchInputRef}
               />
            </Form>
         </Formik>

         <button className={styles.closeButton} onClick={onClose}>
            <span className="icon p12icon-cross"></span>
         </button>
      </div>
   );
};
