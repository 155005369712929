import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { ModalType } from "../shared/ui";

export interface ModalConfig {
   visible: boolean | undefined;
   priority?: number; // Prio 1 = highest, then ascending. Prio 0 = unset. Not used currently.
}

export type ModalsState = { [P in ModalType]: ModalConfig };

export const initialState: ModalsState = {
   chat: {
      visible: undefined,
      priority: 1,
   },
   dsaPopup: {
      visible: undefined,
   },
   newsletterPopup: {
      visible: undefined,
   },
   memberPopup: {
      visible: undefined,
   },
   welcomePopup: {
      visible: undefined,
   },
   backInStockPopup: {
      visible: undefined,
   },
   upsellingPopup: {
      visible: undefined,
   },
};

// Find currently visible modal - assumes there is only one visible at a time
export type CurrentModal = [ModalType | undefined, ModalConfig | undefined];
const getCurrent = (state: ModalsState): CurrentModal =>
   (Object.entries(state).find(([_, value]) => value.visible) as CurrentModal) || [undefined, undefined];

export const modalsSlice = createSlice({
   name: "modals",
   initialState,
   reducers: {
      show: (state, action: PayloadAction<ModalType>) => {
         // Find current visible modal - assumes there is only one visible at a time
         const [currentKey] = getCurrent(state);

         // A very naive strategy to avoid collisions (with the exception of chat) - we simply avoid showing the requested
         //  modal if there's one visible already (unless it has higher prio). We probably want to polish this in the future.
         if (currentKey && (action.payload !== "chat" || currentKey !== "chat")) {
            return;
         }

         state[action.payload].visible = true;
      },
      hide: (state, action: PayloadAction<ModalType>) => {
         state[action.payload].visible = false;
      },
      toggle: (state, action: PayloadAction<ModalType>) => {
         const nextState = !state[action.payload].visible;
         state[action.payload].visible = nextState;
      },
   },
});

export const { show, hide, toggle } = modalsSlice.actions;

export const selectModals = (s: RootState) => s.modals;
export const selectCurrentModal = createSelector([selectModals], (modalsState) => getCurrent(modalsState));

export default modalsSlice.reducer;
