import { SearchDepartmentConfigurationItem } from "../../types";
import styles from "./department.module.scss";
import classNames from "classnames";

interface DepartmentProps {
   department: SearchDepartmentConfigurationItem;
   isActiveDepartment: boolean;
   onDepartmentSelect: (departmentId: string) => void;
}

export const Department = ({ department, isActiveDepartment, onDepartmentSelect }: DepartmentProps) => {
   const { departmentGuid, departmentName } = department;

   return (
      <button key={departmentGuid} className={styles.department} onClick={() => onDepartmentSelect(departmentGuid)}>
         <span className={classNames(styles.departmentName, isActiveDepartment && styles.active)}>
            {departmentName}
         </span>
      </button>
   );
};
