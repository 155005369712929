import { waitForElement } from "@afound/common";
import { isAuthenticated } from "../../shared/auth";
import { getEcomIdWidgetGtmEvent } from "./tracking";

const EcomIdWidgetV2 = () => {
   waitForElement(".js-ecomid-widget").then(() => {
      const elements = document.querySelectorAll(".js-ecomid-widget") as NodeListOf<HTMLElement>;
      elements.forEach((el) => {
         const element = el as HTMLElement;
         element.addEventListener("click", async () => {
            await handleWidgetClick(element);
         });
      });
   });

   waitForElement(".ecomid-widget-container.js-ecomid-cart-widget").then((el) => {
      waitForElement(".js-ecomid-widget", el!).then((element) => {
         element?.addEventListener("click", async () => {
            await handleWidgetClick(element);
         });
      });
   });
};

async function handleWidgetClick(element: HTMLElement) {
   const isLoggedIn = await isAuthenticated();
   var containerElement = element.closest(".ecomid-widget-container") as HTMLElement;
   window.dataLayer.push(getEcomIdWidgetGtmEvent(isLoggedIn, containerElement.dataset.location));
}

EcomIdWidgetV2();
