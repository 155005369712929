import { useState } from "react";
import { WelcomePopupServerConfig } from "./types";
import { SitePopup } from "../../components";
import styles from "./popup-wrapper.module.scss";
import { Button } from "@afound/react";

export const PopupWrapper = (props: WelcomePopupServerConfig) => {
   const {
      imageUri,
      texts: { header, welcomeText, welcomeCta },
   } = props;

   const [showPopup, setShowPopup] = useState(true);

   const handleDismiss = () => setShowPopup(false);

   return (
      <SitePopup type="welcomePopup" visible={showPopup} onClose={handleDismiss}>
         <div className={styles.content}>
            <img className={styles.image} src={`${imageUri}?preset=w600h300`} alt={"Welcome popup"} />
            <h1 className={styles.heading}>{header}</h1>
            <div className={styles.intro}>{welcomeText}</div>
            <Button theme="primary" className={styles.button} onClick={handleDismiss}>
               {welcomeCta}
            </Button>
         </div>
      </SitePopup>
   );
};
