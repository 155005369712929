// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oB_q7zpCoF176mEA6IY1{text-align:center;width:310px;margin:0 auto}.phPD4reKjprjRvvq4tQC{font-family:p12-text;font-size:30px;font-weight:300;display:flex;justify-content:center;align-items:center;-moz-column-gap:4px;column-gap:4px}.V8dCe9G0f0wMVpMYS0no{font-family:p12-desc;margin:12px 0;line-height:1.15;font-size:16px}.UGGwZPrKFOQ9KsGTAYii{padding-bottom:16px}.dM1P4JrrXjv4p4xPG7Ut{height:40px;font-size:18px;font-weight:300;line-height:20px}.K2mL7QQWxpUkONzLEPdV{z-index:201}.AxqTdoDQoBqvNCTylV0Q{z-index:200}.Zg18Li2hqglpzT0y9AaH{position:absolute;top:16px;right:16px}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/welcome-popup/popup-wrapper.module.scss"],"names":[],"mappings":"AAAA,sBACG,iBAAkB,CAClB,WAAY,CACZ,aAAc,CAChB,sBAGE,oBAAqB,CACrB,cAAe,CACf,eAAgB,CAChB,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,mBAAY,CAAZ,cAAe,CACjB,sBAGE,oBAAqB,CACrB,aAAc,CACd,gBAAiB,CACjB,cAAe,CACjB,sBAGG,mBAAoB,CACvB,sBAGG,WAAY,CACZ,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACpB,sBAGG,WAAY,CACf,sBAGG,WAAY,CACf,sBAGE,iBAAkB,CAClB,QAAS,CACT,UAAW","sourcesContent":[".content {\r\n   text-align: center;\r\n   width: 310px;\r\n   margin: 0 auto;\r\n}\r\n\r\n.heading {\r\n   font-family: p12-text;\r\n   font-size: 30px;\r\n   font-weight: 300;\r\n   display: flex;\r\n   justify-content: center;\r\n   align-items: center;\r\n   column-gap: 4px;\r\n}\r\n\r\n.intro {\r\n   font-family: p12-desc;\r\n   margin: 12px 0;\r\n   line-height: 1.15;\r\n   font-size: 16px;\r\n}\r\n\r\n.image {\r\n    padding-bottom: 16px;\r\n}\r\n\r\n.button {\r\n    height: 40px;\r\n    font-size: 18px;\r\n    font-weight: 300;\r\n    line-height: 20px;\r\n}\r\n\r\n.popup {\r\n    z-index: 201;\r\n}\r\n\r\n.popupBackdrop {\r\n    z-index: 200;\r\n}\r\n\r\n.close {\r\n   position: absolute;\r\n   top: 16px;\r\n   right: 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "oB_q7zpCoF176mEA6IY1",
	"heading": "phPD4reKjprjRvvq4tQC",
	"intro": "V8dCe9G0f0wMVpMYS0no",
	"image": "UGGwZPrKFOQ9KsGTAYii",
	"button": "dM1P4JrrXjv4p4xPG7Ut",
	"popup": "K2mL7QQWxpUkONzLEPdV",
	"popupBackdrop": "AxqTdoDQoBqvNCTylV0Q",
	"close": "Zg18Li2hqglpzT0y9AaH"
};
export default ___CSS_LOADER_EXPORT___;
