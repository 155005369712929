// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dGaJDqoraIgv5SA2bZDF{position:absolute;top:0;left:50%;transform:translateX(-50%);width:100%;max-width:1920px;margin:0 auto;z-index:11}\n", "",{"version":3,"sources":["webpack://./Assets/src/components/notification-bar/notification-bar-wrapper/notification-bar-wrapper.module.scss"],"names":[],"mappings":"AAAA,sBACC,iBAAkB,CAClB,KAAM,CACN,QAAS,CACT,0BAA2B,CAC3B,UAAW,CACX,gBAAiB,CACjB,aAAc,CACd,UAAW","sourcesContent":[".wrapper {\r\n\tposition: absolute;\r\n\ttop: 0;\r\n\tleft: 50%;\r\n\ttransform: translateX(-50%);\r\n\twidth: 100%;\r\n\tmax-width: 1920px;\r\n\tmargin: 0 auto;\r\n\tz-index: 11;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "dGaJDqoraIgv5SA2bZDF"
};
export default ___CSS_LOADER_EXPORT___;
