// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I1FDBKanFFzeDebxd865{margin:30px 0 24px 0}.wZCX43SSV_SLk8yV9Fj8{font-size:20px;font-weight:700;display:block;text-align:center;font-family:\"p12-001\",Helvetica Narrow,Arial Narrow,Tahoma,Arial,Helvetica,sans-serif;padding-bottom:12px}.joyq0QRyUZrSDNaylp_Y{font-size:16px;line-height:22px;font-weight:bold;font-family:\"p12-001\",Helvetica Narrow,Arial Narrow,Tahoma,Arial,Helvetica,sans-serif}.iyuvBwmZ4Rr5zOZPROKz{line-height:22px;font-family:\"p12-001\",Helvetica Narrow,Arial Narrow,Tahoma,Arial,Helvetica,sans-serif}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/size-guide/measurement-table/measurement-table.module.scss"],"names":[],"mappings":"AAAA,sBACG,oBAAqB,CACvB,sBAEE,cAAe,CACf,eAAgB,CAChB,aAAc,CACd,iBAAkB,CAClB,qFAAsF,CACtF,mBAAmB,CACrB,sBAGE,cAAe,CACf,gBAAiB,CACjB,gBAAiB,CACjB,qFAAsF,CACxF,sBAGE,gBAAiB,CACjB,qFAAsF","sourcesContent":[".wrapper {\r\n   margin: 30px 0 24px 0;\r\n}\r\n.title {\r\n   font-size: 20px;\r\n   font-weight: 700;\r\n   display: block;\r\n   text-align: center;\r\n   font-family: \"p12-001\",Helvetica Narrow,Arial Narrow,Tahoma,Arial,Helvetica,sans-serif;\r\n   padding-bottom:12px;\r\n}\r\n\r\n.subTitle {\r\n   font-size: 16px;\r\n   line-height: 22px;\r\n   font-weight: bold;\r\n   font-family: \"p12-001\",Helvetica Narrow,Arial Narrow,Tahoma,Arial,Helvetica,sans-serif;\r\n}\r\n\r\n.description {\r\n   line-height: 22px;\r\n   font-family: \"p12-001\",Helvetica Narrow,Arial Narrow,Tahoma,Arial,Helvetica,sans-serif;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "I1FDBKanFFzeDebxd865",
	"title": "wZCX43SSV_SLk8yV9Fj8",
	"subTitle": "joyq0QRyUZrSDNaylp_Y",
	"description": "iyuvBwmZ4Rr5zOZPROKz"
};
export default ___CSS_LOADER_EXPORT___;
