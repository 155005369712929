import { useEffect, useRef } from "react";
import ai from "../../ai";
import { Cart } from "@afound/types";

export const useCartEcomId = (cart: Cart | undefined) => {
   const ecomIdInitRef = useRef<boolean>(false);

   useEffect(() => {
      if (!cart || ecomIdInitRef.current) {
         return;
      }

      try {
         window.ecomid.widgets.info?.load();
         window.ecomid.widgets.user?.load();

         ecomIdInitRef.current = true;
      } catch (err) {
         ai.trackException({ exception: err as Error, properties: { feature: "cart" } });
      }
   }, [cart]);

   return () => (
      <div data-location="cart" className="ecomid-widget-container js-ecomid-cart-widget">
         <div className="widget-container"></div>
      </div>
   );
};
