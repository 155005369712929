import { getCreateAccountGtmEvent, post, validatePassword } from "@afound/common";

import { ShowSiteNotification, SiteNotificationEventArgs } from "../../components/notification-bar";
import { dispatchCustomEvent, waitForElement } from "../../shared/dom";
import { validateField } from "../../shared/validation/dom-extensions";

interface CreatePasswordResponse {
   success: boolean;
   message: string;
}

const trackGtmEvent = (success: boolean) => {
   if (!dataLayer) {
      return;
   }

   dataLayer.push(getCreateAccountGtmEvent(success, "registeraccount:orderconfirmationPage"));
};

const submitPassword = async (form: HTMLFormElement) => {
   try {
      const response = await post<FormData, CreatePasswordResponse>("?handler=Reset", new FormData(form), {
         requestType: "formdata",
      });

      trackGtmEvent(response?.success || false);

      return response;
   } catch {
      trackGtmEvent(false);
   }

   return undefined;
};

export const createPasswordForm = () => {
   waitForElement<HTMLFormElement>("#createPasswordSection").then((section) => {
      const form = section.querySelector("form") as HTMLFormElement;
      const password = form.querySelector("#Password") as HTMLInputElement;
      const passwordError = form.querySelector("span.error") as HTMLElement;
      const submitButton = form.querySelector("input[type='submit']") as HTMLInputElement;

      form.addEventListener("submit", async (ev) => {
         ev.preventDefault();

         const isValid = validateField(
            password,
            passwordError,
            {
               required: INITIALDATA.validationMessages.required,
               custom: INITIALDATA.validationMessages.password,
            },
            validatePassword
         );

         if (!isValid) {
            return;
         }

         submitButton.setAttribute("disabled", "");
         const response = await submitPassword(form);
         submitButton.removeAttribute("disabled");

         if (!response?.success) {
            dispatchCustomEvent<SiteNotificationEventArgs>(form, ShowSiteNotification, {
               theme: "error",
               message: response?.message || "",
            });
            return;
         }

         dispatchCustomEvent<SiteNotificationEventArgs>(form, ShowSiteNotification, {
            theme: "success",
            message: response?.message || "",
         });

         section.classList.add("is-hidden");
      });

      password.addEventListener("input", () => {
         if (password.validity.valid) {
            passwordError.textContent = "";
            passwordError.className = "error";
            password.className = "form-control__input";
         }
      });
   });
};
