import { ApiStatus } from "@afound/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../../store";
import { defaultSettings } from "./default-settings";
import { AreaKey, Settings } from "./types";
import { MyPagesServerConfig } from "../types";

interface SettingsState extends Settings {
   status: ApiStatus;
}

const initialState: SettingsState = {
   status: "idle",
   ...defaultSettings,
};

const getSettings = async (serverConfig: MyPagesServerConfig): Promise<Settings> => {
   const areaMap: { [key: string]: AreaKey } = {
      ProfilePage: "profile",
      OrderHistoryPage: "orders",
   };

   const rootRoute = serverConfig.routes[0];
   const areaRoutes = rootRoute.routes
      .filter((r) => r.component === "ProfilePage" || r.component === "OrderHistoryPage")
      .map((r) => ({
         area: areaMap[r.component],
         path: `/${r.path.split("/").reverse()[0]}`,
      }));

   return new Promise<Settings>((resolve) => {
      resolve({
         locale: serverConfig.locale,
         areaRoutes,
         baseUrl: rootRoute.path,
         country: serverConfig.country,
         contentUrls: serverConfig.contentUrls,
         externalLoginSettings: serverConfig.externalLoginSettings,
         translations: serverConfig.translations,
         validations: serverConfig.validations,
         isMyDealsEnabled: serverConfig.isMyDealsEnabled,
         enableEcomIdV2: serverConfig.enableEcomIdV2,
         contactUsWaitingDays: serverConfig.contactUsWaitingDays,
         myPagesThreadTips: serverConfig.myPagesThreadTips,
         isMemberZoneEnabled: serverConfig.isMemberZoneEnabled,
         isFlexibleReturnFeeEnabled: serverConfig.isFlexibleReturnFeeEnabled,
         isClosingModeEnabled: serverConfig.isClosingModeEnabled,
      });
   });
};

export const fetchSettings = createAsyncThunk(
   "settings/fetch",
   async (serverConfig: MyPagesServerConfig, { rejectWithValue }) => {
      const settings = await getSettings(serverConfig);

      if (!settings) {
         return rejectWithValue("Failed to fetch settings");
      }

      return settings;
   }
);

export const settingsSlice = createSlice({
   name: "settings",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(fetchSettings.pending, (state) => {
         state.status = "loading";
      });
      builder.addCase(fetchSettings.fulfilled, (state, action: PayloadAction<Settings>) => {
         return {
            ...state,
            ...action.payload,
            status: "success",
         };
      });
      builder.addCase(fetchSettings.rejected, (state) => {
         state.status = "error";
      });
   },
});

export const selectSettings = (s: RootState) => s.settings;
export const selectTranslations = (s: RootState) => s.settings.translations;

export default settingsSlice.reducer;
