import { H2, useGtm } from "@afound/react";
import { getContactUsGtmEvent } from "@afound/common";
import { FC, ReactNode } from "react";
import { HtmlContent } from "@afound/react";

import { Icon } from "../../components/icon";
import { HomeTexts } from "../../settings/localized-texts";
import { ContentUrls } from "../../settings/types";
import styles from "./contact.module.scss";
import { useAppDispatch } from "../../../../store";
import { show } from "../../../../store/modals-slice";

const Block: FC<{
   label: string;
   renderIcon: () => ReactNode;
   url?: string;
   onClick?: () => void;
}> = ({ label, renderIcon, url, onClick }) => {
   const inner = (
      <>
         {renderIcon()}
         <span className={styles.label}>{label}</span>
      </>
   );

   return url ? (
      <a className={styles.block} href={url} target="_blank" rel="noreferrer" onClick={onClick}>
         {inner}
      </a>
   ) : (
      <div className={styles.block} role="button" onClick={onClick}>
         {inner}
      </div>
   );
};

interface ContactProps {
   heading: string;
   returnPolicy: string;
   links: Pick<ContentUrls, "faqPage" | "contactPage" | "digitalReturnPage">;
   texts: Pick<HomeTexts, "contactFaq" | "contactEmail" | "contactChat">;
}

export const Contact = (props: ContactProps) => {
   const {
      heading,
      returnPolicy,
      links,
      texts: { contactFaq, contactEmail, contactChat },
   } = props;

   const dispatch = useAppDispatch();
   const { dataLayer } = useGtm();

   const handleContactUsClick = () => {
      dataLayer(getContactUsGtmEvent("mypages"));
   };

   const handleChatClick = () => dispatch(show("chat"));

   return (
      <div>
         <H2 className={styles.heading}>{heading}</H2>
         {links.digitalReturnPage && (
            <div className={styles.returnPolicyContainer}>
               <Icon type="InfoGray" height={26} width={40} className={styles.infoIcon} />
               <HtmlContent html={returnPolicy} links={[links.digitalReturnPage]} className={styles.returnPolicyText} />
            </div>
         )}
         <div className={styles.blocks}>
            <Block renderIcon={() => <Icon type="FAQ" height={40} />} label={contactFaq} url={links.faqPage} />
            <Block
               renderIcon={() => <Icon type="EnvelopeBig" height={40} />}
               label={contactEmail}
               url={links.contactPage}
               onClick={handleContactUsClick}
            />
            <Block
               renderIcon={() => <Icon type="ChatBig" className={styles.chatIcon} height={40} />}
               label={contactChat}
               onClick={handleChatClick}
            />
         </div>
      </div>
   );
};
