import { validateEmail } from "@afound/common";
import { Button, ButtonWrapper, Checkbox, HtmlContent, InputField, Notification, RadioButton } from "@afound/react";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import * as yup from "yup";

import { DepartmentPreference } from "../../shared/types";
import styles from "./newsletter-signup-form.module.scss";
import { NewsletterSignupFormTexts } from "./types";
import { useSubscription } from "./use-subscription";
import { formatTemplatedString } from "../../shared/utils";

interface NewsletterFormModel {
   email: string;
   department: DepartmentPreference;
   acceptTerms: boolean;
}

interface NewsletterSignupFormProps {
   directMarketingPolicyPageUrl: string;
   texts: NewsletterSignupFormTexts;
   onSubscribed?: (success: boolean, email: string, department: DepartmentPreference) => void;
}

export const NewsletterSignupForm = (props: NewsletterSignupFormProps) => {
   const {
      directMarketingPolicyPageUrl,
      texts: {
         newsletterSignupTitle,
         newsletterSignupText,
         departmentPreference,
         mensDepartment,
         womensDepartment,
         emailPlaceholder,
         directMarketingPolicy,
         couponTerms,
         signUpCta,
         acceptMarketingPolicyRequired: required,
         departmentPreferenceRequired,
         invalidEmail,
         signUpError,
      },
      onSubscribed,
   } = props;

   const [loading, setLoading] = useState(false);
   const [subscriptionError, setSubscriptionError] = useState<string | undefined>();

   const { subscribeNewsletter } = useSubscription();

   const handleSubmit = async (values: NewsletterFormModel) => {
      setLoading(true);
      setSubscriptionError(undefined);

      const { success, message } = await subscribeNewsletter(values.email, values.department);
      if (!success) {
         setSubscriptionError(message || signUpError);
         setLoading(false);
      }

      onSubscribed && onSubscribed(success, values.email, values.department);
   };

   const styledHeading = useMemo(() => {
      return formatTemplatedString(
         newsletterSignupTitle,
         (placeholder: string) => `<span style="color:#ce0058">${placeholder}</span>`
      );
   }, [newsletterSignupTitle]);

   return (
      <Formik
         initialValues={{ email: "", department: "" as any, acceptTerms: false }}
         validationSchema={yup.object({
            email: yup
               .string()
               .required(invalidEmail)
               .trim()
               .lowercase()
               .test("isValidEmail", invalidEmail, (val?: string) => (val ? validateEmail(val) : false)),
            department: yup.string().required(departmentPreferenceRequired),
            acceptTerms: yup.bool().oneOf([true], required),
         })}
         onSubmit={handleSubmit}
      >
         <Form className={styles.form}>
            <h3 className={styles.heading} dangerouslySetInnerHTML={{ __html: styledHeading }} />
            <p className={styles.intro}>{newsletterSignupText}</p>
            <div>
               <div className={styles.departmentSelect}>{departmentPreference}</div>
               <div className={styles.departments}>
                  <RadioButton name="department" label={mensDepartment} value="men" />
                  <RadioButton name="department" label={womensDepartment} value="women" hideError />
               </div>
            </div>
            <InputField
               name="email"
               type="email"
               placeholder={emailPlaceholder}
               formatOptions={{ forceLowerCase: true }}
            />
            <Checkbox name="acceptTerms">
               <HtmlContent
                  className={styles.legal}
                  html={directMarketingPolicy}
                  links={[directMarketingPolicyPageUrl]}
               />
            </Checkbox>
            <ButtonWrapper>
               <Button type="submit" className={styles.submit} busy={loading}>
                  {signUpCta}
               </Button>
            </ButtonWrapper>
            <div className={styles.couponTerms}>
               <hr />
               <span className={styles.couponTermsText}>{couponTerms}</span>
            </div>
            <Notification
               className={styles.error}
               visible={!!subscriptionError}
               theme="error"
               message={subscriptionError || ""}
               closeable={false}
            />
         </Form>
      </Formik>
   );
};
