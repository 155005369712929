import { useEffect, useRef, useState } from "react";
import { LoginModalEventArgs, LoginModalServerConfig, ShowLoginModal } from "../types";
import { Modal, Tab, Tabs } from "@afound/react";
import { Login } from "../login";
import styles from "./login-modal.module.scss";
import { ResetPassword } from "../reset-password";
import { Register } from "../register";
import { getLoginModalEvent } from "../tracking";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

interface LoginModalProps extends LoginModalServerConfig {
   modalToggle: HTMLElement;
}

type ViewMode = "login" | "register" | "resetPassword";

export const LoginModal = (props: LoginModalProps) => {
   const { modalToggle, texts } = props;

   const scrollLockRef = useRef<HTMLDivElement>(null);

   const [showLogin, setShowLogin] = useState(false);
   const [viewMode, setViewMode] = useState<ViewMode>("login");
   const [returnUrl, setReturnUrl] = useState<string>();

   const handleHeaderLoginButtonClick = () => {
      setShowLogin(true);
      disableBodyScroll(scrollLockRef.current!);
      window.dataLayer.push(getLoginModalEvent());
   };

   const handleShowLoginModalEvent = (ev: Event) => {
      const { defaultTab, returnUrl } = (ev as CustomEvent<LoginModalEventArgs>).detail || {
         defaultTab: undefined,
         returnUrl: undefined,
      };

      if (defaultTab) {
         setViewMode(defaultTab);
      }

      if (returnUrl) {
         setReturnUrl(returnUrl);
      }

      setShowLogin(true);
   };

   useEffect(() => {
      modalToggle.addEventListener("click", handleHeaderLoginButtonClick);
      document.addEventListener(ShowLoginModal, handleShowLoginModalEvent);

      return () => {
         modalToggle.removeEventListener("click", handleHeaderLoginButtonClick);
         document.removeEventListener(ShowLoginModal, handleShowLoginModalEvent);
      };
   }, []);

   const handleLoginClose = () => {
      setShowLogin(false);
      setReturnUrl(undefined);
      enableBodyScroll(scrollLockRef.current!);
   };

   return (
      <Modal
         ref={scrollLockRef}
         visible={showLogin}
         className={styles.modalWrapper}
         modalRootId="login-modal-root"
         disableStackingContext={{
            active: showLogin,
            targetElementQuery: "header",
            zIndexValue: 201,
         }}
         onClose={handleLoginClose}
      >
         {viewMode !== "resetPassword" ? (
            // <Tabs tabIndex={viewMode === "register" ? 1 : 0}>
            //    <Tab label={texts.loginTabLabel}>
            //       <Login {...props} returnUrl={returnUrl} onForgotPasswordClick={() => setViewMode("resetPassword")} />
            //    </Tab>
            //    <Tab label={texts.registerTabLabel}>
            //       <Register {...props} returnUrl={returnUrl} />
            //    </Tab>
            // </Tabs>
            <Login {...props} returnUrl={returnUrl} onForgotPasswordClick={() => setViewMode("resetPassword")} />
         ) : (
            <ResetPassword texts={texts} onBackClick={() => setViewMode("login")} />
         )}
      </Modal>
   );
};
