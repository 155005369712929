import { getContactUsGtmEvent } from "@afound/common";

import { getMarketSelectorGtmEvent } from "../../shared/tracking";
import { HeaderBaseProps, HeaderCommonElements } from "./types";

interface DesktopElements extends HeaderCommonElements {
   headerActions: HTMLElement;
}

interface DesktopProps extends HeaderBaseProps<DesktopElements> {}

const Desktop = (props: DesktopProps) => {
   const {
      elements: { header, headerNav, headerMenu, headerActions },
   } = props;

   const setUpTracking = () => {
      // Contact us link
      const contactUsLink = headerNav.querySelector("#desktop-contact-us-link")!;

      const handleContactUsClick = () => {
         window.dataLayer.push(getContactUsGtmEvent("header"));
      };

      contactUsLink.addEventListener("click", handleContactUsClick);

      // Market selector
      const marketSelectorList = headerNav.querySelector(`.header__panel-desktop .country-select__list`)!;

      const handleMarketSelect = (ev: Event) => {
         const currentMarket = window.location.pathname.substring(1).split("/")[0];
         const value = (ev.target as HTMLAnchorElement).getAttribute("data-value");

         if (currentMarket && value) {
            window.dataLayer.push(getMarketSelectorGtmEvent("header", currentMarket, value));
         }
      };

      marketSelectorList.addEventListener("click", handleMarketSelect);

      return () => {
         contactUsLink.removeEventListener("click", handleContactUsClick);
         marketSelectorList.removeEventListener("click", handleMarketSelect);
      };
   };

   const harmonizeWithInfoBar = () => {
      // Since the info bar is fixed (i.e. sticky) and header is not, we run into some interesting challenges.
      // When the info bar is visible and we scroll, the info bar should naturally have a higher z-index since
      // we don't want it to disappear behind the header when passing by. However, since our modals (login, etc)
      // reside within the header, and the header has a lower z-index they will appear "under" the info bar when
      // opened. To work around this we set a higher z-index temporarily when opening a modal; unfortunately we
      // don't have a unified way of detecting modal close, so we'll have to resort to the document scroll event
      // to remove the temporary z-index.

      const infoBarVisible = document.querySelector(".information-bar");
      if (!infoBarVisible) {
         return;
      }

      const handleHeaderActionClick = (ev: MouseEvent) => {
         const target = ev.target as HTMLElement;
         if (target?.closest("[data-role='modal-trigger']")) {
            header.classList.add("header__increase-z-index");
         }
      };

      const handleDocumentScroll = () => {
         header.classList.remove("header__increase-z-index");
      };

      headerActions.addEventListener("click", handleHeaderActionClick);
      document.addEventListener("scroll", handleDocumentScroll);

      return () => {
         headerActions.removeEventListener("click", handleHeaderActionClick);
         document.removeEventListener("scroll", handleDocumentScroll);
      };
   };

   const closeAllMenus = () => {
      headerMenu.querySelectorAll(".header__menu-item.active").forEach((elem) => elem.classList.remove("active"));
      headerMenu.querySelectorAll(".header__submenu-wrapper.is-visible").forEach((elem) => {
         elem.classList.remove("is-visible");
      });
   };

   const handleMouseOver = (ev: MouseEvent) => {
      const target = ev.target as HTMLElement;

      const menuItemElem = target.closest(".header__menu-item");
      if (!menuItemElem) {
         return;
      }

      closeAllMenus();

      menuItemElem.querySelector(".header__submenu-wrapper")?.classList.add("is-visible");
   };

   const handleMouseOut = (ev: MouseEvent) => {
      const target = ev.target as HTMLElement;
      const relatedTarget = ev.relatedTarget as HTMLElement | null; // The new element that we left for

      // If mouse out is still within the menu wrapper, don't close the menu yet
      if (relatedTarget?.classList.contains("header__menu-wrapper")) {
         return;
      }

      const menuItemElem = target.closest(".header__menu-item");

      if (!menuItemElem) {
         // Since we don't immediately close the submenu when leaving menu items we also need to check
         // if we're leaving the menu row - in that case, close all visible menus.
         if (target.closest(".header__menu")) {
            closeAllMenus();
         }
         return;
      }

      menuItemElem.querySelector(".header__submenu-wrapper")?.classList.remove("is-visible");
   };

   headerMenu?.addEventListener("mouseover", handleMouseOver);
   headerMenu?.addEventListener("mouseout", handleMouseOut);

   const trackingCleanup = setUpTracking();
   const infoBarCleanup = harmonizeWithInfoBar();

   return () => {
      headerMenu?.removeEventListener("mouseover", handleMouseOver);
      headerMenu?.removeEventListener("mouseout", handleMouseOut);
      trackingCleanup && trackingCleanup();
      infoBarCleanup && infoBarCleanup();
   };
};

export default Desktop;
