import { currentDevice, Device } from "@afound/common";

import { waitForElement } from "../../shared/dom";
import Mobile from "./mobile";
import Shared from "./shared";
import { FooterElements } from "./types";

const Footer = () => {
   waitForElement("footer").then((footerElem) => {
      if (!footerElem) {
         return;
      }

      const elements: FooterElements = {
         footer: footerElem,
         footerLinks: footerElem.querySelector(".footer__links") as HTMLElement,
         backToTopButton: footerElem.querySelector(".footer__after button") as HTMLElement,
      };

      const cleanupCallbacks: Omit<Record<Device | "shared", Function | undefined>, "desktop"> = {
         mobile: undefined,
         shared: undefined,
      };

      const init = (device: Device) => {
         if (device === "mobile") {
            cleanupCallbacks["mobile"] = Mobile(elements);
         }

         cleanupCallbacks["shared"] = Shared(elements);
      };

      const [device] = currentDevice({ desktop: 992 }, (newDevice) => {
         const { mobile: mobileCleanup, shared: sharedCleanup } = cleanupCallbacks;

         mobileCleanup && mobileCleanup();
         sharedCleanup && sharedCleanup();

         init(newDevice);
      });

      init(device);
   });
};

Footer();
