import { Button, ButtonWrapper, Checkbox, ExternalLogin, InputField, LinkButton, Notification } from "@afound/react";
import { Form, Formik } from "formik";
import { LoginModalServerConfig, LoginModalTexts } from "../types";
import * as yup from "yup";

import styles from "./login.module.scss";
import { useLogin } from "../use-login";
import { useState } from "react";
import { getLoginEvent } from "../tracking";

interface LoginProps extends LoginModalServerConfig {
   returnUrl?: string;
   onForgotPasswordClick: () => void;
}

interface LoginFormModel {
   email: string;
   password: string;
   rememberMe: boolean;
}

const getValidationSchema = (texts: Pick<LoginModalTexts, "required">) =>
   yup.object({
      email: yup.string().required(texts.required),
      password: yup.string().required(texts.required),
   });

export const Login = (props: LoginProps) => {
   const {
      locale,
      marketName,
      externalLoginSource,
      returnUrl,
      onForgotPasswordClick,
      texts: {
         email,
         password,
         rememberMe,
         forgotPassword,
         signIn: signInLabel,
         or,
         signInWithSocial,
         generalError,
         ...rest
      },
   } = props;

   const [loading, setLoading] = useState(false);
   const [loginError, setLoginError] = useState<string>();

   const { signIn } = useLogin();

   const handleSubmit = async (values: LoginFormModel) => {
      setLoginError(undefined);
      setLoading(true);

      const resp = await signIn(values.email, values.password, values.rememberMe, locale);

      setLoading(false);

      if (!resp?.success) {
         setLoginError(resp?.message || generalError);
         window.dataLayer.push(getLoginEvent("login:error", resp?.failureReason || ""));
         return;
      }

      window.dataLayer.push(getLoginEvent("login", "login:modal"));

      if (returnUrl || resp?.returnUrl) {
         window.location.href = returnUrl || resp.returnUrl!;
      } else {
         window.location.reload();
      }
   };

   return (
      <div className={styles.login}>
         <Formik
            initialValues={{ email: "", password: "", rememberMe: false }}
            validationSchema={getValidationSchema(rest)}
            onSubmit={handleSubmit}
         >
            <Form>
               <InputField name="email" label={email} formatOptions={{ forceLowerCase: true, trim: true }} />
               <InputField name="password" type="password" label={password} />
               <div className={styles.rememberMe}>
                  <Checkbox name="rememberMe" className={styles.rememberMeToggle}>
                     {rememberMe}
                  </Checkbox>
                  <LinkButton type="button" theme="underlined" onClick={onForgotPasswordClick}>
                     {forgotPassword}
                  </LinkButton>
               </div>
               <ButtonWrapper className={styles.buttonRow}>
                  <Button type="submit" disabled={loading}>
                     {signInLabel}
                  </Button>
               </ButtonWrapper>
               <Notification className={styles.error} visible={!!loginError} theme="error" message={loginError!} />
            </Form>
         </Formik>
         <div className={styles.externalLoginDivider}>{or}</div>
         <ExternalLogin
            locale={locale}
            marketName={marketName}
            signInWithButtonLabel={signInWithSocial}
            source={externalLoginSource}
            returnUrl={returnUrl}
         />
      </div>
   );
};
