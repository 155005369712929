import { animatePulse } from "@afound/common";
import { HeaderBaseProps, HeaderCartCountEvent, HeaderCartCountEventArgs } from "./types";

interface SharedProps extends HeaderBaseProps {}

interface HeaderItemName {
   displayName: string;
   trackingName: string;
}

const Shared = (props: SharedProps) => {
   const {
      elements: { headerMenuWrapper, headerCartIcon, headerCartLabel },
      onNavigate,
   } = props;

   const handleCartCountChange = (ev: Event) => {
      const {
         detail: { count },
      } = ev as CustomEvent<HeaderCartCountEventArgs>;
      headerCartIcon.classList.toggle("p12icon-cart--filled", count > 0);
      headerCartLabel.innerText = count > 0 ? count.toString() : "";
      animatePulse(headerCartLabel);
   };

   const extractMenuItemName = (containerElem: Element): HeaderItemName | undefined => {
      const navLink = containerElem.classList.contains("header__nav-link")
         ? containerElem
         : containerElem.querySelector(".header__nav-link");

      if (!navLink) {
         return undefined;
      }

      const labelSpan = navLink.querySelector("span");

      return {
         displayName: labelSpan ? labelSpan.innerHTML : navLink.innerHTML,
         trackingName: navLink?.getAttribute("data-tracking-name") || "",
      };
   };

   const getDepartmentName = (current: HTMLElement) => {
      const departmentItem = current.closest(".header__menu-item");
      if (!departmentItem) {
         return undefined;
      }

      return extractMenuItemName(departmentItem);
   };

   const tryGetPromotedName = (current: HTMLElement) => {
      const promotedItem = current.closest(".header__promoted-item");
      if (!promotedItem) {
         return undefined;
      }

      const childContainer = promotedItem.closest(".header__promoted-link-children");
      if (childContainer) {
         const promotedSubdepartmentLink = childContainer.previousElementSibling!;
         return extractMenuItemName(promotedSubdepartmentLink);
      }

      return extractMenuItemName(promotedItem);
   };

   const tryGetSubdepartmentName = (current: HTMLElement) => {
      const subdepartmentItem = current.closest(".header__submenu-item");
      if (!subdepartmentItem) {
         return undefined;
      }

      return extractMenuItemName(subdepartmentItem);
   };

   const tryGetCategoryName = (current: HTMLElement) => {
      const categoryItem = current.closest(".header__category-item");
      if (!categoryItem) {
         return undefined;
      }

      return extractMenuItemName(categoryItem);
   };

   const handleNavlinkClick = (ev: MouseEvent) => {
      const target = ev.target as HTMLElement | null;
      if (!target) {
         return;
      }

      const targetIsNavlink =
         target.classList.contains("header__nav-link") || target.parentElement?.classList?.contains("header__nav-link");
      if (!targetIsNavlink) {
         return;
      }

      const targetHasChildren =
         target.classList.contains("has-children") || target.parentElement?.classList?.contains("has-children");
      if (targetHasChildren) {
         return;
      }

      const department = getDepartmentName(target);
      const departmentTrackingName = department?.trackingName || "";

      const promoted = tryGetPromotedName(target);
      if (promoted) {
         onNavigate({
            trigger: "promoted",
            trackingKeys: {
               department: departmentTrackingName,
               promoted: promoted.trackingName,
            },
         });

         return;
      }

      const subdepartment = tryGetSubdepartmentName(target);
      if (subdepartment) {
         const category = tryGetCategoryName(target);

         onNavigate({
            trigger: category ? "category" : "subdepartment",
            trackingKeys: {
               department: departmentTrackingName,
               subdepartment: subdepartment.trackingName,
               category: category?.trackingName,
            },
         });

         return;
      }

      onNavigate({
         trigger: "department",
         trackingKeys: {
            department: departmentTrackingName,
         },
      });
   };

   headerMenuWrapper.addEventListener("click", handleNavlinkClick);
   document.addEventListener(HeaderCartCountEvent, handleCartCountChange);

   return () => {
      headerMenuWrapper.removeEventListener("click", handleNavlinkClick);
      document.removeEventListener(HeaderCartCountEvent, handleCartCountChange);
   };
};

export default Shared;
