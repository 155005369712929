// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D6_kqLZfECRoIbzSV2vF{font-family:p12-001, Helvetica Narrow, Arial Narrow, Tahoma, Arial, Helvetica, sans-serif;font-size:12px;padding-left:6px;cursor:pointer;position:relative}.D6_kqLZfECRoIbzSV2vF .YzB_KAs7d_isOE57KCG3{z-index:10;top:calc(100% + 5px)}\n", "",{"version":3,"sources":["webpack://./Assets/src/components/info-tooltip/info-tooltip.module.scss"],"names":[],"mappings":"AAAA,sBACG,yFAA0F,CAC1F,cAAe,CACf,gBAAiB,CACjB,cAAe,CACf,iBAAkB,CALrB,4CAOM,UAAW,CACX,oBAAqB","sourcesContent":[".tooltipWrapper {\r\n   font-family: p12-001, Helvetica Narrow, Arial Narrow, Tahoma, Arial, Helvetica, sans-serif;\r\n   font-size: 12px;\r\n   padding-left: 6px;\r\n   cursor: pointer;\r\n   position: relative;\r\n   .tooltipText {\r\n      z-index: 10;\r\n      top: calc(100% + 5px);\r\n   }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipWrapper": "D6_kqLZfECRoIbzSV2vF",
	"tooltipText": "YzB_KAs7d_isOE57KCG3"
};
export default ___CSS_LOADER_EXPORT___;
