import { post } from "@afound/common";
import utils from "../../../Scripts/New/Common/utils";
import { createGtmOnCustomerSegmentPopup } from "../../../Scripts/Tracking/gtm-action-creators";
import { waitForElement } from "../../shared/dom";

const CustomerSegmentModal = () => {
   waitForElement(".customer-segment-modal__backfade").then(() => {
      const displayModal = INITIALDATA.customerSegmentModal.displayModal;
      const customerSegment = INITIALDATA.customerSegmentModal.customerSegment;

      if (displayModal) {
         setTimeout(() => {
            createGtmOnCustomerSegmentPopup({ action: "Offer shown", label: customerSegment });
            const modal = document.querySelector(".customer-segment-modal__backfade") as HTMLElement;
            modal.classList.add("customer-segment-modal--visible");
         }, 5000)
      }

      const closeButton = document.querySelector(".customer-segment-modal__close") as HTMLAnchorElement;
      closeButton.addEventListener("click", () => {
         createGtmOnCustomerSegmentPopup({ action: "Closed offer pop up", label: customerSegment  });
         if (displayModal) {
            post("/api/v1/customersegmentpopupapi/closepopup", {});
         }
         const modal = document.querySelector(".customer-segment-modal__backfade") as HTMLElement;
         modal.classList.remove("customer-segment-modal--visible");
      });

      const continueLink = document.querySelector(".customer-segment-modal__continue") as HTMLAnchorElement;
      continueLink.addEventListener("click", (ev: Event) => {         
         ev.preventDefault();
         createGtmOnCustomerSegmentPopup({ action: "Continue shopping", label: customerSegment  });
         if (displayModal) {
            post("/api/v1/customersegmentpopupapi/closepopup", {});
         }
         const modal = document.querySelector(".customer-segment-modal__backfade") as HTMLElement;
         modal.classList.remove("customer-segment-modal--visible");
      });

      const modalButton = document.querySelector(".customer-segment-modal button") as HTMLButtonElement;
      modalButton && modalButton.addEventListener("click", () => {
         const code = document.querySelector(".customer-segment-modal input") as HTMLInputElement;
         if (code) {
            createGtmOnCustomerSegmentPopup({ action: "Copy code", label: customerSegment  });
            utils.copyToClipboard(code.value);

            const buttonText = INITIALDATA.customerSegmentModal.copiedText;
            const button = document.querySelector(".customer-segment-modal button") as HTMLButtonElement;
            button.textContent = buttonText;
         }
      });
   });
};

CustomerSegmentModal();
