import { useEffect } from "react";
import { createPortal } from "react-dom";
import { ModalType } from "../../shared/ui";
import { useAppDispatch, useAppSelector } from "../../store";
import { hide, selectModals, show } from "../../store/modals-slice";
import { Popup, PopupProps } from "@afound/react";
import styles from "./site-popup.module.scss";

interface SitePopupProps extends PopupProps {
   type: ModalType;
}

export const SitePopup = (props: SitePopupProps) => {
   const { type, visible, children, onClose, className, ...rest } = props;

   const modalsState = useAppSelector(selectModals);
   const modalConfig = modalsState[type];

   const popupTemplate = document.getElementById("site-popup-root");

   const dispatch = useAppDispatch();

   useEffect(() => {
      if (modalConfig.visible === visible) {
         return;
      }

      if (visible) {
         dispatch(show(type));
      } else if (modalConfig.visible !== undefined && !visible) {
         dispatch(hide(type));
      }
   }, [modalConfig.visible, visible]);

   const handleClose = () => {
      dispatch(hide(type));
      onClose();
   };

   return (
      popupTemplate &&
      createPortal(
         <Popup
            {...rest}
            visible={!!modalConfig.visible}
            className={`${styles.popup} ${className}`}
            backdropClassName={styles.backdrop}
            onClose={handleClose}
         >
            {children}
         </Popup>,
         popupTemplate,
         type
      )
   );
};
