// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MKt6yVg9xCLELArXBK0w{padding:16px 0 16px 15px}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/search/departments/departments.module.scss"],"names":[],"mappings":"AAAA,sBACI,wBAAyB","sourcesContent":[".departmentsList {\r\n    padding: 16px 0 16px 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"departmentsList": "MKt6yVg9xCLELArXBK0w"
};
export default ___CSS_LOADER_EXPORT___;
