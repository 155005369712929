import { Product } from "@afound/types";

export const createGtmPromoImpressionData = (
   promotionId: string,
   promotionName: string,
   promotionCreative: string,
   url: string
) => ({
   event: "promoImpressionEvent",
   ecommerce: {
      promoView: {
         promotions: [
            {
               creative: promotionCreative,
               id: promotionId,
               name: promotionName,
               position: "0",
               url,
            },
         ],
      },
   },
});

export const createGtmPromotionClickData = (
   promotionId: string,
   promotionName: string,
   promotionCreative: string,
   targetUrl: string
) => ({
   event: "promotionClick",
   ecommerce: {
      promoClick: {
         promotions: [
            {
               creative: promotionCreative,
               id: promotionId,
               name: promotionName,
               position: "0",
               targetUrl,
            },
         ],
      },
   },
});

export const createAddToCartData = (
   product: Product,
   currency: string,
   cartTotal?: number,
   eventId?: string,
   promotionId?: string,
   promotionName?: string,
   promotionCreative?: string
) => {
   const products = {
      name: product.title,
      id: product.productCode,
      price: `${product.actualPrice}`,
      brand: product.brand,
      category: product.category,
      variant: product.color,
      dimension10: product.size,
      dimension13: product.offerCode,
      dimension36: product.promotionId,
      dimension42: promotionName,
      promotionId: promotionId,
      promotionName: promotionName,
      promotionCreative: promotionCreative,
      quantity: 1, // always 1 from addToCart
      quickView: false,
   };

   const gtmData = {
      event: "addToCartEvent",
      ecommerce: {
         currencyCode: currency,
         totalBasketValue: cartTotal || 0,
         add: {
            products: [products],
         },
      },
      gads: {
         event: "add_to_cart",
         value: cartTotal || 0,
         items: [
            {
               id: products.id,
               google_business_vertical: "retail",
            },
         ],
      },
      eventId: eventId,
   };

   return gtmData;
};
