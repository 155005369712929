import { get } from "@afound/common";

export const isAuthenticated = async () => {
   try {
      const { loggedIn } = (await get<{ loggedIn: boolean }>("/api/v1/authentication/authenticated"))!;
      return loggedIn;
   } catch (err: unknown) {
      return false;
   }
};
