import { WishlistItem } from "@afound/types";

import { useAppSelector } from "../../../store";
import { WishlistProduct } from "../wishlist-product";
import { selectWishlistConfig } from "../../../store/wishlist-slice";
import styles from "./wishlist-items.module.scss";

interface WishlistItemsProps {
   heading: string;
   itemsLabel: string;
   items: WishlistItem[];
}

const updateHeaderCartCount = (cartIconElem: HTMLElement) => {
   if (!cartIconElem) {
      return;
   }

   cartIconElem.classList.add("p12icon-cart--filled");

   const labelElement = cartIconElem.nextElementSibling as HTMLElement;
   const cartItemCount = +(labelElement.innerText || 0) + 1;
   labelElement.innerText = cartItemCount.toString();
};

export const WishlistItems = (props: WishlistItemsProps) => {
   const { heading, items } = props;

   const { locale, translations } = useAppSelector(selectWishlistConfig);

   const cartIcon = document.querySelector(".p12icon-cart") as HTMLElement;

   const handleAddedToCart = () => {
      updateHeaderCartCount(cartIcon);
   };

   return (
      <>
         <h2 className={styles.heading}>{`${heading} (${items.length})`}</h2>
         {/* <span className={styles.counter}>
            {items.length} {itemsLabel}
         </span> */}
         <div className={styles.items}>
            {items.map((item, idx) => (
               <div key={`${idx}_${item.offerId}`} className={styles.item}>
                  <WishlistProduct locale={locale} item={item} texts={translations} onAddedToCart={handleAddedToCart} />
               </div>
            ))}
         </div>
      </>
   );
};
