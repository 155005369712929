import { SearchIconType, SearchServerConfig, TopSearchItemType, TrackingClassType } from "../../types";
import styles from "./search-item.module.scss";
import classNames from "classnames";

interface SearchResultProps extends Pick<SearchServerConfig, "translations"> {
   itemType: TopSearchItemType;
   icon?: SearchIconType;
   phrase: string;
   url: string;
   filterQuery?: string;
   onClick?: () => void;
}

const trackingClassName: Record<TopSearchItemType, TrackingClassType | undefined> = {
   None: undefined,
   TrendingNow: "tracking-tranding-item",
   RecentSearch: "tracking-recent-search",
   EsalesSearch: "tracking-esales-search-suggestion",
   ManualSearch: "tracking-top-search",
};

export const SearchResult = (props: SearchResultProps) => {
   const { url, icon, phrase, translations, itemType, onClick } = props;

   const classes = classNames(styles.listLink, itemType !== "None" ? trackingClassName[itemType] : "");

   return (
      <li>
         <a href={url} className={classes} onClick={onClick}>
            {icon && icon !== "brand" && <span className={`icon p12icon-${icon} spaced-right--small`} />}
            {icon === "brand" && <span className={styles.brand}>{translations.brand}</span>}
            <span className={styles.phrase} dangerouslySetInnerHTML={{ __html: phrase }}></span>
         </a>
      </li>
   );
};
