import { createRoot } from "react-dom/client";
import { Upselling } from "./upselling";
import { waitForElement } from "@afound/common";
import { store } from "../../store";
import { Provider } from "react-redux";
import { ResponsiveContext } from "@afound/react";

waitForElement("#upselling-root").then((root) => {
   createRoot(root!).render(
      <Provider store={store}>
         <ResponsiveContext.Provider value={{ desktop: 992 }}>
            <Upselling {...INITIALDATA.newProduct} />
         </ResponsiveContext.Provider>
      </Provider>
   );
});
