import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { DomLitElement } from "../../../lit-extensions";
import styles from "./filter-count.module.scss";

@customElement("filter-count")
export class FilterCount extends DomLitElement {
   @property()
   itemCount: number = 0;

   render() {
      const countClasses = classMap({
         [styles.count]: true,
         [styles.visible]: this.itemCount > 0,
      });

      return html`
        <span class=${countClasses}>${this.itemCount}</span>
      `;
   }
}
