// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZLmBf5L4M40M1Gy9Jxrt{margin-bottom:24px;font-size:20px}.edxwh9LhRmydL0131aVx{display:inline-block;margin-bottom:16px;font-size:18px}.JMhAqCw57H925TOW1GlE{display:flex;flex-flow:wrap}.Y2_ftmmaAIcy0NJi5qb8{flex:0 1 auto;margin:0 4px 16px 4px;width:calc(50% - 8px)}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/wishlist/wishlist-items/wishlist-items.module.scss"],"names":[],"mappings":"AAAA,sBACC,kBAAmB,CACjB,cAAe,CACjB,sBAGA,oBAAqB,CACrB,kBAAmB,CACnB,cAAe,CACf,sBAGA,YAAa,CACb,cAAe,CACf,sBAGA,aAAc,CACd,qBAAsB,CACtB,qBAAsB","sourcesContent":[".heading {\r\n\tmargin-bottom: 24px;\r\n   font-size: 20px;\r\n}\r\n\r\n.counter {\r\n\tdisplay: inline-block;\r\n\tmargin-bottom: 16px;\r\n\tfont-size: 18px;\r\n}\r\n\r\n.items {\r\n\tdisplay: flex;\r\n\tflex-flow: wrap;\r\n}\r\n\r\n.item {\r\n\tflex: 0 1 auto;\r\n\tmargin: 0 4px 16px 4px;\r\n\twidth: calc(50% - 8px);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "ZLmBf5L4M40M1Gy9Jxrt",
	"counter": "edxwh9LhRmydL0131aVx",
	"items": "JMhAqCw57H925TOW1GlE",
	"item": "Y2_ftmmaAIcy0NJi5qb8"
};
export default ___CSS_LOADER_EXPORT___;
