// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tLSKk4TKLCnH5sX8jQul{display:inline}.tLSKk4TKLCnH5sX8jQul:first-child{padding-right:20px}.tLSKk4TKLCnH5sX8jQul:not(:first-child){padding:0 20px 0 20px}.tLSKk4TKLCnH5sX8jQul:hover>.H5E3M5FO58lnwfkHcacO{color:#000;border-color:#000}.H5E3M5FO58lnwfkHcacO{border-bottom:2px solid transparent;color:#666;transition:300ms}.Jk_OPhFI_9PkaWfcv2m_{color:#000;border-color:#000}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/search/departments/department/department.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAe,CADnB,kCAIQ,kBAAmB,CAJ3B,wCAQQ,qBAAsB,CAR9B,kDAYY,UAAW,CACX,iBAAkB,CACzB,sBAID,mCAAoC,CACpC,UAAW,CACX,gBAAiB,CACpB,sBAGG,UAAW,CACX,iBAAkB","sourcesContent":[".department {\r\n    display: inline;\r\n\r\n    &:first-child {\r\n        padding-right: 20px;\r\n    }\r\n\r\n    &:not(:first-child) {\r\n        padding: 0 20px 0 20px;\r\n    }\r\n    \r\n    &:hover > .departmentName {\r\n            color: #000;\r\n            border-color: #000;\r\n    }\r\n}\r\n\r\n.departmentName {\r\n    border-bottom: 2px solid transparent;\r\n    color: #666;\r\n    transition: 300ms;\r\n}\r\n\r\n.active {\r\n    color: #000;\r\n    border-color: #000;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"department": "tLSKk4TKLCnH5sX8jQul",
	"departmentName": "H5E3M5FO58lnwfkHcacO",
	"active": "Jk_OPhFI_9PkaWfcv2m_"
};
export default ___CSS_LOADER_EXPORT___;
