import { dispatchCustomEvent, waitForElement } from "@afound/common";
import { getFlexibleReturnFeeLoginOrRegisterGtmEvent } from "../../product-page/tracking";
import { ShowLoginModal } from "../../login/types";

const ProductListingSellerPage = () => {
   waitForElement(".product-listing-seller-page").then((sellerPage) => {
      if (!sellerPage) {
         return;
      }

      const elements = {
         retrunFeeNotLoggedInMsgEle: sellerPage.querySelector(
            "#seller-page-return-fee-amount-not-logged-in"
         ) as HTMLElement | null,
      };

      const setRetrunFeeNotLoggedInMsgClicks = () => {
         const { retrunFeeNotLoggedInMsgEle } = elements;

         if (!retrunFeeNotLoggedInMsgEle) {
            return;
         }
         retrunFeeNotLoggedInMsgEle.addEventListener("click", (e: Event) => {
            const target = e.target as HTMLElement;
            if (target.classList.contains("js-login-link")) {
               const action = "login";
               dispatchCustomEvent(target!, ShowLoginModal, { defaultTab: action });
               window.dataLayer.push(getFlexibleReturnFeeLoginOrRegisterGtmEvent(action));
            } else if (target.classList.contains("js-register-link")) {
               const action = "register";
               dispatchCustomEvent(target!, ShowLoginModal, { defaultTab: action });
               window.dataLayer.push(getFlexibleReturnFeeLoginOrRegisterGtmEvent(action));
            }
         });
      };

      const init = () => {
         setRetrunFeeNotLoggedInMsgClicks();
      };

      init();
   });
};

ProductListingSellerPage();
