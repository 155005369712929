import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { DomLitElement } from "../../../../lit-extensions";
import { store } from "../../../../store";
import { selectProductListingConfig } from "../../product-listing-slice";

@customElement("product-count")
export class ProductCount extends DomLitElement {
   @property()
   itemCount = 0;

   render() {
      const {
         translations: { productLabel, productsLabel },
      } = selectProductListingConfig(store.getState());

      return html`
         <div class="listing-options-count">
            ${this.itemCount === 1
               ? productLabel?.replace("{0}", `${this.itemCount}`)
               : productsLabel?.replace("{0}", `${this.itemCount}`)}
         </div>
      `;
   }
}
