import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { DomLitElement } from "../../lit-extensions";
import styles from "./back-to-top-button.module.scss";

@customElement("back-to-top-button")
export class BackToTopButton extends DomLitElement {
   private scrollToTop = () => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
   }

   private handleClick = async (ev: Event) => {
      ev.preventDefault();

      this.scrollToTop();
   }

   render() {
      return html`
         <button class=${styles.button} @click=${this.handleClick}>
            <span class="icon p12icon-arrow-up"></span>
         </button>
      `;
   }
}