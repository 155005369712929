import { validateEmail } from "@afound/common";

/**
 * Convenience method for validating an input field while also managing validation. If the field is empty,
 * a required message will be shown. If a validator function is provided, a custom error message will be shown
 * when it evaluates to false.
 * @param input The HTML input element to be validated.
 * @param error The element containing the validation error message.
 * @param messages An object containing the required error and a custom message if a validator is provided.
 * @param validator (optional) A callback that accepts the input value and returns a boolean indicating validity.
 * @returns True if field is valid, false otherwise.
 */
export const validateField = (
   input: HTMLInputElement | HTMLTextAreaElement,
   error: HTMLElement,
   messages: { required: string; custom?: string },
   validator?: (value: string) => boolean
) => {
   if (input.validity.valueMissing) {
      error.textContent = messages.required;
      error.className = "error form-control__error";
      input.className = "form-control__input error";
      return false;
   }

   if (validator) {
      const isValid = validator(input.value);
      if (!isValid) {
         error.textContent = messages.custom || "";
         error.className = "error form-control__error";
         input.className = "form-control__input error";
         return false;
      }
   }

   error.textContent = "";
   error.classList.remove("form-control__error");
   input.classList.remove("error");

   return true;
};

/**
 * Helper for validating emails. Automatically transforms emails to lowercase before validating, and
 * updates the field value as well.
 * @param input The HTML input element to be validated.
 * @param error The element containing the validation error message.
 * @param messages A set of validation messages for different types of errors.
 * @returns True if field is valid, false otherwise.
 */
export const validateEmailToLower = (
   input: HTMLInputElement,
   error: HTMLElement,
   messages: { required: string; custom?: string }
) =>
   validateField(input, error, messages, (value) => {
      if (!value) {
         return false;
      }

      const transformed = value.trim().toLowerCase();
      input.value = transformed;

      return validateEmail(transformed);
   });

export const validateCheckbox = (
   element: HTMLInputElement,
   errorElement: HTMLElement,
   messages: { required: string }
) => {
   if (!element.checked) {
      errorElement.textContent = messages.required;
      errorElement.className = "error form-control__error";
      element.className = "form-control__input error";
      return false;
   } else {
      errorElement.textContent = "";
   }

   return true;
};
