import { dispatchCustomEvent, waitForElement } from "@afound/common";
import { getOpenSizeFilterEvent } from "./tracking";
import { ShowFilterModal, FilterModalEventArgs } from "./types";

const ProductFilter = () => {
   waitForElement(".product-filter").then((filter) => {
      if (!filter) {
         return;
      }

      const elements = {
         sizeFilterTrigger: filter.querySelector(".listing-options-size-filter") as HTMLElement | null,
      };

      const init = () => {
         const { sizeFilterTrigger } = elements;

         if (!sizeFilterTrigger) {
            return;
         }

         sizeFilterTrigger.addEventListener("click", () => {
            dispatchCustomEvent<FilterModalEventArgs>(sizeFilterTrigger, ShowFilterModal, {
               defaultFilter: "v_brandsize",
            });

            window.dataLayer.push(getOpenSizeFilterEvent());
         });
      };

      init();
   });
};

ProductFilter();
