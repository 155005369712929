import { ApiError, Device, post, sessionStorage, dispatchCustomEvent } from "@afound/common";
import {
   AddToCartRequest,
   AddToCartResponse,
   ProductPageServerConfig,
   AddToCartEvent,
   AddToCartEventArgs,
} from "./types";
import { getGtmPdpData } from "./tracking";
import { HeaderCartCountEvent, HeaderCartCountEventArgs } from "../header/types";

interface CartElements {
   stickyAddToCartWrapper: HTMLElement;
   sizeSelect: HTMLSelectElement;
   addToCartButton: HTMLElement;
   cartErrorContainer: HTMLElement;
   cartErrorMessage: HTMLElement;
   siteFooter: HTMLElement;
   upSellingModalRoot?: HTMLElement;
}

interface CartProps extends Pick<ProductPageServerConfig, "productId" | "currency" | "price"> {
   currentDevice: Device;
   elements: CartElements;
}

const isCartResponse = (response: AddToCartResponse | string[] | undefined): response is AddToCartResponse =>
   !!(response as AddToCartResponse)?.result;

export const Cart = (props: CartProps) => {
   const {
      currentDevice,
      elements: {
         stickyAddToCartWrapper,
         sizeSelect,
         addToCartButton,
         cartErrorContainer,
         cartErrorMessage,
         siteFooter,
      },
   } = props;

   const setStickyAddToCartClasses = () => {
      if (currentDevice === "mobile") {
         stickyAddToCartWrapper.classList.add("new-product-page__container--sticky");

         const height = stickyAddToCartWrapper.getBoundingClientRect().height;
         siteFooter.setAttribute("style", `margin-bottom: ${height}px;`);
      }
   };

   const updateCartQuantity = (count: number) => {
      dispatchCustomEvent<HeaderCartCountEventArgs>(addToCartButton, HeaderCartCountEvent, { count });
   };

   const refreshCartError = (message?: string) => {
      if (message) {
         cartErrorMessage.textContent = message;
         cartErrorContainer.className =
            "server-error server-error--visible error-message new-product-page__server-error";
      }
      if (!message && cartErrorContainer.classList.contains("server-error--visible")) {
         cartErrorContainer.className = "server-error";
      }
   };

   const handleAddToCartClick = async () => {
      const selectedOfferCode = sizeSelect.options[sizeSelect.selectedIndex].value;
      const selectedSize = sizeSelect.options[sizeSelect.selectedIndex].text;
      const recommendationPanel = sessionStorage.getItem("af_panel");
      const promotion = sessionStorage.getItem("af_promotion");

      const promotionId = promotion?.split("|")[0] ?? null;
      const promotionName = promotion?.split("|")[1] ?? null;
      const promotionCreative = promotion?.split("|")[2] ?? null;

      const data = {
         offerCode: selectedOfferCode,
         recommendationPanel: recommendationPanel,
         promotionId: promotionId,
         promotionName: promotionName,
         promotionCreative: promotionCreative,
      };

      const cartButtonValue = addToCartButton.innerText;
      addToCartButton.classList.add("button--loading");
      addToCartButton.innerText = "";

      let response: AddToCartResponse | string[] | undefined;
      try {
         response = await post<AddToCartRequest, AddToCartResponse | string[]>("/api/cart", data);
      } catch (err) {
         const apiError = err as ApiError;
         response = apiError.content as string[];
      }

      if (!isCartResponse(response)) {
         refreshCartError(response![0]);
         addToCartButton.classList.remove("button--loading");
         addToCartButton.innerText = cartButtonValue;
         return;
      }

      dispatchCustomEvent<AddToCartEventArgs>(addToCartButton, AddToCartEvent, { sizeInfo: selectedSize });
      updateCartQuantity(response.result.totalItemsCount);
      refreshCartError();

      addToCartButton.classList.remove("button--loading");
      addToCartButton.innerText = cartButtonValue;

      if (window.dataLayer) {
         window.dataLayer.push(
            getGtmPdpData("addToCartEvent", {
               cartTotal: response.result.total,
               cart: response.cartSnapshot,
               selectedSize: selectedSize,
               selectedOfferCode: selectedOfferCode,
               eventId: response.fbEventId,
               recommendationPanel: recommendationPanel,
               promotionId: promotionId,
               promotionName: promotionName,
               promotionCreative: promotionCreative,
            })
         );
      }
   };

   setStickyAddToCartClasses();

   if (addToCartButton) {
      addToCartButton.addEventListener("click", handleAddToCartClick);
   }
};
