// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/svg/icon-circle.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".egfaIIG2_ThwNXus4e8x{font-family:p12-001, \"Helvetica Narrow\", \"Arial Narrow\", Tahoma, Arial, Helvetica, sans-serif;font-weight:normal;font-size:12px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");color:#fff;width:16px;height:17px;display:none;align-items:center;justify-content:center;line-height:1;margin-left:6px}.egfaIIG2_ThwNXus4e8x.XOsdipsjRGFc9tsvmup2{display:flex}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/product-listing/filter-count/filter-count.module.scss"],"names":[],"mappings":"AAAA,sBACG,6FAA8F,CAC9F,kBAAmB,CACnB,cAAe,CACf,wDAAyD,CACzD,UAAW,CACX,UAAW,CACX,WAAY,CACZ,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,aAAc,CACd,eAAgB,CAZnB,2CAeM,YAAa","sourcesContent":[".count {\r\n   font-family: p12-001, \"Helvetica Narrow\", \"Arial Narrow\", Tahoma, Arial, Helvetica, sans-serif;\r\n   font-weight: normal;\r\n   font-size: 12px;\r\n   background-image: url(/assets/images/svg/icon-circle.svg);\r\n   color: #fff;\r\n   width: 16px;\r\n   height: 17px;\r\n   display: none;\r\n   align-items: center;\r\n   justify-content: center;\r\n   line-height: 1;\r\n   margin-left: 6px;\r\n\r\n   &.visible {\r\n      display: flex;\r\n   }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"count": "egfaIIG2_ThwNXus4e8x",
	"visible": "XOsdipsjRGFc9tsvmup2"
};
export default ___CSS_LOADER_EXPORT___;
