import { ApiError, ApiResponse, ProblemDetails } from "@afound/common";
import ai from "../ai";

const isApiErrorWithProblemDetails = (err: Error): err is ApiError<ProblemDetails> => !!(err as any).content?.title;

export const handleApiError = <T>(err: unknown, aiFeature: string): ApiResponse<T> => {
   const error = err as Error;

   ai.trackException({ exception: error }, { aiFeature });

   const errorMessage = isApiErrorWithProblemDetails(error) ? error.content?.title : error.message;

   return {
      errorMessage,
   };
};
