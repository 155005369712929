import { FancySelect, SelectOption } from "@afound/react";
import { Formik } from "formik";
import { ProductSize } from "../../../../shared/types";
import { BackInStockServerConfig, BackInStockTexts } from "../types";

import styles from "./size-select.module.scss";
import { selectTranslations } from "../../../my-pages/settings/settings-slice";
import { useAppSelector } from "../../../../store";

type SizeOptionProps = SelectOption &
   Pick<ProductSize, "isSoldOut"> &
   Pick<SizeSelectProps, "onNotifyClick"> & {
      texts: BackInStockTexts;
   };

const SizeOption = (props: SizeOptionProps) => {
   const {
      text,
      isSoldOut,
      texts,
   } = props;

   return (
      <div className={styles.option}>
         <span className={isSoldOut ? styles.soldOut : ""}>{text}</span>
         {isSoldOut && (
               <span>{texts.soldOut}</span>
         )}
      </div>
   );
};

interface SizeSelectProps extends Pick<BackInStockServerConfig, "sizes" | "texts"> {
   onNotifyClick: (sizeValue: string) => void;
}

export const SizeSelect = (props: SizeSelectProps) => {
   const { sizes, texts, onNotifyClick } = props;

   const handleSizeChange = (ev: React.ChangeEvent) => {
      const target = ev.target as HTMLSelectElement;

      // Dispatch event manually since just setting the value via Formik won't trigger an event.
      // This is needed so that the underlying (native) select plays nicely with the PDP.
      target.dispatchEvent(new Event("change"));
   };

   const getSizeInfo = (sizeId: string): { label: string; isSoldOut: boolean } => {
      const size = sizes.find((s) => s.id === sizeId);
      if (!size) {
         return { label: "", isSoldOut: false };
      }

      const { name, isSoldOut, isFewLeft } = size;
      const label = (name || texts.sizeFallback) + (isFewLeft ? ` - ${texts.fewLeft}` : "");

      return { label, isSoldOut };
   };

   return (
      <Formik initialValues={{ size: sizes.find((s) => s.selected)?.id || "" }} onSubmit={() => {}}>
         <FancySelect
            name="size"
            className={styles.selectWrapper}
            options={sizes.map((s) => {
               const { label, isSoldOut } = getSizeInfo(s.id);
               return { text: label, value: s.id, disabled: isSoldOut };
            })}
            renderOption={(o) => {
               const { label, isSoldOut } = getSizeInfo(o.value);
               return <SizeOption {...{ ...o, text: label, isSoldOut }} texts={texts} onNotifyClick={onNotifyClick} />;
            }}
            animationStyle="in"
            disableStackingContext={{
               targetElementQuery: ".new-product-page__container",
            }}
            onChange={handleSizeChange}
         />
      </Formik>
   );
};
