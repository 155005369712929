import { GtmEvent } from "@afound/types";

export const getLoginModalEvent = () => ({
   event: "modals",
   eventCategory: "modals",
   eventAction: "open",
   modalId: "login",
});

export const getLoginEvent = (action: "login" | "login:error", label: string): GtmEvent => ({
   event: "loginEvent",
   eventCategory: "login",
   eventAction: action,
   eventLabel: label,
});
