import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import { waitForElement } from "../../shared/dom";

const InspirationalCarousel = () => {
   waitForElement(".inspirational-carousel-block").then(() => {
      let swipers = document.querySelectorAll(".inspirational-carousel-block .swiper") as NodeListOf<HTMLElement>;

      for (let i = 0; i < swipers.length; i++) {
         let swiperId = `swiper-inspirational-carousel-${i}`;
         swipers[i].classList.add("af-initiated");
         swipers[i].classList.add(swiperId);

         let slidesPerView = 1;
         let slidesPerGroup = 1;
         let spaceBetween = 56;

         if (swipers[i].classList.contains("swiper--inspirational-carousel-3-images-layout")) {
            spaceBetween = 91;
         }

         let swiperProperties = {
            slidesPerView,
            spaceBetween,
            slidesPerGroup,
            preloadImages: false,
            lazy: {
               checkInView: true,
               loadPrevNext: true,
               loadOnTransitionStart: true,
               loadPrevNextAmount: 1,
            },
            pagination: {
               el: `.${swiperId} .swiper-pagination`,
               clickable: true,
            },
            navigation: {
               nextEl: `.${swiperId} .swiper-button-next`,
               prevEl: `.${swiperId} .swiper-button-prev`,
            },
            breakpoints: {
               "@0.00": {
                  slidesPerView,
                  slidesPerGroup,
                  spaceBetween: 12,
               },
               "@0.75": {
                  slidesPerView,
                  slidesPerGroup,
                  spaceBetween,
               },
               "@1.00": {
                  slidesPerView,
                  slidesPerGroup,
                  spaceBetween,
               },
            },
            modules: [Navigation, Pagination],
         };

         new Swiper(`.${swiperId}`, swiperProperties);
      }
   });
};

InspirationalCarousel();
