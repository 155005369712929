import { dispatchCustomEvent, waitForElement } from "../../shared/dom";
import {
   createGtmAccordionData,
   getFlexibleReturnFeeLoginOrRegisterGtmEvent,
   getGtmPdpData,
} from "./tracking";

//INVESTIGATE IF WE STILL NEED THIS, WE HAVE NO PRODUCTLIST ON PDP ANYMORE
import productUINew from "../../../Scripts/Features/Product/product.js";
import { Cart } from "./cart";
import { Device, currentDevice, sessionStorage } from "@afound/common";
import { ProductPageServerConfig } from "./types";
import { Sizes } from "./sizes";
import { ShowLoginModal } from "../login/types";

export * from "./back-in-stock";
export * from "./promotion";

const ProductPage = (serverConfig: ProductPageServerConfig) => {
   waitForElement(".new-product-page").then((productPage) => {
      waitForElement(".new-product-page select[name='size']").then(async (sizeSelect) => {
         if (!productPage) {
            return;
         }

         const { hasReviews, productRelationGuid, currentCulture, sizes, currency, translations } = serverConfig;

         const elements = {
            siteFooter: document.querySelector("footer") as HTMLElement,
            upSellingModalRoot: document.querySelector("#upselling-root-template") as HTMLElement | undefined,
            orpPriceLabel: productPage.querySelector(
               ".new-product-page__description-section__price-orp"
            ) as HTMLElement,
            cpPriceLabel: productPage.querySelector(".new-product-page__description-section__price-cp") as HTMLElement,
            compPriceLabel: productPage.querySelector(
               ".new-product-page__description-section__comparison-price"
            ) as HTMLElement,
            discountPriceLabel: productPage.querySelector(
               ".new-product-page__description-section__price-discount"
            ) as HTMLElement,
            sizeSelect: sizeSelect as HTMLSelectElement,
            addToCartButton: productPage.querySelector("#addToCart") as HTMLElement,
            pdpReviewsSection: productPage.querySelector("#pdp-reviews") as HTMLElement,
            pdpReviewsWrapper: productPage.querySelector(".pdp-reviews-wrapper") as HTMLElement,
            cartErrorContainer: productPage.querySelector(
               ".new-product-page__container span.server-error"
            ) as HTMLElement,
            cartErrorMessage: productPage.querySelector(
               ".new-product-page__container span.server-error--text"
            ) as HTMLElement,
            stickyAddToCartWrapper: productPage.querySelector(".new-product-page__container") as HTMLElement,
            wishlistSection: productPage.querySelector(".new-product-page__wishlist") as HTMLElement,
         };

         const [device] = currentDevice({ desktop: 992 });

         const setUpAccordions = () => {
            productPage.querySelectorAll(".new-product-page__accordion-section__title").forEach((ele) => {
               ele.addEventListener(
                  "click",
                  (e: Event) => {
                     const target = e.target as HTMLButtonElement;
                     dataLayer.push(createGtmAccordionData(target.id));
                  },
                  { once: true }
               );
            });
         };

         const setUpDeliverySection = () => {
            productPage.querySelectorAll(".new-product-page__delivery-section__text").forEach((ele) => {
               ele.addEventListener("click", (e: Event) => {
                  const target = e.target as HTMLElement;
                  if (target.classList.contains("js-login-link")) {
                     const action = "login";
                     dispatchCustomEvent(target!, ShowLoginModal, { defaultTab: action });
                     window.dataLayer.push(getFlexibleReturnFeeLoginOrRegisterGtmEvent(action));
                  } else if (target.classList.contains("js-register-link")) {
                     const action = "register";
                     dispatchCustomEvent(target!, ShowLoginModal, { defaultTab: action });
                     window.dataLayer.push(getFlexibleReturnFeeLoginOrRegisterGtmEvent(action));
                  }
               });
            });
         };

         const setUpReviews = () => {
            elements.pdpReviewsSection.addEventListener(
               "click",
               () => {
                  elements.pdpReviewsWrapper.insertAdjacentHTML(
                     "afterbegin",
                     `<product-reviews productRelationGuid="${productRelationGuid}" culture="${currentCulture}"></product-reviews>`
                  );
               },
               { once: true }
            );
         };

         const init = async (device: Device) => {
            productUINew.init(); // TODO: migrate from legacy

            Sizes({ currency, productSizes: sizes, texts: translations, elements });
            Cart({ currentDevice: device, ...serverConfig, elements });

            setUpAccordions();
            setUpDeliverySection();

            if (hasReviews) {
               setUpReviews();
            }

            if (dataLayer) {
               const promotion = sessionStorage.getItem("af_promotion");

               const promotionId = promotion?.split("|")[0] ?? null;
               const promotionName = promotion?.split("|")[1] ?? null;
               const promotionCreative = promotion?.split("|")[2] ?? null;

               dataLayer.push(
                  getGtmPdpData("productDetailEvent", {
                     cartTotal: 0,
                     cart: undefined,
                     eventId: serverConfig.fbEventId,
                     promotionId: promotionId,
                     promotionName: promotionName,
                     promotionCreative: promotionCreative,
                  })
               );
            }
         };

         init(device);
      });
   });
};

if (INITIALDATA.newProduct) {
   ProductPage(INITIALDATA.newProduct);
}
