// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FQwxB6doA3TGNPCYavTc{font-family:p12-desc}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/product-page/back-in-stock/back-in-stock-wrapper/back-in-stock-wrapper.module.scss"],"names":[],"mappings":"AAAA,sBACC,oBAAqB","sourcesContent":[".wrapper {\r\n\tfont-family: p12-desc;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "FQwxB6doA3TGNPCYavTc"
};
export default ___CSS_LOADER_EXPORT___;
