import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import { ProductListingServerConfig } from "./types";

export interface ProductListingState {
   config: ProductListingServerConfig;
}

export const initialState: ProductListingState = {
   config: {
      translations: {},
      sortOptions: [],
      filterCount: 0,
      productCount: 0,
   },
};

export const productListingSlice = createSlice({
   name: "product-listing",
   initialState,
   reducers: {
      setProductListingServerConfig: (state, action: PayloadAction<ProductListingServerConfig>) => {
         state.config = action.payload;
      },
   },
});

export const { setProductListingServerConfig } = productListingSlice.actions;

export const selectProductListingConfig = (s: RootState) => s.productListing.config;

export default productListingSlice.reducer;
