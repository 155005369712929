export const WishlistOpenEvent = "wishlist-modal:open";

export interface WishlistServerConfig {
   locale: string;
   pricingFaqPageFaq: string;
   translations: WishlistTexts;
}

export type WishlistTexts = {
   heading: string;
   headingNotLoggedIn: string;
   notLoggedIn: string;
   headingNoFavorites: string;
   itemCountLabel: string;
   noFavorites: string;
   soldOut: string;
   flashDeal: string;
   fewLeft: string;
   size: string;
   oneSize: string;
   addToCart: string;
   logIn: string;
   register: string;
   errorNotification: string;
   wasPriceInfo: string;
   priorPriceInfo: string;
   discountInfo: string;
   priceGuarantee: string;
};

export const wishlistActions = {
   add: "wishlist/add",
   addFromCart: "wishlist/addFromCart",
   addToCart: "wishlist/addToCart",
   fetch: "wishlist/fetch",
   update: "wishlist/update",
   remove: "wishlist/remove",
};

export type WishlistAction = keyof typeof wishlistActions;
