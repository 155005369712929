import { ApiError, ApiStatus, ProblemDetails, del, get, post, put } from "@afound/common";
import {
   AddToCartRequest,
   RemoveFromWishlistRequest,
   UpdateWishlistRequest,
   WishlistApiModel,
   WishlistItem,
   WishlistItemRef,
   WishlistModel,
} from "@afound/types";
import { createAsyncThunk, createSlice, isAllOf, isAnyOf, SerializedError } from "@reduxjs/toolkit";
import ai from "../ai";
import { startAppListening } from "./listener";
import { isActionOfType } from "../shared/redux-extensions";
import { RootState } from ".";
import { trackEsales, trackGtm } from "../features/wishlist/tracking";
import { WishlistAction, wishlistActions, WishlistServerConfig } from "../features/wishlist/types";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface WishlistState {
   config: WishlistServerConfig;
   model: WishlistModel;
   status: ApiStatus;
   currentAction: WishlistAction | undefined;
   lastAction: WishlistAction | undefined;
}

export const initialState: WishlistState = {
   config: {
      locale: "",
      pricingFaqPageFaq: "",
      translations: {} as any,
   },
   model: {
      items: [],
   } as any,
   status: "idle",
   currentAction: undefined,
   lastAction: undefined,
};

const addToWishlistThunkCreator = (actionType: string) =>
   createAsyncThunk(actionType, async (item: WishlistItemRef, { rejectWithValue }) => {
      try {
         const wishlistRefs = await post<WishlistItemRef, WishlistApiModel<WishlistItem>>("/api/v2/wishlist", item);
         return wishlistRefs;
      } catch (err) {
         const apiError = err as ApiError<ProblemDetails>;
         if (!apiError?.content) {
            throw err;
         }

         return rejectWithValue(apiError.content.title);
      }
   });

export const addToWishlist = addToWishlistThunkCreator(wishlistActions.add);

export const addToWishlistFromCart = addToWishlistThunkCreator(wishlistActions.addFromCart);

export const fetchWishlist = createAsyncThunk(
   wishlistActions.fetch,
   async () => await get<WishlistApiModel<WishlistItem[]>>("/api/v2/wishlist")
);

export const updateWishlist = createAsyncThunk(
   wishlistActions.update,
   async (request: UpdateWishlistRequest) =>
      await put<UpdateWishlistRequest, WishlistApiModel<WishlistItem>>("/api/v2/wishlist", request)
);

export const removeFromWishlist = createAsyncThunk(
   wishlistActions.remove,
   async (removed: RemoveFromWishlistRequest) =>
      await del<RemoveFromWishlistRequest, WishlistApiModel<WishlistItemRef>>("/api/v2/wishlist", removed)
);

export const addToCart = createAsyncThunk(wishlistActions.addToCart, async (request: AddToCartRequest) => {
   await post<AddToCartRequest & { quantity: number }, {}>("/api/cart", { ...request, quantity: 1 });

   const [variantId, offerId] = request.offerCode.split("_");
   return await del<RemoveFromWishlistRequest, WishlistApiModel<WishlistItemRef>>("/api/v2/wishlist", {
      variantId,
      offerId,
      productCode: request.productCode,
   });
});

const asWishlistAction = (actionType: string) => actionType.split("/")[1] as WishlistAction;

const fulfilledReducer = <T>(state: WishlistState, action: PayloadAction<T>, model: WishlistModel): WishlistState => {
   return {
      ...state,
      status: "success",
      model,
      lastAction: asWishlistAction(action.type),
   };
};

const modelReducer = (apiModel: Omit<WishlistApiModel<unknown>, "data">, newItems: WishlistItem[]): WishlistModel => {
   return {
      ...apiModel,
      items: newItems,
      totalAmount: newItems.reduce((prev, cur) => (prev += cur.price.displayPrice), 0),
      totalWasAmount: newItems.reduce((prev, cur) => (prev += cur.price.wasPrice || 0), 0),
   };
};

export const wishlistSlice = createSlice({
   name: "wishlist",
   initialState,
   reducers: {
      setServerConfig: (state, action: PayloadAction<WishlistServerConfig>) => {
         state.config = action.payload;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(fetchWishlist.fulfilled, (state, action) => {
         const { data: items, ...rest } = action.payload!;
         return fulfilledReducer(state, action, modelReducer(rest, items));
      });

      builder.addCase(updateWishlist.fulfilled, (state, action) => {
         const { data: updated, ...rest } = action.payload!;

         const updatedIndex = state.model.items.findIndex((i) => i.productCode === updated.productCode);
         const items = [...state.model.items];
         items[updatedIndex] = updated;

         return fulfilledReducer(state, action, modelReducer(rest, items));
      });

      builder.addMatcher(isAnyOf(addToWishlist.fulfilled, addToWishlistFromCart.fulfilled), (state, action) => {
         const { data: added, ...rest } = action.payload!;
         const items = [added, ...state.model.items];

         return fulfilledReducer(state, action, modelReducer(rest, items));
      });

      builder.addMatcher(isAnyOf(removeFromWishlist.fulfilled, addToCart.fulfilled), (state, action) => {
         const { data: removed, ...rest } = action.payload!;
         const items = state.model.items.filter((i) => i.productCode !== action.payload!.data.productCode);

         return fulfilledReducer(state, action, modelReducer(rest, items));
      });

      builder.addMatcher(isAllOf(isActionOfType("pending")), (state, action) => {
         state.status = "loading";
         state.currentAction = asWishlistAction(action.type);
      });

      builder.addMatcher(
         isAllOf(isActionOfType("rejected")),
         (state, action: PayloadAction<unknown> & { error?: SerializedError }) => {
            state.status = "error";
            state.lastAction = asWishlistAction(action.type);
            ai.trackException({ exception: action.error as Error, properties: { feature: "wishlist" } });
         }
      );
   },
});

export const { setServerConfig } = wishlistSlice.actions;

export const selectWishlist = (s: RootState) => s.wishlist;
export const selectWishlistModel = (s: RootState) => s.wishlist.model;
export const selectWishlistConfig = (s: RootState) => s.wishlist.config;

export default wishlistSlice.reducer;

startAppListening({
   matcher: isAnyOf(
      addToWishlist.fulfilled,
      addToWishlistFromCart.fulfilled,
      addToCart.fulfilled,
      removeFromWishlist.fulfilled
   ),
   effect: async (action: PayloadAction<WishlistApiModel<unknown> | undefined>, listenerApi) => {
      const wishlist = listenerApi.getState().wishlist.model;
      const prevWishlist = listenerApi.getOriginalState().wishlist.model;

      const wishlistAction = asWishlistAction(action.type);
      trackGtm(wishlistAction, action.payload!, wishlist, prevWishlist);
      await trackEsales(wishlistAction, action.payload!, prevWishlist);
   },
});
