import { createRoot } from "react-dom/client";
import { ChatWrapper } from "./chat-wrapper";
import { Provider } from "react-redux";
import { store } from "../../store";

const chatRoot = document.getElementById("chat-root");
if (false) {
   createRoot(chatRoot).render(
      <Provider store={store}>
         <ChatWrapper {...INITIALDATA.chat} />
      </Provider>
   );
}
