import { useRef, useState } from "react";
import { Tooltip, useOutsideClick } from "@afound/react";

import styles from "./promotion-tooltip.module.scss";

interface PromotionTooltipProps {
   text: string;
}

export const PromotionTooltip = (props: PromotionTooltipProps) => {
   const { text } = props;

   const [visible, setVisible] = useState(false);

   const handleInfoIconClick = () => setVisible((prev) => !prev);

   const tooltipRef = useRef(null);
   useOutsideClick(tooltipRef, () => setVisible(false));

   return (
      <span ref={tooltipRef} className={styles.tooltipWrapper}>
         <span className="icon p12icon-info-gray" onClick={handleInfoIconClick}>
            <Tooltip visible={visible} position="above" className={styles.tooltipText}>
               {text}
            </Tooltip>
         </span>
      </span>
   );
};
