// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oTDQLOpGjPvm1POC1Q8z{position:relative}.oTDQLOpGjPvm1POC1Q8z:after{content:'';position:absolute;top:0;left:0;height:100%;width:100%;background-color:#fff;opacity:0.8;z-index:1}.oTDQLOpGjPvm1POC1Q8z .j2OcbdjID8oHz3Allrqh{font-family:\"p12-text\";font-size:24px;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:2}.jkPIHalcOz41KLdjFD1f{padding:0 8px;margin-top:8px}.KW1quJgdPGIsIoD8jZSf{margin-bottom:8px}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/wishlist/wishlist-product/wishlist-product.module.scss"],"names":[],"mappings":"AAAA,sBACC,iBAAkB,CADnB,4BAIE,UAAW,CACX,iBAAkB,CAClB,KAAM,CACN,MAAO,CACP,WAAY,CACZ,UAAW,CACX,qBAAsB,CACtB,WAAY,CACZ,SAAU,CAZZ,4CAgBE,sBAAuB,CACvB,cAAe,CACf,iBAAkB,CAClB,OAAQ,CACR,QAAS,CACT,+BAAgC,CAChC,SAAU,CACV,sBAID,aAAc,CACd,cAAe,CACf,sBAGA,iBAAkB","sourcesContent":[".soldOut {\r\n\tposition: relative;\r\n\r\n\t&:after {\r\n\t\tcontent: '';\r\n\t\tposition: absolute;\r\n\t\ttop: 0;\r\n\t\tleft: 0;\r\n\t\theight: 100%;\r\n\t\twidth: 100%;\r\n\t\tbackground-color: #fff;\r\n\t\topacity: 0.8;\r\n\t\tz-index: 1;\r\n\t}\r\n\r\n\t.soldOutLabel {\r\n\t\tfont-family: \"p12-text\";\r\n\t\tfont-size: 24px;\r\n\t\tposition: absolute;\r\n\t\ttop: 50%;\r\n\t\tleft: 50%;\r\n\t\ttransform: translate(-50%, -50%);\r\n\t\tz-index: 2;\r\n\t}\r\n}\r\n\r\n.actions {\r\n\tpadding: 0 8px;\r\n\tmargin-top: 8px;\r\n}\r\n\r\n.selectWrapper {\r\n\tmargin-bottom: 8px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"soldOut": "oTDQLOpGjPvm1POC1Q8z",
	"soldOutLabel": "j2OcbdjID8oHz3Allrqh",
	"actions": "jkPIHalcOz41KLdjFD1f",
	"selectWrapper": "KW1quJgdPGIsIoD8jZSf"
};
export default ___CSS_LOADER_EXPORT___;
