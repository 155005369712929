import { displayWithCurrency } from "../../shared/utils";
import { createGtmSizeData } from "./tracking";
import { ProductPrice, ProductSize } from "./types";

interface SizesElements {
   sizeSelect: HTMLSelectElement;
   orpPriceLabel: HTMLElement;
   cpPriceLabel: HTMLElement;
   compPriceLabel: HTMLElement;
   discountPriceLabel: HTMLElement;
}

interface SizesProps {
   currency: string;
   productSizes: ProductSize[];
   texts: {
      priorPrice: string;
   };
   elements: SizesElements;
}

export const Sizes = (props: SizesProps) => {
   const {
      currency,
      productSizes,
      elements: { sizeSelect, orpPriceLabel, cpPriceLabel, compPriceLabel, discountPriceLabel },
      texts,
   } = props;

   const handlePriceChanges = (selectedPrice: ProductPrice, currency: string, previousPriceLabel: string) => {
      if (selectedPrice.originalPrice) {
         orpPriceLabel.textContent = displayWithCurrency(selectedPrice.originalPrice, currency);
      }

      // Need to get ppPriceLabel in real-time since it will get added/removed dynamically
      const ppPriceLabel = document.querySelector(".new-product-page__description-section__price-pp") as HTMLElement;

      if (selectedPrice.previousPrice && !ppPriceLabel) {
         const template = `<div class="new-product-page__description-section__price-wrapper__inline new-product-page__description-section__price-pp-target">
            <span class="new-product-page__description-section__price-astrix">${previousPriceLabel}***</span>
            <span class="new-product-page__description-section__price-pp">${displayWithCurrency(
               selectedPrice.previousPrice,
               currency
            )}</span>
         </div>`;

         cpPriceLabel.insertAdjacentHTML("beforebegin", template);
      } else if (selectedPrice.previousPrice) {
         ppPriceLabel.textContent = displayWithCurrency(selectedPrice.previousPrice, currency);
      } else if (ppPriceLabel) {
         const ppTarget = document.querySelector(
            ".new-product-page__description-section__price-pp-target"
         ) as HTMLElement;
         ppTarget.remove();
      }

      if (selectedPrice.currentPrice) {
         cpPriceLabel.textContent = displayWithCurrency(selectedPrice.currentPrice, currency);
      }
      if (selectedPrice.comparisonPriceInfoFormatted) {
         compPriceLabel.textContent = selectedPrice.comparisonPriceInfoFormatted;
      }
      if (selectedPrice.discountPercentage) {
         discountPriceLabel.textContent = `${selectedPrice.discountPercentage}%`;
      }
   };

   const setUpSizeSelect = () => {
      let previousSelectedSize = sizeSelect.options[sizeSelect.selectedIndex].text;

      sizeSelect.addEventListener("change", () => {
         const selectedOption = sizeSelect.options[sizeSelect.selectedIndex];

         const selectedSizeText = selectedOption.innerText;
         const selectedPrice = productSizes.find((size) => size.id === selectedOption.value)?.prices;

         window.dataLayer.push(createGtmSizeData(`changed from ${previousSelectedSize} to ${selectedSizeText}`));

         previousSelectedSize = selectedSizeText;

         if (selectedPrice) {
            handlePriceChanges(selectedPrice, currency, texts.priorPrice);
         }
      });
   };

   setUpSizeSelect();
};
