import { createRoot } from "react-dom/client";
import { CartModal } from "./cart-modal";
import ai from "../../ai";
import { Provider } from "react-redux";
import { store } from "../../store";
import { ResponsiveContext } from "@afound/react";

const cartRoot = document.getElementById("cart-root");

if (cartRoot) {
   const modalToggle = document.getElementById("cart-header-button");

   if (!modalToggle) {
      ai.trackException(
         {
            exception: new Error(
               "Failed to render cart. Expected to find a header toggle button with class cart-header-button but found none"
            ),
         },
         { feature: "cart" }
      );
   } else {
      createRoot(cartRoot).render(
         <Provider store={store}>
            <ResponsiveContext.Provider value={{ desktop: 992 }}>
               <CartModal {...INITIALDATA.cart} modalToggle={modalToggle} />
            </ResponsiveContext.Provider>
         </Provider>
      );
   }
}
