import { ProductCard } from "@afound/react";
import { SitePopup } from "../../components";
import { useUpselling } from "./use-upselling";
import styles from "./upselling-popup.module.scss";
import { useEffect, useState } from "react";
import { UpsellingCarousel } from "./upselling-carousel";
import { UpdateCartQuantity as updateCartQuantity } from "../../../Scripts/New/Product/DOM";
import { AddToCartEvent, AddToCartEventArgs } from "../product-page/types";
import { getProductClickGtmEvent, sessionStorage } from "@afound/common";
import { UpsellingProduct } from "./types";
import { Product } from "@afound/types";
import { mapUpsellingProductToProduct } from "./mapping";
import { createAddToCartData, createGtmPromoImpressionData, createGtmPromotionClickData } from "./tracking";

const upsellingPopupCountKey = "pdp_upsellingPopupCount";

const getDepartmentName = (departmentId: string | null) : string => {
   switch (departmentId) {
      case "87bc1068-fd6b-45f9-9cf3-d747dc27f419":
         return "DAM";
      case "ab47c989-3f72-4a9d-a9aa-624f413311f1":
         return "HERR";
      case "521fe0c8-8814-479b-b28a-a4e67a9f029e":
         return "BARN";
      case "1ddb9dcd-549b-4565-9f95-28aca1731467":
         return "INREDNING";
      case "495f3af0-6dbe-4a45-b987-feb85bfb069b":
         return "BEAUTY";
      default:
         return "";
   }
}

interface UpsellingProps {
   currentCulture: string;
   currency: string;
   gender: string;
   epiProductKey: string;
   translations: {
      upsellingTitle: string;
      discountInfo: string;
      priorPriceInfo: string;
      wasPriceInfo: string;
      priceGuarantee: string;
      addToCart: string;
      addedToCart: string;
      soldOut: string;
   };
}

export const Upselling = (props: UpsellingProps) => {
   const upsellingPopupCount = Number(sessionStorage.getItem(upsellingPopupCountKey));
   const [disableModal, setDisableModal] = useState<boolean>(false);
   const [hasSingleSizeFilter, setHasSingleSizeFilter] = useState<boolean>(false);
   const {
      currentCulture,
      currency,
      gender,
      epiProductKey,
      translations: {
         upsellingTitle,
         discountInfo,
         priceGuarantee,
         priorPriceInfo,
         wasPriceInfo,
         addToCart: addToCartTranslation,
         addedToCart,
         soldOut,
      },
   } = props;
   const [recommendedProducts, setRecommendedProducts] = useState<UpsellingProduct[]>([]);
   const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
   const [title, setTitle] = useState<string>(upsellingTitle);
   const { fetchUpsellingProducts, addToCart } = useUpselling();
   const currentDepartment = document.body.getAttribute("data-current-department");
   const promotionCreative = `UpsellingModule - ${getDepartmentName(currentDepartment)}`;
   const promotionId = "e512cc58-56a5-43d6-bbe8-1f98d9609654";
   const promotionName = "UpsellingModule";

   const handleAddToCartClick = async (product: Product) => {
      const response = await addToCart(product.offerCode, promotionId, promotionName, promotionCreative);
      if (response) {
         window.dataLayer.push(createGtmPromotionClickData(promotionId, promotionName, promotionCreative, product.productUrl));
         window.dataLayer.push(
            createAddToCartData(product, 
               currency, 
               response.result.total,
               response.fbEventId,
               promotionId,
               promotionName,
               promotionCreative,
            )
         );
         updateCartQuantity();
         
         return;
      }
      return soldOut;
   };

   const handleClose = () => {
      setDisableModal(true);
   };

   const handleProductClick = (product: Product) => {
      window.dataLayer.push(getProductClickGtmEvent(product, currency, promotionName));
   };   

   const handleAddToCartExternalEvent = async (ev: Event) => {
      const { sizeInfo } = (ev as CustomEvent<AddToCartEventArgs>).detail;
      if (!disableModal) {
         if (upsellingPopupCount % 2 == 0) {
            const upsellingProductsResult = await fetchUpsellingProducts(epiProductKey, gender, sizeInfo);
            if (upsellingProductsResult?.products) {
               setRecommendedProducts(upsellingProductsResult?.products);
               setHasSingleSizeFilter(upsellingProductsResult.hasSingleSizeFilter);
               if (upsellingProductsResult.customTitle) {
                  setTitle(upsellingProductsResult.customTitle);
               }
               sessionStorage.setItem(upsellingPopupCountKey, "1");
            }
         } else {
            sessionStorage.setItem(upsellingPopupCountKey, (upsellingPopupCount + 1).toString());
         }
      }
   };

   useEffect(() => {
      const isVisible = !disableModal && recommendedProducts.length > 0;
      setIsModalVisible(isVisible);
      if (isVisible) {
         window.dataLayer.push(createGtmPromoImpressionData(promotionId, promotionName, promotionCreative, window.location.href));
      }
   }, [disableModal, recommendedProducts]);

   useEffect(() => {
      document.addEventListener(AddToCartEvent, handleAddToCartExternalEvent);

      return () => document.removeEventListener(AddToCartEvent, handleAddToCartExternalEvent);
   }, [disableModal]);

   return (
      <SitePopup className={styles.upsellingModal} visible={isModalVisible} onClose={handleClose} type="upsellingPopup">
         <UpsellingCarousel title={title}>
            {recommendedProducts.map((r) => (
               <ProductCard
                  product={mapUpsellingProductToProduct(r)}
                  locale={currentCulture}
                  currency={currency}
                  labels={{
                     discountInfo: discountInfo,
                     priorPriceInfo: priorPriceInfo,
                     wasPriceInfo: wasPriceInfo,
                     priceGuarantee: priceGuarantee,
                     addToCart: addToCartTranslation,
                     addToCartCompleted: addedToCart,
                  }}
                  onAddToCartClick={handleAddToCartClick}
                  onProductClick={() => handleProductClick(mapUpsellingProductToProduct(r))}
                  className={styles.card}
                  imageContainerClassName={styles.cardImageContainer}
                  showAddToCart={hasSingleSizeFilter}
               />
            ))}
         </UpsellingCarousel>
      </SitePopup>
   );
};
