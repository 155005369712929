import { Device, animatePulse } from "@afound/common";

import { isAuthenticated } from "../../shared/auth";
import { dispatchCustomEvent, waitForElement } from "../../shared/dom";
import { store } from "../../store";
import { WishlistOpenEvent } from "./types";
import { fetchWishlist } from "../../store/wishlist-slice";

export const wishlistHeaderButton = (currentDevice: Device) => {
   waitForElement("header").then(async (header) => {
      if (!header) {
         return;
      }

      const headerButtonQuery = header.classList.contains("header")
         ? "#wishlist-header-button"
         : currentDevice === "desktop"
         ? "#wishlist-header-button-desktop"
         : "#wishlist-header-button-mobile";

      const buttonElem = header.querySelector(headerButtonQuery) as HTMLElement | null;
      if (!buttonElem) {
         return;
      }

      const wishlistCountLabel = buttonElem.querySelector("span:last-child")!;
      const wishlistIcon = buttonElem.querySelector(".icon");

      const handleButtonClick = () => {
         dispatchCustomEvent(buttonElem, WishlistOpenEvent);
      };

      const handleWishlistCountEvent = (ev: Event) => {
         wishlistCountLabel.innerHTML = (ev as CustomEvent<number>).detail.toString();
      };

      buttonElem.addEventListener("click", handleButtonClick);

      // Listen for custom event that's fired when wishlist is updated from MyPages (which is a separate app)
      document.addEventListener("wishlist:count", handleWishlistCountEvent);

      if (await isAuthenticated()) {
         store.dispatch(fetchWishlist());
      }

      store.subscribe(() => {
         const {
            wishlist: { status, model },
         } = store.getState();

         // Don't handle initial state update
         if (status === "idle") {
            return;
         }

         const currentCount = +wishlistCountLabel.innerHTML;
         const newCount = model.items.length;

         if (newCount !== currentCount) {
            wishlistCountLabel.innerHTML = newCount > 0 ? newCount.toString() : "";
            animatePulse(wishlistCountLabel);

            if (currentCount === 0) {
               wishlistIcon?.classList.add("p12icon-heart--filled");
            } else if (currentCount > 0 && newCount === 0) {
               wishlistIcon?.classList.remove("p12icon-heart--filled");
            }
         }
      });
   });
};
