import {
   getWishlistAddGtmEvent,
   getWishlistAddToCartGtmEvent,
   getWishlistAddToWishlistGtmEvent,
   getWishlistRemoveGtmEvent,
   post,
} from "@afound/common";
import { GtmEvent, WishlistApiModel, WishlistItem, WishlistItemRef, WishlistModel } from "@afound/types";

import ai from "../../ai";
import { WishlistAction } from "./types";

export const getWishlistModalGtmEvent = (): Omit<GtmEvent, "eventLabel"> & { modalId: string } => ({
   event: "modals",
   eventCategory: "modals",
   eventAction: "open",
   modalId: "wishlist",
});

export const trackGtm = (
   action: WishlistAction,
   payload: WishlistApiModel<unknown>,
   wishlist: WishlistModel,
   prevWishlist: WishlistModel
) => {
   try {
      switch (action) {
         case "add": {
            const gtmEvent = getWishlistAddGtmEvent(payload.data as WishlistItem, wishlist);
            window.dataLayer.push(gtmEvent);
            break;
         }
         case "addFromCart": {
            const gtmEvent = getWishlistAddToWishlistGtmEvent(payload.data as WishlistItem, wishlist);
            window.dataLayer.push(gtmEvent);
            break;
         }
         case "addToCart": {
            const addedRef = payload.data as WishlistItemRef;
            const added = prevWishlist.items.find((i) => i.variantId === addedRef.variantId);
            const gtmEvent = getWishlistAddToCartGtmEvent(added!, wishlist);
            window.dataLayer.push(gtmEvent);
            break;
         }
         case "remove": {
            const removedRef = payload.data as WishlistItemRef;
            const removed = prevWishlist.items.find((i) => i.variantId === removedRef.variantId);
            const gtmEvent = getWishlistRemoveGtmEvent(removed!, wishlist);
            window.dataLayer.push(gtmEvent);
            break;
         }
      }
   } catch (err) {
      const error = err as Error;
      error.message = `GA tracking for wishlist failed. Error was: ${error.message}`;
      ai.trackException({ exception: error });
   }
};

export const trackEsales = async (
   action: WishlistAction,
   payload: WishlistApiModel<unknown>,
   prevWishlist: WishlistModel
) => {
   const notifyEsales = async (item: WishlistItem, type: "add_to_favorite" | "remove_from_favorite") => {
      if (!item.epiProductKey) {
         return;
      }

      try {
         await post("/api/v1/esales/notify", {
            productKey: item.epiProductKey,
            productName: item.name,
            type,
         });
      } catch (err) {
         const error = err as Error;
         error.message = `Esales tracking for wishlist failed. Error was: ${error.message}`;
         ai.trackException({ exception: error });
      }
   };

   if (action == "add" || action === "addFromCart") {
      notifyEsales(payload.data as WishlistItem, "add_to_favorite");
   } else if (action === "remove") {
      const removedRef = payload.data as WishlistItemRef;
      const removed = prevWishlist.items.find((i) => i.productCode === removedRef.productCode);
      notifyEsales(removed!, "remove_from_favorite");
   }
};
