import "./main.scss";
import "@afound/react/dist/main.css";

import { Loader } from "@afound/react";
import React, { ComponentType, lazy, Suspense, useEffect, useMemo } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "./app";
import { ApplicationError } from "./components/application-error";
import Home from "./home";
import NotFound from "./not-found";
import { fetchSettings, selectSettings } from "./settings/settings-slice";
import { store, useAppDispatch, useAppSelector } from "../../store";
import { MyPagesServerConfig } from "./types";

const Profile = lazy(() => import("./profile"));
const ProfileOverview = lazy(() => import("./profile/overview"));
const EditProfile = lazy(() => import("./profile/edit"));
const EditAddress = lazy(() => import("./profile/edit-address"));

const OrdersAndMessages = lazy(() => import("./orders"));
const Orders = lazy(() => import("./orders/orders"));
const Messages = lazy(() => import("./orders/messages"));

const Wishlist = lazy(() => import("./wishlist"));
const MyReturnFee = lazy(() => import("./my-return-fee"));
const Deals = lazy(() => import("./deals"));

const withSuspense = (Component: ComponentType) => (props?: any) =>
   (
      <Suspense fallback={<Loader visible />}>
         <Component {...props} />
      </Suspense>
   );

const Root = (props: MyPagesServerConfig) => {
   const dispatch = useAppDispatch();

   const { status, baseUrl, areaRoutes, isFlexibleReturnFeeEnabled } = useAppSelector(selectSettings);

   useEffect(() => {
      dispatch(fetchSettings(props));
   }, [dispatch]);

   const profilePath = useMemo(() => areaRoutes.find((r) => r.area === "profile")?.path, [areaRoutes]);
   const ordersPath = useMemo(() => areaRoutes.find((r) => r.area === "orders")?.path, [areaRoutes]);

   const ordersAndMessagesRoute = (
      <Route path={ordersPath} element={withSuspense(OrdersAndMessages)()}>
         <Route index element={withSuspense(Orders)()} />
         <Route path=":orderId" element={withSuspense(Orders)()} />
         <Route path={":orderId/messages"} element={withSuspense(Messages)()} />
      </Route>
   );

   return status === "success" ? (
      <BrowserRouter basename={baseUrl}>
         <Routes>
            <Route path="/" element={<App defaultRedirectPath={ordersPath} />}>
               {props.isClosingModeEnabled ? (
                  ordersAndMessagesRoute
               ) : (
                  <>
                     <Route index element={<Home />} />
                     <Route path={profilePath} element={withSuspense(Profile)()}>
                        <Route index element={withSuspense(ProfileOverview)()} />
                        <Route path="edit" element={withSuspense(EditProfile)()} />
                        <Route path="edit-address" element={withSuspense(EditAddress)()} />
                     </Route>
                     {ordersAndMessagesRoute}
                     <Route path="my-favorites" element={withSuspense(Wishlist)()} />
                     <Route path="deals" element={withSuspense(Deals)()} />
                     {isFlexibleReturnFeeEnabled && (
                        <Route path="my-return-fee" element={withSuspense(MyReturnFee)()} />
                     )}
                  </>
               )}
               <Route path="*" element={<NotFound />} />
            </Route>
         </Routes>
      </BrowserRouter>
   ) : status === "error" ? (
      <ApplicationError />
   ) : (
      <Loader visible />
   );
};

const myPagesRoot = document.getElementById("my-pages-root");

if (myPagesRoot) {
   createRoot(myPagesRoot).render(
      <React.StrictMode>
         <Provider store={store}>
            <Root {...INITIALDATA.myPages} />
         </Provider>
      </React.StrictMode>
   );
}
