import { ErrorPage } from "@afound/react";

export const ApplicationError = () => (
   <ErrorPage
      backUrl="/"
      title="We're overwhelmed by your excitement"
      message="This page is temporarily unavailable. Please wait for a while before trying again, or go back to the site."
      renderLogo={() => null}
   />
);
