// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TFeAZkLYaq5rTHezPgTn{display:flex;flex-direction:column;align-items:center}.BMJPg9YeZs_9Grqlykfi{font-family:'p12-text';text-align:center;font-size:20px;font-weight:300;margin-bottom:12px}.rWXFDoa3fUURntogOaGQ{display:flex;align-items:center}.rWXFDoa3fUURntogOaGQ+.rWXFDoa3fUURntogOaGQ{margin-top:4px}.sy4CK8ki1GpiOSGIgD4O{padding-left:12px}\n", "",{"version":3,"sources":["webpack://./Assets/src/components/member-perks/member-perks.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,sBAGA,sBAAuB,CACvB,iBAAkB,CAClB,cAAe,CACf,eAAgB,CAChB,kBAAmB,CACnB,sBAGA,YAAa,CACb,kBAAmB,CAFpB,4CAKE,cAAe,CACf,sBAID,iBAAkB","sourcesContent":[".wrapper {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\talign-items: center;\r\n}\r\n\r\n.heading {\r\n\tfont-family: 'p12-text';\r\n\ttext-align: center;\r\n\tfont-size: 20px;\r\n\tfont-weight: 300;\r\n\tmargin-bottom: 12px;\r\n}\r\n\r\n.perk {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\r\n\t& + & {\r\n\t\tmargin-top: 4px;\r\n\t}\r\n}\r\n\r\n.label {\r\n\tpadding-left: 12px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "TFeAZkLYaq5rTHezPgTn",
	"heading": "BMJPg9YeZs_9Grqlykfi",
	"perk": "rWXFDoa3fUURntogOaGQ",
	"label": "sy4CK8ki1GpiOSGIgD4O"
};
export default ___CSS_LOADER_EXPORT___;
