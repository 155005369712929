import { Device, post } from "@afound/common";

import { getMarketSelectorGtmEvent, MarketSelectorTrigger } from "../../shared/tracking";
import { currentDeviceDefault } from "../../shared/ui";

const tryGetTrackingMetadata = (ev: Event): [string, MarketSelectorTrigger?] => {
   const currentMarket = window.location.pathname.substring(1).split("/")[0];
   const trigger =
      (ev.target as HTMLSelectElement).closest(".country-select")?.getAttribute("data-context") || undefined;

   return [currentMarket, trigger as MarketSelectorTrigger];
};

const MarketSelector = () => {
   const configureDesktopSelector = () => {
      const marketSelects = document.querySelectorAll(".country-select:not([data-is-mobile])");
      if (marketSelects.length === 0) {
         return;
      }

      marketSelects.forEach((s) => {
         const marketSelectorTrigger = s.querySelector(".btn-market-selector");
         if (marketSelectorTrigger) {
            marketSelectorTrigger.addEventListener("click", (ev) => {
               const target = ev.target as HTMLElement;
               const marketSelectorList = target.parentElement!.querySelector(".country-select__list");
               marketSelectorList?.classList.add("country-select__list--expanded");
            });
         }
      });

      document.body.addEventListener("click", (ev) => {
         marketSelects.forEach((s) => {
            if (s.contains(ev.target as Node)) {
               return;
            }

            const marketSelectorList = s.parentElement!.querySelector(".country-select__list");
            marketSelectorList?.classList.remove("country-select__list--expanded");
         });
      });
   };

   const configureMobileSelector = () => {
      const marketSelects = document.querySelectorAll(".country-select[data-is-mobile]");
      if (marketSelects.length === 0) {
         return;
      }

      marketSelects.forEach((s) => {
         const selectElem = s.querySelector("select");
         if (selectElem) {
            selectElem.addEventListener("change", handleMobileMarketChange);
         }
      });
   };

   const handleMobileMarketChange = async (ev: Event) => {
      const value = (ev.target as HTMLSelectElement).value;
      const resp = await post<any, { Success: boolean; ReturnUrl: string }>("/api/v1/market/changemarket", {
         marketId: value,
         currentUrl: window.location.href,
      });
      if (resp?.Success) {
         const [currentMarket, trigger] = tryGetTrackingMetadata(ev);
         if (currentMarket && trigger) {
            window.dataLayer.push(getMarketSelectorGtmEvent(trigger, currentMarket, value));
         }

         window.location.href = resp.ReturnUrl;
      }
   };

   const handleDOMLoaded = (_: Event, device: Device) => {
      if (device === "mobile") {
         configureMobileSelector();
      } else if (device === "desktop") {
         configureDesktopSelector();
      }
   };

   const init = (device: Device) => {
      document.addEventListener("DOMContentLoaded", (ev) => handleDOMLoaded(ev, device));
   };

   const [device] = currentDeviceDefault((d) => {
      init(d);
   });

   init(device);
};

MarketSelector();
