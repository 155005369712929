import { createRoot } from "react-dom/client";
import { ContactUsContent } from "./contact-us-content";
import { Provider } from "react-redux";
import { store } from "../../store";

const contactUsRoot = document.getElementById("contact-us-with-tracking-root");
if (contactUsRoot) {
   createRoot(contactUsRoot).render(
      <Provider store={store}>
         <Provider store={store}>
            <ContactUsContent {...INITIALDATA.contactus} />
         </Provider>
      </Provider>
   );
}
