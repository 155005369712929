import { waitForElement } from "@afound/common";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import { SwiperOptions } from "swiper/types/swiper-options";

const FancyCarousel = () => {
   waitForElement(".fancy-carousel-block_new-brand-page").then(() => {
      const elements = {
         allSwipers: document.querySelectorAll(
            ".fancy-carousel-block_new-brand-page .swiper--new-brand-page"
         ) as NodeListOf<HTMLElement>,
      };

      const init = () => {
         elements.allSwipers.forEach((swiper) => {
            const swiperId = swiper.id;

            const swiperProperties: SwiperOptions = {
               pagination: {
                  el: `#${swiperId} .swiper-pagination`,
                  clickable: true,
               },
               navigation: {
                  nextEl: `#${swiperId} .swiper-button-next`,
                  prevEl: `#${swiperId} .swiper-button-prev`,
               },
               breakpoints: {
                  "@0.00": {
                     slidesPerView: 2,
                     slidesPerGroup: 2,
                     spaceBetween: 10,
                  },
                  "@0.75": {
                     slidesPerView: 2,
                     slidesPerGroup: 2,
                     spaceBetween: 20,
                  },
                  "@1.00": {
                     slidesPerView: 4,
                     slidesPerGroup: 4,
                     spaceBetween: 20,
                  },
               },
               modules: [Navigation, Pagination],
            };

            new Swiper(`#${swiperId}`, swiperProperties);
         });
      };

      init();
   });
};

FancyCarousel();
