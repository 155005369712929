import { useEffect, useState } from "react";

import { useAppSelector } from "../../../../store";
import { selectProductListingConfig } from "../../product-listing-slice";

export const SortBy = () => {
   const { sortOptions } = useAppSelector(selectProductListingConfig);
   const [value, setValue] = useState<string>("");

   useEffect(() => {
      const selected = sortOptions.find((s) => s.isCurrent);
      setValue(selected?.sortOption || "");
   }, [sortOptions]);

   const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => setValue(e.currentTarget.value);

   return (
      <select
         className="listing-options-sort"
         name="sort"
         data-js-selector="sort"
         value={value}
         onChange={handleOnChange}
      >
         {sortOptions.map((s) => (
            <option key={s.sortOption} id={`s-${s.sortOption}`} value={s.sortOption}>
               {s.text}
            </option>
         ))}
      </select>
   );
};
