import { get } from "@afound/common";
import { useCallback } from "react";

import { RecentlyViewedProducts } from "./types";
import { aiFeatureName } from "../../types";
import ai from "../../../../ai";

export const useRecentlyViewedProducts = () => {
   const fetchRecentlyViewedProducts = async (locale: string) => {
      
      try {
         return await get<RecentlyViewedProducts[]>(
            `/api/v1/mypages/products/recentlyviewed?countryCode=${locale}&page=1&pageSize=10`
         );
      } catch (err) {
         ai.trackException({ exception: err as Error, properties: { feature: aiFeatureName } });
         return undefined;
      }
   };

   return {
      fetchRecentlyViewedProducts: useCallback(fetchRecentlyViewedProducts, [ai]),
   };
};
