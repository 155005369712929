import { Settings } from "./types";

export const defaultSettings: Settings = {
   locale: "",
   baseUrl: "",
   areaRoutes: [],
   country: {
      twoLetterISORegionName: "",
      threeLetterISORegionName: "",
      isoCurrencySymbol: "",
      displayName: "",
   },
   contentUrls: {
      storePage: "",
      faqPage: "",
      contactPage: "",
      marketingPolicyPage: "",
      digitalReturnPage: "",
   },
   validations: {
      line1: "",
      city: "",
      postalCode: "",
      email: "",
   },
   translations: {
      profileSection: "",
      ordersAndMessagesSection: "",
      wishlistSection: "",
      myReturnFeeSection: "",
      dealsSection: "",
      home: {} as any,
      profile: {} as any,
      orders: {} as any,
      productCard: {} as any,
      wishlist: {} as any,
      deals: {} as any,
      errors: {} as any,
      myReturnFee: {} as any,
   },
   externalLoginSettings: {
      isFacebookLoginEnabled: false,
      isGoogleLoginEnabled: false,
      isEnabled: false,
   },
   isMyDealsEnabled: false,
   enableEcomIdV2: false,
   isMemberZoneEnabled: false,
   contactUsWaitingDays: 1,
   myPagesThreadTips: {
      deliveryTips: "",
      returnTips: "",
      missingReturnSlipTips: "",
   },
   isFlexibleReturnFeeEnabled: false,
   isClosingModeEnabled: false,
};
