import { ApiStatus, get } from "@afound/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import ai from "../../../ai";
import { RootState } from "../../../store";
import { UserInfo } from "./types";
import { aiFeatureName } from "../types";

interface ProfileState extends UserInfo {
   status: ApiStatus;
}

const initialState: ProfileState = {
   status: "idle",
   profile: {
      firstName: "",
      lastName: "",
      email: "",
      daytimePhoneNumber: "",
      dateOfBirth: "",
      employeeNumber: "",
      totalSavings: 0,
   },
   shippingAddress: {
      addressId: "",
      firstName: "",
      lastName: "",
      line1: "",
   },
   subscription: {
      subscribeForNewsLetter: false,
   },
};

export const fetchUserInfo = createAsyncThunk("profile/fetchUserInfo", async (locale: string) => {
   try {
      const response = await get<UserInfo>(`/api/v1/mypages/profile?locale=${locale}`);

      return response;
   } catch (err) {
      ai.trackException({ exception: err as Error, properties: { feature: aiFeatureName } });
      throw err;
   }
});

export const profileSlice = createSlice({
   name: "profile",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(fetchUserInfo.pending, (state) => {
         state.status = "loading";
      });
      builder.addCase(fetchUserInfo.fulfilled, (state, action: PayloadAction<UserInfo | undefined>) => {
         return {
            ...state,
            status: "success",
            ...(action.payload || {}),
         };
      });
      builder.addCase(fetchUserInfo.rejected, (state) => {
         state.status = "error";
      });
   },
});

export const selectUserInfo = (s: RootState) => s.profile;

export default profileSlice.reducer;
