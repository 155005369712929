import { post } from "@afound/common";
import { useCallback } from "react";
import ai from "../../ai";
import { ApiResponse, LoginApiResponse, RegisterApiRequest, RegisterApiResponse } from "./types";

export const useLogin = () => {
   const signIn = async (email: string, password: string, rememberMe: boolean, locale: string) => {
      const formData = new FormData();
      formData.append("Email", email);
      formData.append("Password", password);
      formData.append("RememberMe", rememberMe.toString());
      formData.append("LanguageName", locale);

      try {
         const response = await post<FormData, LoginApiResponse>("/api/v1/authentication/login", formData, {
            requestType: "formdata",
         });
         return response;
      } catch (err) {
         ai.trackException({ exception: err as Error, properties: { feature: "login" } });
         return { success: false };
      }
   };

   const register = async (request: RegisterApiRequest) => {
      const formData = new FormData();

      for (let key in request) {
         formData.append(key, request[key as keyof RegisterApiRequest].toString());
      }

      try {
         const response = await post<FormData, RegisterApiResponse>(
            "/api/v1/authentication/registeraccount",
            formData,
            { requestType: "formdata" }
         );
         return response;
      } catch (err) {
         ai.trackException({ exception: err as Error, properties: { feature: "login" } });
         return { success: false };
      }
   };

   const resetPassword = async (email: string) => {
      try {
         const response = await post<{ email: string }, ApiResponse>("/api/v1/authentication/forgotpassword", {
            email,
         });
         return response;
      } catch (err) {
         ai.trackException({ exception: err as Error, properties: { feature: "login" } });
         return { success: false };
      }
   };

   return {
      signIn: useCallback(signIn, []),
      register: useCallback(register, []),
      resetPassword: useCallback(resetPassword, []),
   };
};
