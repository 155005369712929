import { GtmEvent } from "@afound/types";

export type BackInStockSignupAction = "initiated" | "submitted";

export const getBackInStockSignupEvent = (signupAction: BackInStockSignupAction, variantId: string): GtmEvent => ({
   event: "backInStock",
   eventCategory: "NotifyMe",
   eventAction: signupAction,
   eventLabel: variantId,
});
