import { html } from "lit";
import { customElement, state, property } from "lit/decorators.js";

import { DomLitElement } from "../../../../lit-extensions";
import { store } from "../../../../store";
import { selectProductListingConfig } from "../../product-listing-slice";

@customElement("filter-button")
export class FilterButton extends DomLitElement {
   @property()
   itemCount = 0;

   @state()
   filterModalElement: HTMLElement | null = null;

   connectedCallback() {
      super.connectedCallback();

      this.filterModalElement = document.querySelector('[data-js-selector="filterModal"]');
   }

   private handleFilterClick = async (ev: Event) => {
      ev.preventDefault();

      this.filterModalElement?.classList.add("client-modal--is-open");
   };

   render() {
      const {
         translations: { filterLabel },
      } = selectProductListingConfig(store.getState());

      return html`
         <div @click=${this.handleFilterClick} class="product-list-header__button-filter button-select">
            <span class="icon p12icon-filter icon--text-size spaced-right--small"></span>
            <span class="listing-options-filter-label">${filterLabel}</span>
            <filter-count class="listing-options-filter-count" itemCount=${this.itemCount}></filter-count>
         </div>
      `;
   }
}
