// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hqMs8yzaSYqUGDp1mKjF{display:flex;flex-flow:column;margin:0 auto;min-height:480px}.hqMs8yzaSYqUGDp1mKjF button:disabled{opacity:0.5}.hqMs8yzaSYqUGDp1mKjF a{text-decoration:none}.hqMs8yzaSYqUGDp1mKjF p{line-height:1.5}.KYtdwgjwTgEBHJoZwlQQ svg{fill:#fff}.yTBGOpKZQT35gbHHAy1h{flex:1;max-width:none}@media (min-width: 992px){.hqMs8yzaSYqUGDp1mKjF{min-height:640px}}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/my-pages/app.module.scss"],"names":[],"mappings":"AAEA,sBACG,YAAa,CACb,gBAAiB,CACjB,aAAc,CACd,gBAAiB,CAJpB,sCAWM,WAAY,CAXlB,wBAeM,oBAAqB,CAf3B,wBAmBM,eAAgB,CAClB,0BAKE,SAAU,CACZ,sBAID,MAAO,CACP,cAAe,CAzBf,0BANH,sBAOM,gBAAiB,CActB","sourcesContent":["@import \"./main.scss\";\r\n\r\n.app {\r\n   display: flex;\r\n   flex-flow: column;\r\n   margin: 0 auto;\r\n   min-height: 480px;\r\n\r\n   @media (min-width: $desktop) {\r\n      min-height: 640px;\r\n   }\r\n\r\n   button:disabled {\r\n      opacity: 0.5;\r\n   }\r\n\r\n   a {\r\n      text-decoration: none;\r\n   }\r\n\r\n   p {\r\n      line-height: 1.5;\r\n   }\r\n}\r\n\r\n.errorClose {\r\n   svg {\r\n      fill: #fff;\r\n   }\r\n}\r\n\r\n.main {\r\n   flex: 1;\r\n   max-width: none;\r\n}\r\n\r\n:export {\r\n   desktopWidth: $desktop;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktopWidth": "992px",
	"app": "hqMs8yzaSYqUGDp1mKjF",
	"errorClose": "KYtdwgjwTgEBHJoZwlQQ",
	"main": "yTBGOpKZQT35gbHHAy1h"
};
export default ___CSS_LOADER_EXPORT___;
