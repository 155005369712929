// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XcH_wwNDMYfGK9fWNxoA{z-index:201}.tFPHOGfAWeHQErrq1YjQ{z-index:201}\n", "",{"version":3,"sources":["webpack://./Assets/src/components/site-popup/site-popup.module.scss"],"names":[],"mappings":"AAEA,sBACG,WAHe,CAIjB,sBAGE,WAPe","sourcesContent":["$popup-zindex: 201;\r\n\r\n.backdrop {\r\n   z-index: $popup-zindex;\r\n}\r\n\r\n.popup {\r\n   z-index: $popup-zindex;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": "XcH_wwNDMYfGK9fWNxoA",
	"popup": "tFPHOGfAWeHQErrq1YjQ"
};
export default ___CSS_LOADER_EXPORT___;
