import styles from "./measurement-table.module.scss";
import { MeasureTableInfo } from "../types";

interface MeasureTableProps {
   measureTableInfo: MeasureTableInfo;
}

export const MeasurementTable = (props: MeasureTableProps) => {
   const { categoryTitle, measure } = props.measureTableInfo;

   return (
         <div className={styles.wrapper}>
            <span className={styles.title}>{categoryTitle}</span>
            {measure.map((measure, index) => (

               <div key={index}>
                  <span className={styles.subTitle}>{measure.title}: </span>
                  <span className={styles.description}>{measure.description}</span>
               </div>
            ))}
         </div>
   );
};
