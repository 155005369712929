import { get } from "@afound/common";

const initOptimizelyFullstackClickEvents = () => {
   document.querySelectorAll("[data-optimizely-fullstack-event]").forEach((el) => {
      el.addEventListener("click", async (ev) => {
         const eventName = (ev.currentTarget as HTMLElement)?.getAttribute("data-optimizely-fullstack-event");
         if (eventName) {
            try {
               await get(`/api/optimizely-fullstack/trackevent?eventName=${eventName}`);
            } catch {}
         }
      });
   });
};

initOptimizelyFullstackClickEvents();
