import { FooterElements } from "./types";

type MobileElements = Pick<FooterElements, "footerLinks">;

type MobileProps = MobileElements;

const Mobile = (props: MobileProps) => {
   const { footerLinks } = props;

   const handleLinkGroupClick = (ev: MouseEvent) => {
      const target = ev.target as HTMLElement;

      const linkGroupTitle = target.closest(".footer__link-group-title");
      if (!linkGroupTitle) {
         return;
      }

      const expandButton = linkGroupTitle.querySelector(".footer__link-group-expand span") as HTMLSpanElement;
      expandButton.classList.toggle("p12icon-arrow-down");
      expandButton.classList.toggle("p12icon-arrow-up");

      const links = linkGroupTitle
         .closest(".footer__link-group")!
         .querySelector(".footer__link-group-links") as HTMLElement;
      links.classList.toggle("expanded");
   };

   footerLinks?.addEventListener("click", handleLinkGroupClick);

   return () => {
      footerLinks?.removeEventListener("click", handleLinkGroupClick);
   };
};

export default Mobile;
