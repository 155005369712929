import { createRoot } from "react-dom/client";
import { RecruitmentPopup } from "./recruitment-popup";
import { Provider } from "react-redux";
import { store } from "../../store";

const memberRecruitmentPopupRoot = document.getElementById("member-recruitment-popup-root");
if (memberRecruitmentPopupRoot) {
   createRoot(memberRecruitmentPopupRoot).render(
      <Provider store={store}>
         <RecruitmentPopup {...INITIALDATA.memberRecruitmentPopup} />
      </Provider>
   );
}
