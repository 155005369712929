// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K1TDeLGIi47v6kQwnxLv{text-align:center}.ypTaguR7HD1oiv9_FYfp{font-family:p12-text;font-size:26px;font-weight:300;display:flex;justify-content:center;align-items:center;-moz-column-gap:4px;column-gap:4px}.GqssoEPE7ooexmf7gNgq{font-family:p12-desc;margin:16px 0;line-height:1.15}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/newsletter-popup/popup/popup.module.scss"],"names":[],"mappings":"AAAA,sBACC,iBAAkB,CAClB,sBAGA,oBAAqB,CACrB,cAAe,CACf,eAAgB,CAChB,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,mBAAY,CAAZ,cAAe,CACf,sBAGA,oBAAqB,CACrB,aAAc,CACd,gBAAiB","sourcesContent":[".content {\r\n\ttext-align: center;\r\n}\r\n\r\n.heading {\r\n\tfont-family: p12-text;\r\n\tfont-size: 26px;\r\n\tfont-weight: 300;\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n\tcolumn-gap: 4px;\r\n}\r\n\r\n.intro {\r\n\tfont-family: p12-desc;\r\n\tmargin: 16px 0;\r\n\tline-height: 1.15;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "K1TDeLGIi47v6kQwnxLv",
	"heading": "ypTaguR7HD1oiv9_FYfp",
	"intro": "GqssoEPE7ooexmf7gNgq"
};
export default ___CSS_LOADER_EXPORT___;
