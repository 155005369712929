import { DepartmentPreference } from "./profile/types";

export const getNewsletterSubscriptionProps = (email: string, departmentPreference: DepartmentPreference) => ({
   event: "newsletterSignupEvent",
   eventAction: "newslettersignup",
   eventCategory: "Email",
   eventLabel: "mypages",
   email,
   departmentPreference,
});
