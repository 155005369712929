/**
 * To avoid an excessive amount of refactoring when migrating My Pages to webfront
 * we keep the My Pages icon keys, but keep the iconMap and use it to map to webfront
 * icons.
 */
export const iconMap: { [key: string]: string } = {
   // Keep icons in alphabetical order please :)
   ArrowLeft: "arrow-left", //safe to remove?
   ArrowLeftBig: "arrow-left--big",
   Box: "order",
   Cart: "cart",
   CartFilled: "cart--filled",
   Chat: "chat",
   ChatBig: "chat--big",
   Check: "tick",
   ChevronRight: "arrow-right",
   Clip: "clip",
   Close: "cross",
   Deal: "deal", //does not exist
   Deals: "deals--black",
   Edit: "edit",
   Envelope: "envelope",
   EnvelopeBig: "envelope--big",
   FAQ: "faq",
   Heart: "heart",
   HeartFilled: "heart--filled",
   InfoGray: "info-gray",
   Menu: "menu",
   Minus: "minus",
   Picture: "picture",
   Plus: "plus",
   Return: "return",
   Search: "search",
   Send: "send",
   User: "profile",
   UserFilled: "profile--filled",
   // Flag icons are handled separately in the icon component
   FlagAT: "FlagAT",
   FlagDE: "FlagDE",
   FlagDK: "FlagDK",
   FlagFI: "FlagFI",
   FlagNL: "FlagNL",
   FlagNO: "FlagNO",
   FlagSE: "FlagSE",
};
