import { DsaPopupServerConfig } from "../types";
import { useEffect, useMemo, useState } from "react";

import styles from "./dsa-popup-wrapper.module.scss";
import { SitePopup } from "../../../../components";

type DsaPopupWrapperProps = DsaPopupServerConfig;

export const DsaPopupWrapper = (props: DsaPopupWrapperProps) => {
   const { popupContent = "" } = props;

   const [show, setShow] = useState(false);

   const handleDsaPopupTriggerClick = (ev: MouseEvent) => {
      const target = ev.target as HTMLElement;

      if (
         target.classList.contains("dsa-popup-trigger") ||
         target.parentElement?.classList.contains("dsa-popup-trigger")
      ) {
         setShow(true);
      }
   };

   useEffect(() => {
      document.addEventListener("click", handleDsaPopupTriggerClick);

      return () => document.removeEventListener("click", handleDsaPopupTriggerClick);
   }, []);

   const handleClose = () => setShow(false);

   const popupContentWithIcons = useMemo(() => {
      if (!popupContent) {
         return;
      }

      return popupContent.replace(/{{(.+?)}}/g, (_: string, g1: string) => {
         let iconName = "";

         switch (g1) {
            case "searchInfoIcon":
               iconName = "recent-search";
               break;
            case "basicInfoIcon":
               iconName = "search-category";
               break;
            case "profileInfoIcon":
               iconName = "profile";
               break;
         }

         return `<span class="icon p12icon-${iconName}"></span>`;
      });
   }, [popupContent]);

   return (
      <SitePopup type="dsaPopup" visible={show} onClose={handleClose}>
         {popupContentWithIcons && (
            <div className={styles.content} dangerouslySetInnerHTML={{ __html: popupContentWithIcons }} />
         )}
      </SitePopup>
   );
};
