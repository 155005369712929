import { NotificationBar } from "@afound/react";
import { useEffect, useRef, useState } from "react";
import { ShowSiteNotification, SiteNotificationEventArgs } from "../types";

import styles from "./notification-bar-wrapper.module.scss";

export const NotificationBarWrapper = () => {
   const wrapperRef = useRef<HTMLDivElement>(null);

   const [notificationArgs, setNotificationArgs] = useState<SiteNotificationEventArgs>();
   const [show, setShow] = useState(false);

   useEffect(() => {
      document.addEventListener(ShowSiteNotification, handleSiteNotification);

      return () => document.removeEventListener(ShowSiteNotification, handleSiteNotification);
   }, []);

   const handleSiteNotification = (ev: Event) => {
      const { detail } = ev as CustomEvent<SiteNotificationEventArgs>;
      setNotificationArgs(detail);
      setShow(true);

      window.scrollTo({ top: 0 });
   };

   return (
      <div className={styles.wrapper} ref={wrapperRef}>
         <NotificationBar
            visible={show}
            closeable
            relativeToParent
            theme={notificationArgs?.theme}
            message={notificationArgs?.message || ""}
            onClose={() => setShow(false)}
         />
      </div>
   );
};
