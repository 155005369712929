import { Popup } from "./popup";
import { useEffect, useState } from "react";
import { NewsletterPopupServerConfig } from "./types";
import { usePausedStatus } from "./use-paused-status";
import { useDelay } from "../../hooks/use-delay";

export const PopupWrapper = (props: NewsletterPopupServerConfig) => {
   const { popupDelaySeconds, numberOfDaysBetweenPopups, ...rest } = props;

   const [enablePopup, setEnablePopup] = useState(false);
   const [showPopup, setShowPopup] = useState(enablePopup);

   const delay = useDelay(enablePopup, popupDelaySeconds, "newsletterPopup");
   const [isPaused, setSubscribed, setDismissed] = usePausedStatus(numberOfDaysBetweenPopups);

   useEffect(() => {
      setEnablePopup(!isPaused);
   }, [isPaused]);

   useEffect(() => {
      if (delay === 0) {
         setShowPopup(true);
      }
   }, [delay]);

   const handleSubscribe = () => setSubscribed();

   const handleDismiss = () => {
      setDismissed();
      setShowPopup(false);
   };

   return enablePopup ? (
      <Popup {...rest} visible={showPopup} onSubscribe={handleSubscribe} onDismiss={handleDismiss} />
   ) : null;
};
