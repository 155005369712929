// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Vz6x1jP8slGKh_siR0yY{display:block;max-width:816px;margin:16px 20px}@media (min-width: 992px){.Vz6x1jP8slGKh_siR0yY{margin:28px auto}}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/my-pages/components/container/container.module.scss","webpack://./Assets/src/features/my-pages/main.scss"],"names":[],"mappings":"AAEA,sBACG,aAAc,CACd,eAAgB,CAChB,gBCQgB,CDNhB,0BALH,sBAMM,gBAAiB,CAEtB","sourcesContent":["@import \"../../main.scss\";\r\n\r\n.container {\r\n   display: block;\r\n   max-width: 816px;\r\n   margin: 16px $page-spacing;\r\n\r\n   @media (min-width: $desktop) {\r\n      margin: 28px auto;\r\n   }\r\n}\r\n","$color-brand: #bb2058;\r\n$color-member: #051cf7;\r\n$color-border: #e5e5e5;\r\n\r\n$color-faded-text: #979797;\r\n\r\n$color-bg-pink: #ff00001a;\r\n$color-bg-green: #d2e6db;\r\n$color-bg-light-gray: #f7f7f7;\r\n$color-bg-light-blue: #b8cbf4;\r\n\r\n$desktop: 992px;\r\n\r\n$page-spacing: 20px;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Vz6x1jP8slGKh_siR0yY"
};
export default ___CSS_LOADER_EXPORT___;
