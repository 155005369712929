// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WWdbHzSXyOqOAWV6tS8H{margin:0 auto;padding:0 20px}.WWdbHzSXyOqOAWV6tS8H button{background-color:#051cf7}.jnhSF6ctiE3qf2GrkLGw,.hzqTOn3j_BDCfiWybSdT{font-family:p12-desc;text-align:center}.hzqTOn3j_BDCfiWybSdT{margin:12px 0}@media (min-width: 992px){.WWdbHzSXyOqOAWV6tS8H{max-width:392px}}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/my-pages/home/member-zone/member-zone.module.scss","webpack://./Assets/src/features/my-pages/main.scss"],"names":[],"mappings":"AAEA,sBACG,aAAc,CACd,cAAe,CAFlB,6BASM,wBCVgB,CDWlB,4CAID,oBAAqB,CACrB,iBAAkB,CACpB,sBAGE,aAAc,CAfd,0BAJH,sBAKM,eAAgB,CAMrB","sourcesContent":["@import \"../../main.scss\";\r\n\r\n.wrapper {\r\n   margin: 0 auto;\r\n   padding: 0 20px;\r\n\r\n   @media (min-width: $desktop) {\r\n      max-width: 392px;\r\n   }\r\n\r\n   button {\r\n      background-color: $color-member;\r\n   }\r\n}\r\n\r\n.intro, .departmentSelect {\r\n   font-family: p12-desc;\r\n   text-align: center;\r\n}\r\n\r\n.departmentSelect {\r\n   margin: 12px 0;\r\n}\r\n","$color-brand: #bb2058;\r\n$color-member: #051cf7;\r\n$color-border: #e5e5e5;\r\n\r\n$color-faded-text: #979797;\r\n\r\n$color-bg-pink: #ff00001a;\r\n$color-bg-green: #d2e6db;\r\n$color-bg-light-gray: #f7f7f7;\r\n$color-bg-light-blue: #b8cbf4;\r\n\r\n$desktop: 992px;\r\n\r\n$page-spacing: 20px;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "WWdbHzSXyOqOAWV6tS8H",
	"intro": "jnhSF6ctiE3qf2GrkLGw",
	"departmentSelect": "hzqTOn3j_BDCfiWybSdT"
};
export default ___CSS_LOADER_EXPORT___;
