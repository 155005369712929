import { CartProduct, Product } from "@afound/types";
import { useEffect, useState } from "react";
import { handleApiError } from "../../shared/http";
import { get, sessionStorage } from "@afound/common";

const cartRecommendationsKey = "cartRecommendations";

const mapToProduct = (cartProduct: CartProduct): Product => ({
   ...cartProduct,
   productUrl: cartProduct.url,
   sizes: cartProduct.sizes.map((s) => s.size),
});

export const useRecommendations = (refresh: boolean): [Product[], string | undefined] => {
   const [recommendations, setRecommendations] = useState<Product[]>([]);
   const [error, setError] = useState<string>();

   useEffect(() => {
      if (!refresh) {
         return;
      }

      const fetchRecommendations = async () => {
         try {
            const recommendations = await get<CartProduct[]>("/api/cart/recommendations");

            if (recommendations) {
               sessionStorage.setSerializedItem(
                  cartRecommendationsKey,
                  recommendations.map((r) => mapToProduct(r))
               );
            }

            setError(undefined);
         } catch (err) {
            const { errorMessage } = handleApiError<Product[]>(err, "cart");
            setError(errorMessage);
         }
      };

      const cachedRecommendations = sessionStorage.getDeserializedItem<Product[]>(cartRecommendationsKey);
      if (cachedRecommendations) {
         setRecommendations(cachedRecommendations);
      } else {
         fetchRecommendations();
      }
   }, [refresh]);

   return [recommendations, error];
};
