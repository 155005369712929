import { currentDevice } from "@afound/common";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { store } from "../../store";
import { wishlistHeaderButton } from "./wishlist-header-button";
import { WishlistModal } from "./wishlist-modal";
import { ResponsiveContext } from "@afound/react";

// Header button
const [device] = currentDevice({ desktop: 992 }, (d) => {
   wishlistHeaderButton(d);
});
wishlistHeaderButton(device);

// Wishlist web component
export * from "./wishlist-button";

// Wishlist modal
const wishlistRoot = document.getElementById("wishlist-root");
if (wishlistRoot) {
   createRoot(wishlistRoot).render(
      <Provider store={store}>
         <ResponsiveContext.Provider value={{ desktop: 992 }}>
            <WishlistModal {...INITIALDATA.wishlist} />
         </ResponsiveContext.Provider>
      </Provider>
   );
}
