import { validateField } from "../../../shared/validation/dom-extensions";
import { DsaReportingBaseProps, DsaReportingElements } from "./types";

interface DsaStep1Props
   extends DsaReportingBaseProps,
      Pick<DsaReportingElements, "step1Form" | "reportReasonInput" | "reportReasonInputError"> {}

export const DsaStep1 = (props: DsaStep1Props) => {
   const { step1Form, reportReasonInput, reportReasonInputError, next } = props;

   const handleSubmit = (ev: SubmitEvent) => {
      ev.preventDefault();

      const isValid = validateField(reportReasonInput, reportReasonInputError, {
         required: INITIALDATA.validationMessages.required,
      });

      if (isValid) {
         next!();
      }
   };

   step1Form.addEventListener("submit", handleSubmit);

   return () => step1Form.removeEventListener("submit", handleSubmit);
};
