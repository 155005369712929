import { createRoot } from "react-dom/client";
import { DsaPopupWrapper } from "./dsa-popup-wrapper";
import { Provider } from "react-redux";
import { store } from "../../../store";

const dsaRoot = document.getElementById("dsa-popup-root");

if (dsaRoot) {
   createRoot(dsaRoot).render(
      <Provider store={store}>
         <DsaPopupWrapper {...INITIALDATA.dsaPopup} />
      </Provider>
   );
}
