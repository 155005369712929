import { FC, ReactNode, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { Button, H2, InputField } from "@afound/react";
import styles from "./tracking.module.scss";
import * as yup from "yup";
import { useOrders } from "./use-orders";
import classNames from "classnames";
import { Order, OrderTrackingTexts, ReturnOrderTexts } from "../types";
import { getOrderTrackingGtmEvent } from "../gtm-tracking";

interface TrackingProps {
   orderTrackingTexts: OrderTrackingTexts;
   returnOrderTexts: ReturnOrderTexts;
   digitalReturnUrl?: string;
   faqPage: string;
   onOrdersFetched: (orders: Order[]) => void;
}

interface TrackingOrderForm {
   orderNumber: string;
}

interface PanelProps {
   heading: string;
   children: ReactNode;
}

const Panel: FC<PanelProps> = ({ heading, children }) => {
   return (
      <div className={styles.box}>
         <H2>{heading}</H2>
         <div className={styles.content}>{children}</div>
      </div>
   );
};

export const Tracking: FC<TrackingProps> = ({
   orderTrackingTexts,
   returnOrderTexts,
   digitalReturnUrl,
   onOrdersFetched,
   faqPage,
}) => {
   const [loading, setLoading] = useState<boolean>(false);
   const { fetchOrdersByOrdernumber } = useOrders();
   const wrapperClassName = digitalReturnUrl ? styles.spaceBetweenWrapper : styles.centeredWrapper;

   const handleSubmit = async ({ orderNumber }: TrackingOrderForm, formikProps: FormikHelpers<TrackingOrderForm>) => {
      setLoading(true);
      const orders = await fetchOrdersByOrdernumber(orderNumber);
      setLoading(false);
      if (!orders || orders.length === 0) {
         formikProps.setFieldError("orderNumber", orderTrackingTexts.noOrdersFound);
         window.dataLayer.push(getOrderTrackingGtmEvent("trackMyOrder", "Failed"));
         return;
      }
      window.dataLayer.push(getOrderTrackingGtmEvent("trackMyOrder", "Success"));
      onOrdersFetched(orders);
   };

   const handleReturnOrderClick = () => {
      window.dataLayer.push(getOrderTrackingGtmEvent("registerDigitalReturn"));
      digitalReturnUrl && window.open(digitalReturnUrl, "_self");
   };

   const handleReturnDescriptionClick = (event: React.MouseEvent<HTMLDivElement>) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains("inline-link") && faqPage) {
         window.open(faqPage, "_blank");
      }
   };

   return (
      <div className={classNames(styles.trackingWrapper, wrapperClassName)}>
         <Panel heading={orderTrackingTexts.heading}>
            <p>{orderTrackingTexts.description}</p>
            <Formik
               initialValues={{ orderNumber: "" }}
               validationSchema={yup.object({
                  orderNumber: yup.string().required(orderTrackingTexts.required),
               })}
               onSubmit={handleSubmit}
            >
               <Form>
                  <InputField
                     name="orderNumber"
                     type="text"
                     label={orderTrackingTexts.orderNumber}
                     placeholder={orderTrackingTexts.orderNumberPlaceholder}
                  />
                  <Button type="submit" busy={loading} theme="small">
                     {orderTrackingTexts.buttonText}
                  </Button>
               </Form>
            </Formik>
         </Panel>
         {digitalReturnUrl && (
            <Panel heading={returnOrderTexts.heading}>
               <p
                  onClick={handleReturnDescriptionClick}
                  dangerouslySetInnerHTML={{ __html: returnOrderTexts.description }}
               ></p>
               <Button type="button" theme="small" onClick={handleReturnOrderClick}>
                  {returnOrderTexts.buttonText}
               </Button>
            </Panel>
         )}
      </div>
   );
};
