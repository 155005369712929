import { waitForElement } from "../../shared/dom";
import { InfobarServerConfig } from "./types"

/*
Information bar.
we toggle between three different messages with a delay for transitions.
We start on message 1 and go thrue all three.
*/
const InformationBar = () => {
   waitForElement(".information-bar").then((ele) => {
      if (!INITIALDATA.informationBar) {
         return
      }

      const { messages } = INITIALDATA.informationBar as InfobarServerConfig;

      const element = ele as HTMLElement;
      const wrapper = element.querySelector(".information-bar__column") as HTMLElement;
      let index = 0;
      if (messages.length > 1) {
         setInterval(() => {
            wrapper.classList.toggle('fade');

            const timer = setTimeout(() => {
               index++;

               const messageIndex = index % messages.length;

               wrapper.innerHTML = messages[messageIndex];
               wrapper.classList.toggle('fade');

               clearTimeout(timer);
            }, 800);

         }, 5000);
      }
   });
};

InformationBar();
