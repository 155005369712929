import { BackInStockServerConfig } from "../types";

import { useRef, useState } from "react";
import { SizeSelect } from "../size-select";
import { BackInStockPopup } from "../back-in-stock-popup";
import { ProductSize } from "../../../../shared/types";

import styles from "./back-in-stock-wrapper.module.scss";
import { ShowSiteNotification, SiteNotificationEventArgs } from "../../../../components/notification-bar";
import { getBackInStockSignupEvent } from "../../../back-in-stock/tracking";
import { dispatchCustomEvent } from "@afound/common";

type BackInStockWrapperProps = BackInStockServerConfig;

export const BackInStockWrapper = (props: BackInStockWrapperProps) => {
   const { productCode, sizes, currentMarket, prefilledEmail, texts, enableHideBackInStock, isLoggedIn } = props;

   const wrapperRef = useRef(null);
   const [notifySize, setNotifySize] = useState<ProductSize | undefined>(undefined);

   const handleNotifyClick = (value: string) => {
      setTimeout(() => {
         setNotifySize(sizes.find((s) => s.id === value));
         window.dataLayer.push(getBackInStockSignupEvent("initiated", productCode));
      }, 50);
   };

   const handleSubscribe = (isSuccess: boolean) => {
      setNotifySize(undefined);

      dispatchCustomEvent<SiteNotificationEventArgs>(wrapperRef.current!, ShowSiteNotification, {
         theme: isSuccess ? "success" : "error",
         message: isSuccess ? texts.backInStockSubscribeSuccess : texts.backInStockSubscribeError,
      });
   };

   return (
      <div className={styles.wrapper} ref={wrapperRef}>
         <BackInStockPopup
            visible={!!notifySize}
            selectedSize={notifySize}
            productCode={productCode}
            currentMarket={currentMarket}
            prefilledEmail={prefilledEmail}
            isLoggedIn={isLoggedIn}
            enableHideBackInStock={enableHideBackInStock}
            texts={{
               ...texts,
               invalidEmail: INITIALDATA.validationMessages.email,
            }}
            onSubscribe={handleSubscribe}
            onClose={() => setNotifySize(undefined)}
         />
         <SizeSelect {...props} onNotifyClick={handleNotifyClick} />
      </div>
   );
};
