// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DZYG39hBNg2e9o2i7X6b{max-width:312px;margin:0 auto;text-align:center}.s1AVKCLJdtrkHBLdwFlm{font-size:26px;font-weight:300;margin:24px 0 12px 0}.I4ltV2vPQzwoav4K_tRM{margin-bottom:24px}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/member/recruitment-popup/recruitment-popup.module.scss"],"names":[],"mappings":"AAAA,sBACG,eAAgB,CAChB,aAAc,CACd,iBAAkB,CACpB,sBAGE,cAAe,CACf,eAAgB,CAChB,oBAAqB,CACvB,sBAGE,kBAAmB","sourcesContent":[".content {\r\n   max-width: 312px;\r\n   margin: 0 auto;\r\n   text-align: center;\r\n}\r\n\r\n.heading {\r\n   font-size: 26px;\r\n   font-weight: 300;\r\n   margin: 24px 0 12px 0;\r\n}\r\n\r\n.description {\r\n   margin-bottom: 24px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "DZYG39hBNg2e9o2i7X6b",
	"heading": "s1AVKCLJdtrkHBLdwFlm",
	"description": "I4ltV2vPQzwoav4K_tRM"
};
export default ___CSS_LOADER_EXPORT___;
