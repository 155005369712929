// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FsTK6F3AEiqAUP6oo6J_{font-family:p12-desc;margin:24px 0}.aWqJEg1mzp5YzKSyLqAE,.MqTGhLrXEzKshLL6FTUn{margin-top:24px}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/login/reset-password/reset-password.module.scss"],"names":[],"mappings":"AAAA,sBACC,oBAAqB,CACrB,aAAc,CACd,4CAGA,eAAgB","sourcesContent":[".resetDescription {\r\n\tfont-family: p12-desc;\r\n\tmargin: 24px 0;\r\n}\r\n\r\n.buttonRow, .notification {\r\n\tmargin-top: 24px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetDescription": "FsTK6F3AEiqAUP6oo6J_",
	"buttonRow": "aWqJEg1mzp5YzKSyLqAE",
	"notification": "MqTGhLrXEzKshLL6FTUn"
};
export default ___CSS_LOADER_EXPORT___;
