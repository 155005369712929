import { validateDepartment } from "@afound/common";

import { waitForElement } from "../../shared/dom";
import { getNewsletterSignupErrorEvent, getNewsletterSignupEvent } from "../../shared/tracking";
import { DepartmentPreference } from "../../shared/types";
import { validateCheckbox, validateEmailToLower } from "../../shared/validation/dom-extensions";

const NewsletterSignup = () => {
   waitForElement(".newsletter-signup").then((signupWrapper: HTMLElement | null) => {
      if (!signupWrapper) {
         return;
      }
      const elements = {
         signupForm: signupWrapper.querySelector("form") as HTMLFormElement,
         departmentPreferenceRadios: signupWrapper.querySelectorAll("form [name='DepartmentPreference']"),
         departmentPreferenceError: signupWrapper.querySelector(".newsletter-signup__department .error") as HTMLElement,
         emailField: signupWrapper.querySelector("form [name='Email']") as HTMLInputElement,
         emailError: signupWrapper.querySelector(".newsletter-signup__email .error") as HTMLElement,
         consentCheckbox: signupWrapper.querySelector(
            ".newsletter-signup__terms input[type=checkbox]"
         ) as HTMLInputElement,
         consentError: signupWrapper.querySelector(".newsletter-signup__terms .error") as HTMLElement,
         submitButton: signupWrapper.querySelector(".newsletter-signup__submit") as HTMLElement,
         successMessageWrapper: signupWrapper.querySelector(".server-message") as HTMLElement,
         successMessage: signupWrapper.querySelector(".server-message .server-message--text") as HTMLElement,
         errorMessageWrapper: signupWrapper.querySelector(".server-error") as HTMLElement,
         errorMessage: signupWrapper.querySelector(".server-error .server-error--text") as HTMLElement,
         newsletterMainTopContent: document.getElementById("newletter-page-main-top-content") as HTMLElement,
         newsletterMainBottomContent: document.getElementById("newletter-page-main-bottom-content") as HTMLElement,
      };

      const signupContext = signupWrapper.getAttribute("data-context") || "";

      const validateForm = () => {
         const emailValid = validateEmailToLower(elements.emailField, elements.emailError, {
            required: INITIALDATA.validationMessages.required,
            custom: INITIALDATA.validationMessages.email,
         });

         const departmentValid = validateDepartment(
            elements.departmentPreferenceRadios as NodeListOf<HTMLInputElement>,
            elements.departmentPreferenceError,
            true
         );

         const consentValid = validateCheckbox(elements.consentCheckbox, elements.consentError, {
            required: INITIALDATA.validationMessages.consent,
         });

         return emailValid && departmentValid && consentValid;
      };

      const handleFormSubmit = async (ev: Event) => {
         ev.preventDefault();
         if (!validateForm()) {
            return;
         }

         elements.errorMessageWrapper.className = "server-error";
         elements.submitButton.classList.add("btn--loading");

         const formData = new FormData(elements.signupForm);
         formData.append("subscribedFromFunction", signupContext);

         const directMarketingPolicy = formData.get("DirectMarketingPolicy");
         if (directMarketingPolicy === "on") {
            formData.append("SubscribeToNewsletter", "true");
         }

         const httpResponse = await fetch("/api/v1/newsletter/subscribe", { method: "POST", body: formData });

         const contentType = httpResponse.headers.get("content-type");
         const email = formData.get("Email") as string;
         const departmentPreference = formData.get("DepartmentPreference") as DepartmentPreference;

         if (contentType && contentType.indexOf("application/json") !== -1) {
            const jsonResponse = (await httpResponse.json()) as { Success: boolean; SuccessMessage: string };

            elements.submitButton.classList.remove("btn--loading");
            if (jsonResponse.Success) {
               window.dataLayer.push(getNewsletterSignupEvent(signupContext, departmentPreference, email));

               elements.successMessage.textContent = jsonResponse.SuccessMessage;
               elements.successMessageWrapper.className =
                  "server-message server-message--visible success-message spaced--small";
               const isNewsletterPage = signupContext === 'newsletterpage';
               if(isNewsletterPage){
                  if(elements.newsletterMainTopContent){
                     elements.newsletterMainTopContent.remove();
                  }
                  if(elements.newsletterMainBottomContent){
                     elements.newsletterMainBottomContent.classList.add("initial--show");
                  }
               }
            }
         } else {
            const textResponse = await httpResponse.text();

            elements.submitButton.classList.remove("btn--loading");

            elements.errorMessage.textContent = textResponse;
            elements.errorMessageWrapper.className = "server-error server-error--visible error-message spaced--medium";
            elements.successMessageWrapper.className = "server-message";

            window.dataLayer.push(getNewsletterSignupErrorEvent(signupContext, departmentPreference, email));
         }
      };

      elements.signupForm.addEventListener("submit", handleFormSubmit);
   });
};

NewsletterSignup();
