import { post, validatePassword } from "@afound/common";
import { waitForElement } from "../../shared/dom";
import { validateField } from "../../shared/validation/dom-extensions";
import ai from "../../ai";

export const PasswordReset = () => {
   waitForElement("#resetPasswordForm").then(async (form) => {
      if (!form) {
         return;
      }

      const elements = {
         resetPasswordInput: form.querySelector("#resetPassword") as HTMLInputElement,
         resetPasswordError: form.querySelector("#resetPassword + span.error") as HTMLElement,
         confirmPasswordInput: form.querySelector("#resetConfirmPassword") as HTMLInputElement,
         confirmPasswordError: form.querySelector("#resetConfirmPassword + span.error") as HTMLElement,
         errorMessageWrapper: form.querySelector("span.server-error") as HTMLElement,
         errorMessage: form.querySelector("span.server-error--text") as HTMLElement,
         successMessageWrapper: form.querySelector("span.server-message") as HTMLElement,
         successMessage: form.querySelector("span.server-message--text") as HTMLElement,
         submitButton: form.querySelector('#resetPasswordForm button[type="submit"]') as HTMLButtonElement,
      };

      elements.resetPasswordInput.addEventListener("input", () => {
         if (validatePasswordField()) {
            elements.resetPasswordError.textContent = "";
            elements.resetPasswordError.className = "error";
            elements.resetPasswordInput.className = "form-control__input";
         }
      });

      elements.confirmPasswordInput.addEventListener("input", () => {
         if (elements.confirmPasswordInput.validity.valid) {
            elements.confirmPasswordError.textContent = "";
            elements.confirmPasswordError.className = "error";
            elements.confirmPasswordInput.className = "form-control__input";
         }
      });

      form.addEventListener("submit", async (event) => {
         event.preventDefault();

         if (
            validatePasswordField() &&
            validatePasswordMissMatch(
               elements.resetPasswordInput,
               elements.confirmPasswordInput,
               elements.confirmPasswordError
            )
         ) {
            elements.submitButton.classList.add("btn--loading");

            const formData = new FormData(event.target as HTMLFormElement);

            try {
               const response = await post<FormData, { success: boolean; message?: string }>(
                  "/Customer/ResetPassword/ResetPasswordPage?handler=Reset",
                  formData,
                  { requestType: "formdata" }
               );
               if (response?.success) {
                  elements.submitButton.style.display = "none";
                  elements.successMessage.textContent = response.message || "";
                  elements.successMessageWrapper.className =
                     "server-message server-message--visible success-message spaced--medium";
                  elements.errorMessageWrapper.className = "server-error";
               } else {
                  elements.errorMessage.textContent = response?.message || "";
                  elements.errorMessageWrapper.className =
                     "server-error server-error--visible error-message spaced--medium";
               }
            } catch (err) {
               ai.trackException({ exception: err as Error, properties: { feature: "resetPasswordPage" } });
            }

            elements.submitButton.classList.remove("btn--loading");
         }
      });

      const validatePasswordField = () =>
         validateField(
            elements.resetPasswordInput,
            elements.resetPasswordError,
            {
               required: INITIALDATA.validationMessages.required,
               custom: INITIALDATA.validationMessages.password,
            },
            validatePassword
         );
   });
};

const validatePasswordMissMatch = (
   element: HTMLInputElement,
   elementMatch: HTMLInputElement,
   errorElement: HTMLElement
) => {
   if (element.value !== elementMatch.value) {
      errorElement.textContent = INITIALDATA.validationMessages.passwordMissMatch;
      errorElement.className = "error form-control__error";
      elementMatch.className = "form-control__input error";
      return false;
   }

   return true;
};

PasswordReset();
