import { SortOptions } from "./sticky-filter-bar/type";

export type ProductListingServerConfig = {
   translations: ProductListingTexts;
   sortOptions: SortOptions[];
} & ProductListingFilterCount;

export type ProductListingFilterCount = {
   filterCount: number;
   productCount: number;
};

export type ProductListingTexts = Partial<{
   clearAllFilterLabel: string;
   filterLabel: string;
   productLabel: string;
   productsLabel: string;
   scrollToTopLabel: string;
}>;

// This is a subset of all product filter types, should correspond to the
// data-js-filter attribute on the filter modal filter elements. For now
// only the brandsize filter can be pre-expanded, but add more as necessary.
type ProductFilterType = "v_brandsize";

export const ShowFilterModal = "filtermodal:show";
export interface FilterModalEventArgs {
   defaultFilter?: ProductFilterType;
}
