import { ReactNode } from "react";
import styles from "./upselling-carousel.module.scss";
import { Carousel } from "@afound/react";

interface UpsellingCarouselProps {
   title: string;
   children: ReactNode[];
}

export const UpsellingCarousel = (props: UpsellingCarouselProps) => {
   const { title, children } = props;

   return (
      <div className={styles.wrapper}>
         <span className={styles.title}>{title}</span>
         <Carousel
            carouselClass={styles.carouselWrapper}
            slideClass={styles.slide}
            breakpoints={{
               0: {
                  spaceBetween: 10,
                  slidesPerView: 2,
                  slidesPerGroup: 2,
               },
               576: {
                  spaceBetween: 10,
                  slidesPerView: 2,
                  slidesPerGroup: 2,
               },
               768: {
                  spaceBetween: 10,
                  slidesPerView: 3,
                  slidesPerGroup: 3,
               },
               1024: {
                  spaceBetween: 10,
                  slidesPerView: 3,
                  slidesPerGroup: 3,
               },
               1280: {
                  spaceBetween: 10,
                  slidesPerView: 4,
                  slidesPerGroup: 4,
               },
            }}
         >
            {children}
         </Carousel>
      </div>
   );
};
