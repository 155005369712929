export interface LoginTexts {
   register: {
      passwordRequireMinLength: string;
      passwordRequireLowerCase: string;
      passwordRequireUpperCase: string;
      passwordRequireDigit: string;
   };
}

export const ShowLoginModal = "loginmodal:show";
export interface LoginModalEventArgs {
   defaultTab?: "login" | "register";
   returnUrl?: string;
}

export interface LoginModalServerConfig {
   locale: string;
   marketName: string;
   externalLoginSource: string;
   enableNewMemberPerks?: boolean;
   texts: LoginModalTexts;
}

export interface LoginModalTexts {
   loginTabLabel: string;
   registerTabLabel: string;
   email: string;
   password: string;
   rememberMe: string;
   forgotPassword: string;
   signIn: string;
   or: string;
   signInWithSocial: string;
   memberPerksHeading: string;
   dealsPerk: string;
   earlyAccessPerk: string;
   discountCodesPerk: string;
   favoritesPerk: string;
   backInStockPerk: string;
   // TODO WZ: Remove once new perks are live
   memberPerk1: string;
   memberPerk2: string;
   memberPerk3: string;
   // TODO end
   createPassword: string;
   repeatPassword: string;
   passwordRequireMinLength: string;
   passwordRequireLowerCase: string;
   passwordRequireUpperCase: string;
   passwordRequireDigit: string;
   newsletterSignupHeading1: string;
   newsletterSignupHeading2: string;
   directMarketingPolicy: string;
   departmentPreference: string;
   departmentPreferenceMen: string;
   departmentPreferenceWomen: string;
   register: string;
   privacyNotice: string;
   registerWithSocial: string;
   resetPasswordText: string;
   resetPassword: string;
   backToLogin: string;
   required: string;
   emailFormat: string;
   passwordMismatch: string;
   departmentPreferenceRequired: string;
   generalError: string;
}

export interface ApiResponse {
   success: boolean;
   message?: string;
}

export interface LoginApiResponse extends ApiResponse {
   returnUrl?: string;
   failureReason?: string;
}

export interface RegisterApiRequest {
   email: string;
   password: string;
   confirmPassword: string;
   subscribeToNewsletter: boolean;
   registerAndLogin: boolean;
   languageName: string;
   subscribedFromFunction: string;
   departmentPreference: string;
}

export interface RegisterApiResponse extends ApiResponse {
   subscribedSuccessfully?: boolean;
}
