// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iP3bLqkPKtpk6ZnS5tAm{max-width:310px;margin:0 auto;text-align:center}.nOWYPrpaqzGfIhtul09d{font-family:p12-text;font-size:20px;font-weight:300;margin-bottom:16px}.Op3dlnuhpPKOWrvZK3a2{font-family:p12-001;font-size:16px;font-style:normal;font-weight:300;line-height:18px;padding-bottom:24px}.h8aRwd0D9W9blSAKs_Xy{margin:16px 0}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/product-page/back-in-stock/back-in-stock-popup/back-in-stock-popup.module.scss"],"names":[],"mappings":"AAAA,sBACC,eAAgB,CAChB,aAAc,CACd,iBAAkB,CAClB,sBAGA,oBAAqB,CACrB,cAAe,CACf,eAAgB,CAChB,kBAAmB,CACnB,sBAGE,mBAAoB,CACpB,cAAe,CACf,iBAAkB,CAClB,eAAgB,CAChB,gBAAiB,CACjB,mBAAoB,CACtB,sBAGE,aAAc","sourcesContent":[".content {\r\n\tmax-width: 310px;\r\n\tmargin: 0 auto;\r\n\ttext-align: center;\r\n}\r\n\r\n.title {\r\n\tfont-family: p12-text;\r\n\tfont-size: 20px;\r\n\tfont-weight: 300;\r\n\tmargin-bottom: 16px;\r\n}\r\n\r\n.description {\r\n   font-family: p12-001;\r\n   font-size: 16px;\r\n   font-style: normal;\r\n   font-weight: 300;\r\n   line-height: 18px;\r\n   padding-bottom: 24px;\r\n}\r\n\r\n.form {\r\n   margin: 16px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "iP3bLqkPKtpk6ZnS5tAm",
	"title": "nOWYPrpaqzGfIhtul09d",
	"description": "Op3dlnuhpPKOWrvZK3a2",
	"form": "h8aRwd0D9W9blSAKs_Xy"
};
export default ___CSS_LOADER_EXPORT___;
