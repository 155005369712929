import { localStorage } from "@afound/common";
import { SetPaused, usePause } from "../../hooks/use-pause";

const hasSubscribedKey = "newsletterPopup_hasSubscribed";

export type SetSubscribed = () => void;
export type PausedStatus = [boolean, SetSubscribed, SetPaused];

export const usePausedStatus = (daysBetweenPopup: number): PausedStatus => {
   const daysBetweenPopupSeconds = daysBetweenPopup * 24 * 3600;
   const [popupPaused, setPaused] = usePause(daysBetweenPopupSeconds, "newsletterPopup");

   const hasSubscribed = localStorage.getItem(hasSubscribedKey) === "1";

   const setSubscribed = () => {
      localStorage.setItem(hasSubscribedKey, "1");
   };

   return [popupPaused || hasSubscribed, setSubscribed, setPaused];
};
