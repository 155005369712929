import { displayWithCurrency } from '../../Common/util';

export const UpdateCartQuantity = () => {
   const cartLabel = document.querySelector("#cart-header-button .header__icon-label");
   const cartIcon = document.querySelector("#cart-header-button .icon");

   if (!cartIcon.classList.contains("p12icon-cart--filled")) {
      cartIcon.classList.add("p12icon-cart--filled");
   }

   cartLabel.innerText = +(cartLabel.innerText || 0) + 1;
};

export const HandleAddToCartError = (message) => {
   const serverErrorContainer = document.querySelector('.new-product-page__container span.server-error');
   const serverErrorMessage = document.querySelector('.new-product-page__container span.server-error--text');

   if (message) {
      serverErrorMessage.textContent = message;
      serverErrorContainer.className = 'server-error server-error--visible error-message new-product-page__server-error';
   }
   if (!message && serverErrorContainer.classList.contains('server-error--visible')) {
      serverErrorContainer.className = 'server-error';
   }
}

export const HandleStickyAddToCart = () => {
   if (window.screen.width <= 991) {
      document.querySelector('.new-product-page__container').classList.add('new-product-page__container--sticky');

      const pdpSticky = document.querySelector('.new-product-page__container');
      const height = pdpSticky.getBoundingClientRect().height;
      document.querySelector('footer').setAttribute('style', `margin-bottom: ${height}px;`);
   }
}


export const HandlePriceChanges = (selectedPrice, currency, previousPriceLabel) => {
   if (selectedPrice.OriginalPrice) {
      document.querySelector('.new-product-page__description-section__price-orp').textContent = displayWithCurrency(selectedPrice.OriginalPrice, currency);
   }

   const previousPriceElm = document.querySelector('.new-product-page__description-section__price-pp');
   if (selectedPrice.PreviousPrice && !previousPriceElm) {
      const template =
         `<div class="new-product-page__description-section__price-wrapper__inline new-product-page__description-section__price-pp-target">
            <span class="new-product-page__description-section__price-astrix">${previousPriceLabel}</span>
            <span class="new-product-page__description-section__price-pp">${displayWithCurrency(selectedPrice.PreviousPrice, currency)}</span>
         </div>`;

      document.querySelector('.new-product-page__description-section__price-cp').insertAdjacentHTML('beforebegin', template);
   } else if (selectedPrice.PreviousPrice) {
      document.querySelector('.new-product-page__description-section__price-pp').textContent = displayWithCurrency(selectedPrice.PreviousPrice, currency);
   } else if (previousPriceElm) {
      document.querySelector('.new-product-page__description-section__price-pp-target').remove();
   }

   if (selectedPrice.CurrentPrice) {
      document.querySelector('.new-product-page__description-section__price-cp').textContent = displayWithCurrency(selectedPrice.CurrentPrice, currency);
   }
   if (selectedPrice.ComparisonPriceInfoFormatted) {
      document.querySelector('.new-product-page__description-section__comparison-price').textContent = selectedPrice.ComparisonPriceInfoFormatted;
   }
   if (selectedPrice.DiscountPercentage) {
      document.querySelector('.new-product-page__description-section__price-discount').textContent = `${selectedPrice.DiscountPercentage}%`;
   }
}

export const HandleReviews = (productRelationGuid, currentCulture) => {
   document.querySelector('.pdp-reviews-wrapper').insertAdjacentHTML("afterbegin", `<product-reviews productRelationGuid="${productRelationGuid}" culture="${currentCulture}"></product-reviews>`);
}

export const HandleUpsellingModal = () => {
   const elementExists = document.getElementById("upselling-root-template");

   if (elementExists) {
      document.querySelector('#upselling-root-template').insertAdjacentHTML("afterbegin", `<div id="upselling-root"></div>`);
   }
}
