import { del, get, post, put } from "@afound/common";
import { Cart } from "@afound/types";
import { useCallback } from "react";
import { AddToCartRequest, ApplyCouponRequest, UpdateQuantityRequest, UpdateSizeRequest } from "./types";
import { handleApiError } from "../../shared/http";
import { getMinicartEvent } from "./tracking";

export const useCart = () => {
   const getCart = async () => {
      try {
         const cart = await get<Cart>("/api/cart");
         return { result: cart };
      } catch (err) {
         return handleApiError<Cart>(err, "cart");
      }
   };

   const addToCart = async () => {
      try {
         const cart = await post<AddToCartRequest, Cart>("/api/cart", {});
         return { result: cart };
      } catch (err) {
         return handleApiError<Cart>(err, "cart");
      }
   };

   const updateQuantity = async (request: UpdateQuantityRequest) => {
      try {
         const cart = await put<UpdateQuantityRequest, Cart>("/api/cart/quantity", request);
         return { result: cart };
      } catch (err) {
         return handleApiError<Cart>(err, "cart");
      }
   };

   const updateSize = async (request: UpdateSizeRequest) => {
      try {
         const cart = await put<AddToCartRequest, Cart>("/api/cart/size", request);
         return { result: cart };
      } catch (err) {
         return handleApiError<Cart>(err, "cart");
      }
   };

   const removeFromCart = async (offerCode: string) => {
      try {
         const cart = await del<never, Cart>(`/api/cart/${offerCode}`);
         return { result: cart };
      } catch (err) {
         return handleApiError<Cart>(err, "cart");
      }
   };

   const applyCoupon = async (request: ApplyCouponRequest) => {
      try {
         const cart = await post<ApplyCouponRequest, Cart>("/api/cart/coupon", request);
         return { result: cart };
      } catch (err) {
         return handleApiError<Cart>(err, "cart");
      }
   };

   const removeCoupon = async (couponCode: string) => {
      try {
         const cart = await del<never, Cart>(`/api/cart/coupon/${couponCode}`);
         return { result: cart };
      } catch (err) {
         return handleApiError<Cart>(err, "cart");
      }
   };

   return {
      getCart: useCallback(getCart, []),
      addToCart: useCallback(addToCart, []),
      updateQuantity: useCallback(updateQuantity, []),
      updateSize: useCallback(updateSize, []),
      removeFromCart: useCallback(removeFromCart, []),
      applyCoupon: useCallback(applyCoupon, []),
      removeCoupon: useCallback(removeCoupon, []),
   };
};
