import { createRoot } from "react-dom/client";
import { PopupWrapper } from "./popup-wrapper";
import { Provider } from "react-redux";
import { store } from "../../store";

const welcomePopupRoot = document.getElementById("welcome-popup-root");
if (welcomePopupRoot) {
   createRoot(welcomePopupRoot).render(
      <Provider store={store}>
         <PopupWrapper {...INITIALDATA.welcomePopup} />
      </Provider>
   );
}
