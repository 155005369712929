import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import notificationReducer from "../features/my-pages/notification/notification-slice";
import orderReducer from "../features/my-pages/orders/order-slice";
import profileReducer from "../features/my-pages/profile/profile-slice";
import sessionReducer from "../features/my-pages/session/session-slice";
import settingsReducer from "../features/my-pages/settings/settings-slice";
import productListingSlice from "../features/product-listing/product-listing-slice";
import wishlistSlice from "./wishlist-slice";
import modalsReducer from "./modals-slice";

import { listenerMiddleware } from "./listener";

export const store = configureStore({
   reducer: {
      productListing: productListingSlice,
      wishlist: wishlistSlice,
      settings: settingsReducer,
      profile: profileReducer,
      order: orderReducer,
      session: sessionReducer,
      notification: notificationReducer,
      modals: modalsReducer,
   },
   middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

export type ReduxStore = typeof store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
