// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27 fill=%27%2375968d%27%3E%3Ctitle%3EArtboard 4 copy 9%3C/title%3E%3Cg id=%27Check%27%3E%3Cpolygon points=%278.5 14.06 4.79 10.35 6.21 8.94 8.5 11.23 13.79 5.94 15.21 7.35 8.5 14.06%27/%3E%3C/g%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fbJkBX22YmCqD5y7yO4W{display:grid;grid-template-columns:repeat(2, 1fr);margin-top:4px}.IGTpALWo7KwUfW3U_APV{margin-right:8px;font-weight:300;color:#666666;display:inline-flex;align-items:center}.IGTpALWo7KwUfW3U_APV.UY3sPrXVeUlMaYbQe8ew{color:#75968d}.Sd9g7q8Pc4SYzuFAp0wp{height:16px;width:16px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center center;background-repeat:no-repeat;background-size:20px 20px}@media (min-width: 992px){.fbJkBX22YmCqD5y7yO4W{display:flex}}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/login/password-strength/password-strength.module.scss"],"names":[],"mappings":"AAEA,sBACC,YAAa,CACb,oCAAqC,CACrC,cAAe,CAOhB,sBACC,gBAAiB,CACjB,eAAgB,CAChB,aAAc,CACd,mBAAoB,CACpB,kBAAmB,CALpB,2CAQE,aApBmB,CAqBnB,sBAID,WAAY,CACZ,UAAW,CACX,wDAAuF,CACvF,iCAAkC,CAClC,2BAA4B,CAC5B,yBAA0B,CAvB1B,0BALD,sBAME,YAAa,CAEd","sourcesContent":["$valid-color: #75968d;\r\n\r\n.wrapper {\r\n\tdisplay: grid;\r\n\tgrid-template-columns: repeat(2, 1fr);\r\n\tmargin-top: 4px;\r\n\r\n\t@media (min-width: 992px) {\r\n\t\tdisplay: flex;\r\n\t}\r\n}\r\n\r\n.req {\r\n\tmargin-right: 8px;\r\n\tfont-weight: 300;\r\n\tcolor: #666666;\r\n\tdisplay: inline-flex;\r\n\talign-items: center;\r\n\r\n\t&.valid {\r\n\t\tcolor: $valid-color;\r\n\t}\r\n}\r\n\r\n.check {\r\n\theight: 16px;\r\n\twidth: 16px;\r\n\tbackground-image: svg-load(\"../../../../images/svg/p12_icon_11.svg\", fill=$valid-color);\r\n\tbackground-position: center center;\r\n\tbackground-repeat: no-repeat;\r\n\tbackground-size: 20px 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "fbJkBX22YmCqD5y7yO4W",
	"req": "IGTpALWo7KwUfW3U_APV",
	"valid": "UY3sPrXVeUlMaYbQe8ew",
	"check": "Sd9g7q8Pc4SYzuFAp0wp"
};
export default ___CSS_LOADER_EXPORT___;
