import { HomeTexts } from "../../settings/localized-texts";
import { DepartmentPreference, Subscription } from "./types";
import styles from "./member-zone.module.scss";
import { MemberPerks, NewsletterSignupForm } from "../../../../components";
import { useAppSelector } from "../../../../store";
import { selectSettings, selectTranslations } from "../../settings/settings-slice";
import { getNewsletterSubscriptionProps } from "../../gtm-events";
import { useState } from "react";

interface MemberZoneProps {
   subscription: Subscription;
   texts: Pick<
      HomeTexts,
      "memberPerksHeading" | "dealsPerk" | "earlyAccessPerk" | "favoritesPerk" | "discountCodesPerk" | "backInStockPerk"
   >;
}

export const MemberZone = (props: MemberZoneProps) => {
   const {
      subscription,
      texts: { memberPerksHeading, ...rest },
   } = props;

   const [subscribed, setSubscribed] = useState(false);

   const {
      contentUrls: { marketingPolicyPage },
   } = useAppSelector(selectSettings);

   const {
      profile: { signUp: signUpCta, ...profileRest },
      errors: errorTexts,
   } = useAppSelector(selectTranslations);

   const handleSubscribed = (success: boolean, email: string, department: DepartmentPreference) => {
      if (success) {
         setSubscribed(true);
         window.dataLayer.push(getNewsletterSubscriptionProps(email, department));
      }
   };

   return (
      <div className={styles.wrapper}>
         {subscription.subscribeForNewsLetter || subscribed ? (
            <MemberPerks heading={memberPerksHeading} {...rest} />
         ) : (
            <NewsletterSignupForm
               directMarketingPolicyPageUrl={marketingPolicyPage}
               texts={{
                  ...profileRest,
                  ...errorTexts,
                  signUpCta,
               }}
               onSubscribed={handleSubscribed}
            />
         )}
      </div>
   );
};
