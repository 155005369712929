import { Device, currentDevice, waitForElement } from "@afound/common";
import Swiper from "swiper";
import { SwiperOptions } from "swiper/types/swiper-options";

const TopCategoryCarousel = () => {
   const config = { desktop: 992 };

   waitForElement(".top-categories-block").then(() => {
      const elements = {
         allSwipers: document.querySelectorAll(
            ".top-categories-block .swiper--top-categories"
         ) as NodeListOf<HTMLElement>,
      };

      let swiperInstances: Swiper[] = [];

      const init = (device: Device) => {
         elements.allSwipers.forEach((swiper) => {
            const swiperId = swiper.id;

            const swiperProperties: SwiperOptions =
               device === "mobile"
                  ? {
                       slidesPerView: 3,
                       slidesPerGroup: 3,
                       spaceBetween: 10,
                    }
                  : {
                       slidesPerView: 5,
                       slidesPerGroup: 5,
                       spaceBetween: 10,
                       noSwiping: true,
                    };
            const swiperInstance = new Swiper(`#${swiperId}`, swiperProperties);
            if (device === "desktop") {
               swiperInstance.el.style.pointerEvents = "none";
               swiperInstance.slides.forEach((slide) => {
                  slide.classList.add("swiper-no-swiping");
                  slide.style.pointerEvents = "visible";
               });
            } else {
               swiperInstance.slides.forEach((slide) => {
                  slide.classList.remove("swiper-no-swiping");
               });
            }
            swiperInstance.update();
            swiperInstances.push(swiperInstance);
         });
      };

      const [device, disposeEvent] = currentDevice(config, (newDevice) => {
         swiperInstances.forEach((swiperInstance) => {
            swiperInstance.destroy(true, true);
         });
         swiperInstances = [];
         init(newDevice);
      });

      init(device);

      window.addEventListener("unload", disposeEvent);
   });
};

TopCategoryCarousel();
