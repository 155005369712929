import ai from "../../../ai";
import { dispatchCustomEvent, waitForElement } from "../../../shared/dom";
import { LoginModalEventArgs, ShowLoginModal } from "../../login/types";
import { getMemberOverlayCtaClickEvent } from "./tracking";

const MemberOverlay = () => {
   waitForElement(".site-wrapper").then((site) => {
      if (!site) {
         return;
      }

      const elements = {
         swipers: site.querySelectorAll(".swiper"),
         listings: site.querySelectorAll(".afpl-products"),
      };

      [...Array.from(elements.swipers), ...Array.from(elements.listings)].forEach((container) => {
         container.addEventListener("click", (ev) => {
            const target = ev.target as HTMLElement;

            const memberCta = target.closest(".member-overlay-cta");
            if (memberCta) {
               const productUrl = (memberCta.previousElementSibling as HTMLAnchorElement).href;

               if (!productUrl) {
                  ai.trackException(
                     {
                        exception: new Error(
                           "Could not find a product URL for member product. Ensure that the CTA button has the <a> element as its previous sibling."
                        ),
                     },
                     { feature: "member" }
                  );

                  return;
               }

               dispatchCustomEvent<LoginModalEventArgs>(target, ShowLoginModal, { returnUrl: productUrl });
               window.dataLayer.push(getMemberOverlayCtaClickEvent());
            }
         });
      });
   });
};

MemberOverlay();
