import { GtmEvent } from "@afound/types";
import { CartSnapshot } from "./types";
import { post } from "@afound/common";

const GTM_EVENT_TYPE = "pdpEvent";
const GTM_EVENT_CATEGORY = "PDP";

export const createGtmAccordionData = (action: string): GtmEvent => ({
   event: GTM_EVENT_TYPE,
   eventCategory: GTM_EVENT_CATEGORY,
   eventAction: action,
   eventLabel: "Open",
});

export const createGtmSizeData = (action: string): GtmEvent => ({
   event: GTM_EVENT_TYPE,
   eventCategory: GTM_EVENT_CATEGORY,
   eventAction: action,
   eventLabel: "Changed size",
});

interface GtmProductProps {
   cartTotal?: number;
   cart?: CartSnapshot;
   selectedSize?: string;
   selectedOfferCode?: string;
   eventId?: string;
   recommendationPanel?: string | null;
   promotionId?: string | null;
   promotionName?: string | null;
   promotionCreative?: string | null;
}

export const getGtmPdpData = (type: "addToCartEvent" | "productDetailEvent", props: GtmProductProps) => {
   const { cartTotal, cart, selectedSize, selectedOfferCode, eventId, recommendationPanel, promotionId, promotionName, promotionCreative } = props;
   const product = INITIALDATA.newProduct;
   const products = {
      name: product.name,
      id: product.productId,
      price: `${product.price}`,
      brand: product.brandName,
      category: product.categoryName,
      variant: product.color,
      dimension1: `${product.discount}`,
      dimension7: product.markersText,
      dimension9: product.sellerName,
      dimension10: selectedSize ? selectedSize : product.selectedSize,
      dimension13: selectedOfferCode ? selectedOfferCode : product.offerCode,
      dimension35: product.promotionType,
      dimension36: product.promotionId,
      dimension42: recommendationPanel,
      promotionId: promotionId,
      promotionName: promotionName,
      promotionCreative: promotionCreative,
      quantity: 1, // always 1 from addToCart
      quickView: false,
   };

   let gtmData = {};

   switch (type) {
      case "addToCartEvent":
         gtmData = {
            event: "addToCartEvent",
            ecommerce: {
               currencyCode: product.currency,
               totalBasketValue: cartTotal || 0,
               add: {
                  products: [products],
               },
            },
            cartSnapshot: cart || {},
            gads: {
               event: "add_to_cart",
               value: cartTotal || 0,
               items: [
                  {
                     id: products.id,
                     google_business_vertical: "retail",
                  },
               ],
            },
            eventId: eventId,
         };
         break;
      case "productDetailEvent":
         gtmData = {
            event: "productDetailEvent",
            ecommerce: {
               currencyCode: product.currency,
               detail: {
                  products: [products],
               },
            },
            gads: {
               event: "view_item",
               value: product.price,
               items: [
                  {
                     id: products.id,
                     google_business_vertical: "retail",
                  },
               ],
            },
            eventId: eventId,
         };
         break;
      default:
         break;
   }
   return gtmData;
};

export interface RemoteTrackingEvent {
   eventName: string;
   eventId: string;
   productId: string;
   currency: string;
   price: number;
   eventSourceUrl: string;
}

export const trackRemotePdpEvent: (event: RemoteTrackingEvent) => Promise<void> = ({
   eventName,
   eventId,
   productId,
   currency,
   price,
   eventSourceUrl,
}) =>
   post("/api/v1/conversionsapi/trackpdpevent", {
      eventName,
      eventId,
      productId,
      currency,
      price,
      eventSourceUrl,
   });

export const getFlexibleReturnFeeLoginOrRegisterGtmEvent = (action: "login" | "register"): GtmEvent => ({
   event: action == "login" ? "flexibleReturnFeeLoginClickEvent" : "flexibleReturnFeeRegisterClickEvent",
});
