// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SSgz3XOz786d6v22J15d{align-items:center;display:flex;padding-left:20px;padding-right:20px}.SSgz3XOz786d6v22J15d:hover{background-color:rgba(0,0,0,0.03)}._Lkgzx4llzBXu2YPNtoo{height:36px;line-height:36px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}._Lkgzx4llzBXu2YPNtoo b{text-transform:capitalize}.R8ooLcMsEguNUAisc3ib{align-items:center;background-color:rgba(0,0,0,0.1);display:flex;height:26px;margin-right:10px;padding-left:14px;padding-right:14px;font-weight:bold}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/search/search-results/search-result/search-item.module.scss"],"names":[],"mappings":"AAAA,sBACG,kBAAmB,CACnB,YAAa,CACb,iBAAkB,CAClB,kBAAmB,CAJtB,4BAOM,iCAAqC,CACvC,sBAID,WAAY,CACZ,gBAAiB,CACjB,eAAgB,CAChB,sBAAuB,CACvB,kBAAmB,CALtB,wBAQM,yBAA0B,CAC5B,sBAID,kBAAmB,CACnB,gCAAoC,CACpC,YAAa,CACb,WAAY,CACZ,iBAAkB,CAClB,iBAAkB,CAClB,kBAAmB,CACnB,gBAAiB","sourcesContent":[".listLink {\r\n   align-items: center;\r\n   display: flex;\r\n   padding-left: 20px;\r\n   padding-right: 20px;\r\n\r\n   &:hover {\r\n      background-color: rgba(0, 0, 0, 0.03);\r\n   }\r\n}\r\n\r\n.phrase {\r\n   height: 36px;\r\n   line-height: 36px;\r\n   overflow: hidden;\r\n   text-overflow: ellipsis;\r\n   white-space: nowrap;\r\n\r\n   b {\r\n      text-transform: capitalize;\r\n   }\r\n}\r\n\r\n.brand {\r\n   align-items: center;\r\n   background-color: rgba(0, 0, 0, 0.1);\r\n   display: flex;\r\n   height: 26px;\r\n   margin-right: 10px;\r\n   padding-left: 14px;\r\n   padding-right: 14px;\r\n   font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listLink": "SSgz3XOz786d6v22J15d",
	"phrase": "_Lkgzx4llzBXu2YPNtoo",
	"brand": "R8ooLcMsEguNUAisc3ib"
};
export default ___CSS_LOADER_EXPORT___;
