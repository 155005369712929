import { html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { DomLitElement, jsonAttributeConverter } from "../../../lit-extensions";
import { LoginTexts } from "../types";
import styles from "./password-strength.module.scss";

interface Requirements {
   length: boolean;
   lowercase: boolean;
   uppercase: boolean;
   digit: boolean;
}

type PasswordStrengthTexts = {
   [P in keyof LoginTexts["register"]]: string;
};

@customElement("password-strength")
export class PasswordStrength extends DomLitElement {
   @property()
   attachTo = "";

   @property({
      converter: jsonAttributeConverter,
   })
   translations!: PasswordStrengthTexts;

   @state()
   targetElem?: HTMLInputElement;

   @state()
   requirements: Requirements = {
      length: false,
      lowercase: false,
      uppercase: false,
      digit: false,
   };

   connectedCallback() {
      super.connectedCallback();

      this.targetElem = document.querySelector(this.attachTo) || undefined;
      if (!this.targetElem) {
         throw new Error(
            `Unable to initialize PasswordStrength component - target element was not found using query ${this.attachTo}.`
         );
      } else if (this.targetElem.nodeName !== "INPUT") {
         throw new Error(
            `Unable to initialize PasswordStrength component - target element must be an input element, but was ${this.targetElem.nodeName}.`
         );
      }

      this.targetElem.addEventListener("keyup", this.handleKeyUp);
   }

   disconnectedCallback() {
      super.disconnectedCallback();

      this.targetElem?.removeEventListener("keyup", this.handleKeyUp);
   }

   private handleKeyUp = (ev: KeyboardEvent) => {
      if (ev.isComposing) {
         return;
      }

      const value = this.targetElem!.value;

      this.requirements = {
         length: value.length >= 8,
         lowercase: /[a-z]+/.test(value),
         uppercase: /[A-Z]+/.test(value),
         digit: /[0-9]+/.test(value),
      };
   };

   private requirement = (label: string, valid: boolean) => {
      const classes = classMap({
         [styles.req]: true,
         [styles.valid]: valid,
      });

      return html`
         <div class=${classes}>
            <span>${label}</span>
            ${valid
               ? html`<span class=${["icon", styles.check].join(" ")}></span>`
               : nothing}
         </div>
      `;
   };

   render() {
      const {
         passwordRequireMinLength,
         passwordRequireLowerCase,
         passwordRequireUpperCase,
         passwordRequireDigit,
      } = this.translations;

      return html`<div class=${styles.wrapper}>
         ${this.requirement(passwordRequireMinLength, this.requirements.length)}
         ${this.requirement(
            passwordRequireLowerCase,
            this.requirements.lowercase
         )}
         ${this.requirement(
            passwordRequireUpperCase,
            this.requirements.uppercase
         )}
         ${this.requirement(passwordRequireDigit, this.requirements.digit)}
      </div>`;
   }
}
