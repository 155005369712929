import { createRoot } from "react-dom/client";
import { PromotionTooltip } from "./promotion-tooltip";
import { ProductPageServerConfig } from "../types";

const target = document.getElementById("pdp-member-promotion-tooltip");

if (target) {
   const pdpConfig = INITIALDATA.newProduct as ProductPageServerConfig;
   createRoot(target).render(<PromotionTooltip text={pdpConfig.translations.memberPromotionTooltip} />);
}
