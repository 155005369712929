import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../../store";

interface NotificationState {
   type: "none" | "standard" | "success" | "error" | "fatal";
   message?: string;
}

export const initialState: NotificationState = { type: "none", message: undefined };

export const notificationSlice = createSlice({
   name: "notification",
   initialState,
   reducers: {
      standard: (state, action: PayloadAction<string>) => {
         state.type = "standard";
         state.message = action.payload;
      },
      success: (state, action: PayloadAction<string>) => {
         state.type = "success";
         state.message = action.payload;
      },
      error: (state, action: PayloadAction<string>) => {
         state.type = "error";
         state.message = action.payload;
      },
      fatal: (state) => {
         state.type = "fatal";
      },
      reset: (state) => ({
         ...state,
         ...initialState,
      }),
   },
});

export const { standard, success, error, fatal, reset } = notificationSlice.actions;

export const selectNotification = (s: RootState) => s.notification;

export default notificationSlice.reducer;
