import { useEffect, useState } from "react";
import { SizeTableResponse } from "./types";
import { get } from "@afound/common";
import ai from "../../ai";

export const useSizeGuide = (
   currentCulture: string,
   brandName: string,
   isKidsDepartment: boolean,
   gender?: string,
   productConcept?: string,
   sizeGuideCategory?: string,
) => {
   const [data, setData] = useState<SizeTableResponse>();
   const market = currentCulture.replace("-", "_").toLowerCase();
   const fetchData = async () => {
      try {
         const baseUrl = "/api/ProductSizeGuide";
         const params = new URLSearchParams();
         params.append("brand", brandName.toLowerCase());

         if (gender) {
            params.append("gender", gender.toLowerCase());
         }

         if (productConcept) {
            params.append("productConcept", productConcept.toLowerCase());
         }

         params.append("isKidsDepartment", `${isKidsDepartment}`);

         if (sizeGuideCategory) {
            params.append("sizeGuideCategory", sizeGuideCategory.toLowerCase());
         }

         params.append("market", market);

         const sizeGuideData = await get<SizeTableResponse>(`${baseUrl}?${params.toString()}`);

         setData(sizeGuideData!);
      } catch (error) {
         ai.trackException({ exception: error as Error, properties: { feature: "size-guide" } });
      }
   };

   useEffect(() => {
      fetchData();
   }, []);

   return data;
};
