import { mapToCommerceProduct } from "@afound/common";
import { Cart, CartProduct, GtmEcommerceProduct, GtmEvent, GtmEcommerceEvent } from "@afound/types";

export type MinicartEventAction = "More from sellers" | "Size changed" | "Coupon" | "Product" | "Exit cart";

export const getMinicartEvent = (action: MinicartEventAction, label: string): GtmEvent => ({
   event: "miniCartEvent",
   eventCategory: "minicart",
   eventAction: action,
   eventLabel: label,
});

type CartGtmEvent<T extends "add" | "remove"> = GtmEcommerceEvent<{
   [P in T]: { products: GtmEcommerceProduct[] };
}>;

export const getAddToCartEvent = (cart: Cart, added: CartProduct): CartGtmEvent<"add"> => ({
   event: "addToCartEvent",
   totalBasketValue: cart.total,
   ecommerce: {
      currencyCode: cart.currency,
      add: {
         products: [
            {
               ...mapToCommerceProduct(added),
               dimension20: "mini-cart",
            },
         ],
      },
   },
});

export const getRemoveFromCartEvent = (cart: Cart, added: CartProduct): CartGtmEvent<"remove"> => ({
   event: "removeFromCartEvent",
   totalBasketValue: cart.total,
   ecommerce: {
      currencyCode: cart.currency,
      remove: {
         products: [
            {
               ...mapToCommerceProduct(added),
               dimension20: "mini-cart",
            },
         ],
      },
   },
});
