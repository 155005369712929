import { post } from "@afound/common";
import ai from "../../ai";
import { BackInStockRequest, BackInStockResponse } from "./types";
import { useCallback } from "react";

export const useBackInStock = () => {
   const addNotificationSubscriber = async (request: BackInStockRequest) => {
      try {
         const response = await post<BackInStockRequest, BackInStockResponse>("/api/product/backinstock", request);
         return response?.isSuccess || false;
      } catch (err) {
         ai.trackException({ exception: err as Error, properties: { feature: "backinstock" } });
         return false;
      }
   };

   return {
      addNotificationSubscriber: useCallback(addNotificationSubscriber, []),
   };
};
