import { FC } from "react";
import styles from "./member-perks.module.scss";

interface MemberPerksProps {
   heading: string;
   dealsPerk: string;
   earlyAccessPerk: string;
   favoritesPerk: string;
   discountCodesPerk: string;
   backInStockPerk: string;
}

const Perk: FC<{ label: string; icon: string }> = ({ label, icon }) => (
   <div className={styles.perk}>
      <span className={`icon p12icon-${icon}`}></span>
      <span className={styles.label}>{label}</span>
   </div>
);

export const MemberPerks = (props: MemberPerksProps) => {
   const { heading, dealsPerk, earlyAccessPerk, favoritesPerk, discountCodesPerk, backInStockPerk } = props;

   return (
      <div className={styles.wrapper}>
         <h4 className={styles.heading}>{heading}</h4>
         <div>
            <Perk label={dealsPerk} icon="member-discount" />
            <Perk label={earlyAccessPerk} icon="member-access" />
            <Perk label={discountCodesPerk} icon="member-gift" />
            <Perk label={favoritesPerk} icon="member-favorite" />
            <Perk label={backInStockPerk} icon="member-mail" />
         </div>
      </div>
   );
};
