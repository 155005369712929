import { configureAppInsights } from "@afound/common";

const appInsightsKey = typeof INITIALDATA !== "undefined" ? INITIALDATA.appInsightsKey : "";
if (!appInsightsKey) {
   console.warn("No Application Insights key was found. Logging will be disabled.");
}

const ai = configureAppInsights(appInsightsKey);

if (appInsightsKey) {
   ai.loadAppInsights();
}

export default ai;
