export * from "./components";
export * from "./features/cart";
export * from "./features/chat";
export * from "./features/commercial-bar";
export * from "./features/contact-us-with-tracking";
export * from "./features/dsa";
export * from "./features/fancy-carousel";
export * from "./features/top-categories-carousel";
export * from "./features/footer";
export * from "./features/header";
export * from "./features/information-bar";
export * from "./features/login";
export * from "./features/market-selector";
export * from "./features/member";
export * from "./features/my-pages";
export * from "./features/newsletter-popup";
export * from "./features/newsletter-signup";
export * from "./optimizely-fullstack";
export * from "./features/order-confirmation";
export * from "./features/password-reset";
export * from "./features/product-listing";
export * from "./features/product-page";
export * from "./features/product-reviews";
export * from "./features/search";
export * from "./features/size-guide";
export * from "./features/wishlist";
export * from "./features/welcome-popup";
export * from "./features/upselling-popup";
export * from "./features/ecomid-widget";
export * from "./features/faq-page";
export * from "./features/customer-segment-modal";
export * from "./features/inspirational-carousel";

/*
Toggle password boxes
 */
document.querySelectorAll(".form-control__password-icon").forEach((el) =>
   el.addEventListener("click", () => {
      el.classList.toggle("p12icon-eye");
      el.classList.toggle("p12icon-eye-close");

      const passwordInput = el.nextElementSibling as HTMLInputElement;

      if (passwordInput.type === "text") {
         passwordInput.type = "password";
      } else {
         passwordInput.type = "text";
      }
   })
);
