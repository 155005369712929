// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HazAIxBEdXQg141GcLZL{margin-top:12px;z-index:201;height:auto}.hoO9YN72uC3D0CLcjAaQ{padding:2px 0}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/cart/cart-modal/cart-modal.module.scss"],"names":[],"mappings":"AAAA,sBACC,eAAgB,CAChB,WAAY,CACZ,WAAY,CACZ,sBAGA,aAAc","sourcesContent":[".modalWrapper {\r\n\tmargin-top: 12px;\r\n\tz-index: 201;\r\n\theight: auto;\r\n}\r\n\r\n.visaLogo {\r\n\tpadding: 2px 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": "HazAIxBEdXQg141GcLZL",
	"visaLogo": "hoO9YN72uC3D0CLcjAaQ"
};
export default ___CSS_LOADER_EXPORT___;
