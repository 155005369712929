import { get } from "@afound/common";
import { useCallback } from "react";
import ai from "../../../ai";
import { Order, aiFeatureName } from "../types";

export const useOrders = () => {
   const fetchOrdersByOrdernumber = async (orderNumber: string) => {
      
      try {
         return await get<Order[]>(
            `/api/v1/orders?logisticalOrdernumber=${orderNumber}`
         );
      } catch (err) {
         ai.trackException({ exception: err as Error, properties: { feature: aiFeatureName } });
         return undefined;
      }
   };

   return {
      fetchOrdersByOrdernumber: useCallback(fetchOrdersByOrdernumber, [ai]),
   };
};
