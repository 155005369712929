// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Kr3EaroWIn36P6leYFzx{margin-bottom:0}.vd8ZCXTKgWYRRKjvOfWE{display:flex;justify-content:space-between;align-items:center}.WQAkawB9FOBOws7qw5gu{color:#666}.MQlDNZOBZOBXParhQl2c{font-family:inherit;font-size:12px;text-transform:none}.MQlDNZOBZOBXParhQl2c .icon{margin-left:4px}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/product-page/back-in-stock/size-select/size-select.module.scss"],"names":[],"mappings":"AAAA,sBACC,eAAgB,CAChB,sBAGA,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CACnB,sBAGA,UAAW,CACX,sBAGA,mBAAoB,CACpB,cAAe,CACf,mBAAoB,CAHrB,4BAME,eAAgB","sourcesContent":[".selectWrapper {\r\n\tmargin-bottom: 0;\r\n}\r\n\r\n.option {\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n\talign-items: center;\r\n}\r\n\r\n.soldOut {\r\n\tcolor: #666;\r\n}\r\n\r\n.notifyButton {\r\n\tfont-family: inherit;\r\n\tfont-size: 12px;\r\n\ttext-transform: none;\r\n\r\n\t:global(.icon) {\r\n\t\tmargin-left: 4px;\r\n\t}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectWrapper": "Kr3EaroWIn36P6leYFzx",
	"option": "vd8ZCXTKgWYRRKjvOfWE",
	"soldOut": "WQAkawB9FOBOws7qw5gu",
	"notifyButton": "MQlDNZOBZOBXParhQl2c"
};
export default ___CSS_LOADER_EXPORT___;
