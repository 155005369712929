// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".n5hT57aZkeTS8YdRPbWU{width:27px;height:27px;display:flex;justify-content:center;align-items:center;background-color:#fff;border:2px solid #000;border-radius:50%}\n", "",{"version":3,"sources":["webpack://./Assets/src/components/back-to-top-button/back-to-top-button.module.scss"],"names":[],"mappings":"AAAA,sBACG,UAAW,CACX,WAAY,CACZ,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,qBAAsB,CACtB,qBAAsB,CACtB,iBAAkB","sourcesContent":[".button {\r\n   width: 27px;\r\n   height: 27px;\r\n   display: flex;\r\n   justify-content: center;\r\n   align-items: center;\r\n   background-color: #fff;\r\n   border: 2px solid #000;\r\n   border-radius: 50%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "n5hT57aZkeTS8YdRPbWU"
};
export default ___CSS_LOADER_EXPORT___;
