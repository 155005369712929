/**
 * @deprecated Use waitForElement from \@afound/common instead.
 */
export const waitForElement = <T extends HTMLElement | null>(selector: string) => {
   return new Promise<T>((resolve) => {
      const match = document.querySelector(selector);
      if (match) {
         return resolve(match as T);
      }

      const observer = new MutationObserver(() => {
         const deferredMatch = document.querySelector(selector);
         if (deferredMatch) {
            resolve(deferredMatch as T);
            observer.disconnect();
         }
      });

      observer.observe(document.body, {
         childList: true,
         subtree: true,
      });
   });
};

/**
 * @deprecated Use dispatchCustomEvent from \@afound/common instead.
 * Dispatches a custom event with optional payload that bubbles up the DOM tree.
 * @param source The event source element.
 * @param eventName A unique name for the event, should follow the format `[component name]:[event name]`.
 * @param data Any data to include in the event.
 */
export const dispatchCustomEvent = <T>(source: HTMLElement, eventName: string, data?: T) => {
   const defaultOptions: CustomEventInit<T> = {
      bubbles: true,
      composed: true,
   };

   source.dispatchEvent(
      new CustomEvent(eventName, {
         ...defaultOptions,
         detail: data,
      })
   );
};
