// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nm7J3gwWLlxTmyX52PZZ{position:relative;bottom:16px;right:16px;height:48px;width:48px;border-radius:50%;display:flex;justify-content:center;align-items:center;background-color:#fff;box-shadow:0px 4px 4px rgba(0,0,0,0.25);z-index:1}@media (min-width: 992px){.nm7J3gwWLlxTmyX52PZZ{bottom:24px;right:24px;height:60px;width:60px}}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/chat/placeholder/placeholder.module.scss","webpack://./Assets/src/features/chat/constants.scss"],"names":[],"mappings":"AAEA,sBACC,iBAAkB,CAClB,WCJwC,CDKxC,UCLwC,CDMxC,WCH6B,CDI7B,UCJ6B,CDK7B,iBAAkB,CAClB,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,qBAAsB,CACtB,uCAA2C,CAC3C,SAAU,CAEV,0BAdD,sBAeE,WChBwC,CDiBxC,UCjBwC,CDkBxC,WCf6B,CDgB7B,UChB6B,CDkB9B","sourcesContent":["@import \"../constants.scss\";\r\n\r\n.trigger {\r\n\tposition: relative;\r\n\tbottom: $placeholder-viewport-margin-mobile;\r\n\tright: $placeholder-viewport-margin-mobile;\r\n\theight: $placeholder-size-mobile;\r\n\twidth: $placeholder-size-mobile;\r\n\tborder-radius: 50%;\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n\tbackground-color: #fff;\r\n\tbox-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\r\n\tz-index: 1;\r\n\r\n\t@media (min-width: 992px) {\r\n\t\tbottom: $placeholder-viewport-margin-desktop;\r\n\t\tright: $placeholder-viewport-margin-desktop;\r\n\t\theight: $placeholder-size-desktop;\r\n\t\twidth: $placeholder-size-desktop;\r\n\t}\r\n}","$placeholder-viewport-margin-mobile: 16px;\r\n$placeholder-viewport-margin-desktop: 24px;\r\n\r\n$placeholder-size-mobile: 48px;\r\n$placeholder-size-desktop: 60px;\r\n\r\n$chat-window-arrow-size: 8px;\r\n\r\n$header-height-mobile: 60px;\r\n$sticky-add-to-cart-height: 146px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trigger": "nm7J3gwWLlxTmyX52PZZ"
};
export default ___CSS_LOADER_EXPORT___;
