import { DsaReportingBaseProps, DsaReportingElements } from "./types";

interface DsaStep2Props
   extends DsaReportingBaseProps,
      Pick<DsaReportingElements, "reportReasonInput" | "reportReasonSummaryContainer" | "step2Submit"> {}

export const DsaStep2 = (props: DsaStep2Props) => {
   const { reportReasonInput, reportReasonSummaryContainer, step2Submit, next } = props;

   const getSummary = () => {
      const template = `<div class="dsa-page__reason-summary">${reportReasonInput.value}</div>`;

      if (reportReasonSummaryContainer.innerHTML.indexOf("{reason}") > -1) {
         return reportReasonSummaryContainer.innerHTML.replace("{reason}", template);
      } else {
         return reportReasonSummaryContainer.innerHTML.replace(
            /<div class="dsa-page__reason-summary">(.+?)<\/div>/,
            template
         );
      }
   };

   reportReasonSummaryContainer.innerHTML = getSummary();

   const handleSubmit = () => {
      next!();
   };

   step2Submit.addEventListener("click", handleSubmit);

   return () => step2Submit.removeEventListener("click", handleSubmit);
};
