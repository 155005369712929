import { Carousel, H2, ProductCard, useBreakpoints, useGtm } from "@afound/react";
import { useEffect, useState } from "react";

import { useAppSelector } from "../../../../store";
import { selectTranslations } from "../../settings/settings-slice";
import styles from "./recent-products.module.scss";
import { RecentlyViewedProducts } from "./types";
import { useRecentlyViewedProducts } from "./use-recently-viewed-products";
import { getProductClickGtmEvent } from "@afound/common";
import classNames from "classnames";

interface RecentProductsProps {
   locale: string;
   currency: string;
}

export const RecentProducts = (props: RecentProductsProps) => {
   const [recentlyViewedProducts, setRecentlyViewedProducts] = useState<RecentlyViewedProducts[]>([]);

   const { locale, currency } = props;

   const { current } = useBreakpoints(true);

   const { fetchRecentlyViewedProducts } = useRecentlyViewedProducts();

   const { dataLayer } = useGtm();

   useEffect(() => {
      const fetch = async () => {
         const recentlyViewedProducts = await fetchRecentlyViewedProducts(locale);

         if (!recentlyViewedProducts) {
            return;
         }

         setRecentlyViewedProducts(recentlyViewedProducts);
      };

      fetch();
   }, [locale, fetchRecentlyViewedProducts]);

   const {
      home: { recentlyViewed },
      productCard: { priceGuaranteeText: priceGuarantee, ...productCardTexts },
   } = useAppSelector(selectTranslations);

   const handleProductClick = (product: RecentlyViewedProducts) => {
      dataLayer(getProductClickGtmEvent(product, currency, "RecentlyViewedBlock"));
   };

   return recentlyViewedProducts.length > 0 ? (
      <div>
         <H2 className={styles.heading}>
            {recentlyViewed}
            <a className={classNames(styles.dsaTrigger, "dsa-popup-trigger")}>
               <span className="icon p12icon-attention"></span>
            </a>
         </H2>
         <div className={styles.wrapper}>
            <Carousel slidesPerView={current === "desktop" ? 3 : 2}>
               {recentlyViewedProducts.map((r) => (
                  <ProductCard
                     key={r.offerCode}
                     className={styles.productCard}
                     product={r}
                     labels={{
                        ...productCardTexts,
                        priceGuarantee,
                     }}
                     locale={locale}
                     currency={currency}
                     onProductClick={() => handleProductClick(r)}
                  />
               ))}
            </Carousel>
         </div>
      </div>
   ) : null;
};
