import { currentDevice, Device } from "@afound/common";

import { waitForElement } from "../../shared/dom";
import Desktop from "./desktop";
import Mobile from "./mobile";
import Shared from "./shared";
import { NavigationEventMeta } from "./types";
import { getHeaderGtmEvent } from "./tracking";

const Header = () => {
   waitForElement("header").then((headerElem) => {
      if (!headerElem) {
         return;
      }

      const elements = {
         header: headerElem,
         mobileMenuTrigger: headerElem.querySelector(".header__panel-mobile .p12icon-menu") as HTMLElement,
         mobileMenuClose: headerElem.querySelector(".header__menu-close") as HTMLElement,
         mobileMenuBack: headerElem.querySelector(".header__menu-back") as HTMLElement,
         mobileMenuCurrent: headerElem.querySelector(".header__menu-current") as HTMLElement,
         headerNav: headerElem.querySelector(".header__nav") as HTMLElement,
         headerActions: headerElem.querySelector(".header__nav .header__actions") as HTMLElement,
         headerMenu: headerElem.querySelector(".header__menu") as HTMLElement,
         headerMenuWrapper: headerElem.querySelector(".header__menu-wrapper") as HTMLElement,
         headerCartLabel: document.querySelector("#cart-header-button .header__icon-label") as HTMLElement,
         headerCartIcon: document.querySelector("#cart-header-button .icon") as HTMLElement,
      };

      const cleanupCallbacks: Record<Device | "shared", Function | undefined> = {
         mobile: undefined,
         desktop: undefined,
         shared: undefined,
      };

      const handleNavigation = (meta: NavigationEventMeta) => {
         const { department, subdepartment, category, promoted } = meta.trackingKeys;

         let subdepartmentWithCategory = promoted || subdepartment; // Promoted items count as subdepartments
         if (subdepartmentWithCategory && category) {
            subdepartmentWithCategory += `:${category}`;
         }

         window.dataLayer.push(getHeaderGtmEvent(department, subdepartmentWithCategory));
      };

      const init = (device: Device) => {
         if (device === "mobile") {
            cleanupCallbacks["mobile"] = Mobile({ elements, onNavigate: handleNavigation });
         } else {
            cleanupCallbacks["desktop"] = Desktop({ elements, onNavigate: handleNavigation });
         }

         cleanupCallbacks["shared"] = Shared({ elements, onNavigate: handleNavigation });
      };

      const [device] = currentDevice({ desktop: 992 }, (newDevice) => {
         const { mobile: mobileCleanup, desktop: desktopCleanup, shared: sharedCleanup } = cleanupCallbacks;

         mobileCleanup && mobileCleanup();
         desktopCleanup && desktopCleanup();
         sharedCleanup && sharedCleanup();

         init(newDevice);
      });

      init(device);
   });
};

Header();
