export type NavigationType = "department" | "subdepartment" | "category" | "promoted";

export type TrackingKeys = {
   [K in NavigationType as Exclude<K, "department">]?: string;
} & {
   [K in NavigationType as Extract<K, "department">]: string;
};

export interface NavigationEventMeta {
   trigger: NavigationType;
   trackingKeys: TrackingKeys;
}

export interface HeaderCommonElements {
   header: HTMLElement;
   headerNav: HTMLElement;
   headerMenuWrapper: HTMLElement;
   headerMenu: HTMLElement;
   headerCartLabel: HTMLElement;
   headerCartIcon: HTMLElement;
}

export interface HeaderBaseProps<TElements = {}> {
   elements: HeaderCommonElements & TElements;
   onNavigate: (meta: NavigationEventMeta) => void;
}

export const HeaderCartCountEvent = "header:cartCount";
export interface HeaderCartCountEventArgs {
   count: number;
}
