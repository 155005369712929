import { Link } from "react-router-dom";

import { Link as LinkModel } from "../../types";
import { Icon, IconType } from "../icon";
import styles from "./nav-list.module.scss";

export interface NavLinkModel extends LinkModel {
   icon: IconType;
}

interface NavListProps {
   links: NavLinkModel[];
   className?: string;
}

export const NavList = (props: NavListProps) => {
   const { links, className } = props;

   return (
      <nav className={className}>
         {links.map((l, idx) => (
            <div key={idx} className={styles.item}>
               <Icon type={l.icon} height={24} className={styles.icon} />
               <Link className={styles.content} to={l.url}>
                  {l.text}
                  <Icon type="ChevronRight" />
               </Link>
            </div>
         ))}
      </nav>
   );
};
