import { Loader, Modal } from "@afound/react";
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useEffect, useRef, useState } from "react";

import { isAuthenticated } from "../../../shared/auth";
import { useAppDispatch, useAppSelector } from "../../../store";
import { WishlistOpenEvent, WishlistServerConfig } from "../types";
import { WishlistContent } from "../wishlist-content";
import { fetchWishlist, selectWishlist, setServerConfig } from "../../../store/wishlist-slice";
import { getWishlistModalGtmEvent } from "../tracking";
import { dispatchCustomEvent } from "../../../shared/dom";
import { ShowLoginModal } from "../../login/types";

type WishlistModalProps = WishlistServerConfig;

export const WishlistModal = (props: WishlistModalProps) => {
   const modalRef = useRef<HTMLDivElement>(null);

   const dispatch = useAppDispatch();

   const { status, currentAction } = useAppSelector(selectWishlist);

   const [showWishlist, setShowWishlist] = useState(false);
   const [loggedIn, setLoggedIn] = useState(false);

   useEffect(() => {
      document.addEventListener(WishlistOpenEvent, handleWishlistOpen);
      dispatch(setServerConfig(props));

      return () => {
         document.removeEventListener(WishlistOpenEvent, handleWishlistOpen);
         clearAllBodyScrollLocks();
      };
   }, []);

   useEffect(() => {
      if (showWishlist) {
         disableBodyScroll(modalRef.current!);
      } else {
         enableBodyScroll(modalRef.current!);
      }
   }, [showWishlist]);

   const handleWishlistOpen = async () => {
      setShowWishlist(true);

      const isLoggedIn = await isAuthenticated();
      if (isLoggedIn) {
         dispatch(fetchWishlist());
      }

      setLoggedIn(isLoggedIn);

      window.dataLayer.push(getWishlistModalGtmEvent());
   };

   const handleLoginOrRegisterClick = (action: "login" | "register") => {
      setShowWishlist(false);
      dispatchCustomEvent(modalRef.current!, ShowLoginModal, { defaultTab: action });
   };

   return (
      <Modal
         ref={modalRef}
         modalRootId="wishlist-root"
         className="wishlist-modal"
         visible={showWishlist}
         onClose={() => setShowWishlist(false)}
      >
         {status === "loading" && currentAction === "fetch" ? (
            <Loader visible />
         ) : (
            <WishlistContent loggedIn={loggedIn} onLoginOrRegisterClick={handleLoginOrRegisterClick} />
         )}
      </Modal>
   );
};
