import { Button, ProductCard, ProductCardProps, Select } from "@afound/react";
import { Product, WishlistItem } from "@afound/types";
import { Formik } from "formik";

import { useAppDispatch } from "../../../store";
import { WishlistTexts } from "../types";
import { addToCart, removeFromWishlist, updateWishlist } from "../../../store/wishlist-slice";
import styles from "./wishlist-product.module.scss";
import { ChangeEvent } from "react";

export type ModifiedAction = "remove" | "sizeChange" | "addToCart";

type WishlistProductTexts = Pick<
   WishlistTexts,
   "addToCart" | "soldOut" | "wasPriceInfo" | "priorPriceInfo" | "discountInfo" | "priceGuarantee"
>;

interface WishlistProductProps extends Pick<ProductCardProps, "priceInfoTooltipPosition"> {
   item: WishlistItem;
   locale: string;
   texts: WishlistProductTexts;
   onAddedToCart?: (item: WishlistItem) => void;
}

const mapToProduct = (item: WishlistItem) => {
   const { displayPrice, wasPrice, priorPrice } = item.price;

   return {
      offerCode: `${item.variantId}_${item.offerId}`,
      productCode: item.productCode,
      imageUrl: item.imageUrl,
      productUrl: item.productUrl,
      brand: item.brand,
      title: item.name,
      wasPrice,
      actualPrice: displayPrice,
      priorPrice,
      sizes: [],
      showPriceGuarantee: false,
      isInWishlist: true,
   } as Product;
};

export const WishlistProduct = (props: WishlistProductProps) => {
   const {
      item,
      locale,
      texts: {
         addToCart: addToCartLabel,
         soldOut: soldOutLabel = "",
         wasPriceInfo = "",
         priorPriceInfo = "",
         priceGuarantee = "",
         discountInfo = "",
      },
      onAddedToCart,
   } = props;

   const dispatch = useAppDispatch();

   const handleWishlistClick = async () => {
      await dispatch(removeFromWishlist(item));
   };

   const handleSizeChange = (ev: ChangeEvent) => {
      const target = ev.target as HTMLSelectElement;

      dispatch(
         updateWishlist({
            current: item,
            next: { variantId: target.value, productCode: item.productCode },
         })
      );
   };

   const handleAddToCartClick = async () => {
      const request = {
         offerCode: `${item.variantId}_${item.offerId}`,
         localSize: item.currentSize,
         productCode: item.productCode,
      };

      const {
         meta: { requestStatus },
      } = await dispatch(addToCart(request));

      if (requestStatus === "fulfilled") {
         onAddedToCart && onAddedToCart(item);
      }
   };

   const sizeOptions = item.outOfStock
      ? [{ text: soldOutLabel, value: "", disabled: true }]
      : item.sizes.map((s) => ({
           text: s.available ? s.size : `${s.size} - ${soldOutLabel}`,
           value: s.variantId,
           disabled: !s.available,
        }));

   const currentSizeValue = sizeOptions.find((s) => s.text === item.currentSize)?.value;
   const selectName = `${item.offerId}_size`;

   return (
      <div className={item.outOfStock ? styles.soldOut : undefined}>
         {item.outOfStock && <span className={styles.soldOutLabel}>{soldOutLabel}</span>}
         <ProductCard
            product={mapToProduct(item)}
            currency={item.price.currencyCode}
            locale={locale}
            labels={{
               wasPriceInfo,
               priorPriceInfo,
               priceGuarantee,
               discountInfo,
            }}
            onWishlistClick={handleWishlistClick}
         />
         <div className={styles.actions}>
            {sizeOptions.length > 0 && (
               <Formik initialValues={{ [selectName]: currentSizeValue || "" }} onSubmit={() => {}}>
                  <Select
                     className={styles.selectWrapper}
                     small
                     name={selectName}
                     options={sizeOptions}
                     onChange={handleSizeChange}
                  />
               </Formik>
            )}
            <Button theme="small" onClick={handleAddToCartClick}>
               {addToCartLabel}
            </Button>
         </div>
      </div>
   );
};
