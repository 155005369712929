import { createRoot } from "react-dom/client";
import { ResponsiveContext } from "@afound/react";
import { LoginModal } from "./login-modal";

export * from "./password-strength";

const loginRoot = document.getElementById("login-modal-root");

if (loginRoot) {
   const modalToggle = document.getElementById("login-header-button");

   if (modalToggle) {
      createRoot(loginRoot).render(
         <ResponsiveContext.Provider value={{ desktop: 992 }}>
            <LoginModal {...INITIALDATA.loginModal} modalToggle={modalToggle} />
         </ResponsiveContext.Provider>
      );
   }
}
