// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EJZdnX_pUsEYOU1SLMjr{z-index:201}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/login/login-modal/login-modal.module.scss"],"names":[],"mappings":"AAAA,sBACC,WACD","sourcesContent":[".modalWrapper {\r\n\tz-index: 201\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": "EJZdnX_pUsEYOU1SLMjr"
};
export default ___CSS_LOADER_EXPORT___;
