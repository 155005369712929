import { FC, useRef } from "react";
import { NewsletterPopupServerConfig } from "../types";
import { ButtonWrapper, Button } from "@afound/react";

import styles from "./popup.module.scss";
import { dispatchCustomEvent } from "../../../shared/dom";
import { LoginModalEventArgs, ShowLoginModal } from "../../login/types";

interface SuccessContentProps extends Pick<NewsletterPopupServerConfig, "registerPageUrl" | "texts"> {
   registerCtaBehavior: "hide" | "redirect" | "modal";
   showContinueShopping: boolean;
   onDismiss: () => void;
}

export const SuccessContent: FC<SuccessContentProps> = ({
   registerPageUrl,
   registerCtaBehavior = "modal",
   showContinueShopping,
   texts: { signupSuccessHeading, signupSuccessMessage, registerCta, continueShoppingCta },
   onDismiss,
}) => {
   const contentRef = useRef<HTMLDivElement>(null);

   const handleRegisterClick = () => {
      if (registerCtaBehavior === "redirect") {
         return;
      }

      dispatchCustomEvent<LoginModalEventArgs>(contentRef.current!, ShowLoginModal, { defaultTab: "register" });
      onDismiss();
   };

   const showRegisterButton = registerCtaBehavior !== "hide";

   const registerButton = (
      <Button
         theme="primary"
         url={registerCtaBehavior === "redirect" ? registerPageUrl : undefined}
         onClick={handleRegisterClick}
      >
         {registerCta}
      </Button>
   );

   const continueShoppingButton = (
      <Button theme={!showRegisterButton ? "primary" : "secondary"} onClick={onDismiss}>
         {continueShoppingCta}
      </Button>
   );

   return (
      <div ref={contentRef}>
         <h1 className={styles.heading}>
            <span className="icon p12icon-boxed-check"></span>
            {signupSuccessHeading}
         </h1>
         <p className={styles.intro}>{signupSuccessMessage}</p>
         {showRegisterButton && showContinueShopping ? (
            <ButtonWrapper>
               {registerButton}
               {continueShoppingButton}
            </ButtonWrapper>
         ) : (
            <>
               {showRegisterButton && registerButton}
               {showContinueShopping && continueShoppingButton}
            </>
         )}
      </div>
   );
};
