import { ProblemDetails, post } from "@afound/common";
import { validateEmailToLower, validateField } from "../../../shared/validation/dom-extensions";
import { DsaReportingBaseProps, DsaReportingElements } from "./types";
import ai from "../../../ai";

interface DsaStep3Props
   extends DsaReportingBaseProps,
      Pick<
         DsaReportingElements,
         | "reportReasonInput"
         | "nameInput"
         | "nameInputError"
         | "emailInput"
         | "emailInputError"
         | "productUrlInput"
         | "offerCodeInput"
         | "step3Form"
         | "step3Submit"
         | "step3AccordionHeading"
         | "step3ServerError"
      > {}

export const DsaStep3 = (props: DsaStep3Props) => {
   const {
      reportReasonInput,
      nameInput,
      nameInputError,
      emailInput,
      emailInputError,
      productUrlInput,
      offerCodeInput,
      step3Form,
      step3AccordionHeading,
      step3Submit,
      step3ServerError,
      next,
   } = props;

   step3ServerError.classList.remove("server-error--visible");

   const toggleAccordion = (element: Element) => {
      element.classList.toggle("expanded");

      element.nextElementSibling?.classList.toggle("expanded");

      element.lastElementChild?.classList.toggle("p12icon-arrow-down");

      element.lastElementChild?.classList.toggle("p12icon-arrow-up");

      const content = element.nextElementSibling as HTMLDivElement;

      content.style.height = "0";
      content.style.marginBottom = "0";

      if (content.classList.contains("expanded")) {
         content.style.height = content.scrollHeight + "px";
         content.style.marginBottom = "10px";
      }
   };

   const handleSubmit = async (ev: SubmitEvent) => {
      ev.preventDefault();

      const nameIsValid = validateField(nameInput, nameInputError, {
         required: INITIALDATA.validationMessages.required,
      });

      const emailIsValid = validateEmailToLower(emailInput, emailInputError, {
         required: INITIALDATA.validationMessages.required,
         custom: INITIALDATA.validationMessages.email,
      });

      if (!nameIsValid || !emailIsValid) {
         return;
      }

      try {
         step3Submit.disabled = true;

         await post("/api/dsa", {
            reason: reportReasonInput.value,
            fullName: nameInput.value,
            email: emailInput.value,
            productUrl: productUrlInput.value,
            offerCode: offerCodeInput.value,
         });

         step3Submit.disabled = false;

         next!();
      } catch (err) {
         step3Submit.disabled = false;

         ai.trackException({ exception: err as Error, properties: { feature: "dsa-reporting" } });

         const problemDetails = err as ProblemDetails;
         step3ServerError.classList.add("server-error--visible");
         step3ServerError.innerText = problemDetails.title || "";
      }
   };

   const handleAccordionClick = () => {
      toggleAccordion(step3AccordionHeading);
   };

   const eventListenerCleanup = () => {
      step3Form.removeEventListener("submit", handleSubmit);
      step3AccordionHeading.removeEventListener("click", handleAccordionClick);
   };

   step3AccordionHeading.addEventListener("click", handleAccordionClick);

   step3Form.addEventListener("submit", handleSubmit);

   return eventListenerCleanup;
};
