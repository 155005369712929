// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dpP0FEe2orHsZGwkUB2V{margin-top:24px}.FQEkfnXQwr7u3OP0PbAx{display:flex;justify-content:space-between}.H2pspHW_fOIqNTrCFSOY{margin-bottom:0}.X1HLTxgqT_CytaP0u3xg,.DksQvh5TjGhjzZCHjk9A{margin-top:24px}.q_SrA7fRx6mtiSQ1y3fW{margin:24px 0 12px 0;text-align:center}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/login/login/login.module.scss"],"names":[],"mappings":"AAAA,sBACC,eAAgB,CAChB,sBAGA,YAAa,CACb,6BAA8B,CAC9B,sBAGA,eAAgB,CAChB,4CAGA,eAAgB,CAChB,sBAGA,oBAAqB,CACrB,iBAAkB","sourcesContent":[".login {\r\n\tmargin-top: 24px;\r\n}\r\n\r\n.rememberMe {\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n}\r\n\r\n.rememberMeToggle {\r\n\tmargin-bottom: 0;\r\n}\r\n\r\n.buttonRow, .error {\r\n\tmargin-top: 24px;\r\n}\r\n\r\n.externalLoginDivider {\r\n\tmargin: 24px 0 12px 0;\r\n\ttext-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": "dpP0FEe2orHsZGwkUB2V",
	"rememberMe": "FQEkfnXQwr7u3OP0PbAx",
	"rememberMeToggle": "H2pspHW_fOIqNTrCFSOY",
	"buttonRow": "X1HLTxgqT_CytaP0u3xg",
	"error": "DksQvh5TjGhjzZCHjk9A",
	"externalLoginDivider": "q_SrA7fRx6mtiSQ1y3fW"
};
export default ___CSS_LOADER_EXPORT___;
