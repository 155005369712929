import { createRoot } from "react-dom/client";
import { SearchWrapper } from "./search-wrapper";
import { ResponsiveContext } from "@afound/react";

const target = document.getElementById("search-root");

if (target) {
   createRoot(target).render(
      <ResponsiveContext.Provider value={{ desktop: 992 }}>
         <SearchWrapper {...INITIALDATA.search} />
      </ResponsiveContext.Provider>
   );
}
