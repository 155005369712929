import { Notification } from "@afound/react";
import { FC, useEffect, useState } from "react";

import { useAppSelector } from "../../../store";
import { WishlistTexts } from "../types";
import { WishlistItems } from "../wishlist-items";
import { selectWishlist, selectWishlistConfig } from "../../../store/wishlist-slice";
import styles from "./wishlist-content.module.scss";

interface WishlistContentProps {
   loggedIn: boolean;
   onLoginOrRegisterClick: (action: "login" | "register") => void;
}

const NotLoggedIn: FC<{ texts: WishlistTexts } & Pick<WishlistContentProps, "onLoginOrRegisterClick">> = ({
   texts,
   onLoginOrRegisterClick,
}) => {
   const { headingNotLoggedIn, notLoggedIn, logIn, register } = texts;

   return (
      <div className={styles.placeholder}>
         <span className="icon p12icon-heart"></span>
         <h4>{headingNotLoggedIn}</h4>
         <div>{notLoggedIn}</div>
         <button className="btn btn--primary btn--wide spaced--medium" onClick={() => onLoginOrRegisterClick("login")}>
            {logIn}
         </button>
         {/* <button
            className="btn btn--primary btn--wide spaced--medium"
            onClick={() => onLoginOrRegisterClick("register")}
         >
            {register}
         </button> */}
      </div>
   );
};

const LoggedIn: FC<{ texts: WishlistTexts }> = ({ texts }) => {
   const { heading = "", itemCountLabel = "", headingNoFavorites, noFavorites, errorNotification = "" } = texts;

   const {
      status,
      model: { items },
   } = useAppSelector(selectWishlist);

   const [showError, setShowError] = useState(false);

   useEffect(() => {
      setShowError(status === "error");
   }, [status]);

   return items.length > 0 ? (
      <>
         <Notification
            className={styles.notification}
            visible={showError}
            theme="error"
            closeable
            message={errorNotification}
            onClose={() => setShowError(false)}
         />
         <WishlistItems heading={heading} itemsLabel={itemCountLabel} items={items} />
      </>
   ) : (
      <div className={styles.placeholder}>
         <span className="icon p12icon-heart"></span>
         <h4>{headingNoFavorites}</h4>
         <div>{noFavorites}</div>
      </div>
   );
};

export const WishlistContent = (props: WishlistContentProps) => {
   const { loggedIn, onLoginOrRegisterClick } = props;

   const { translations } = useAppSelector(selectWishlistConfig);

   return loggedIn ? (
      <LoggedIn texts={translations} />
   ) : (
      <NotLoggedIn texts={translations} onLoginOrRegisterClick={onLoginOrRegisterClick} />
   );
};
