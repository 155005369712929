import { waitForElement } from "../../shared/dom";

const CommericalBar = () => {
   waitForElement(".commercial-bar").then((ele) => {
      const element = ele as HTMLElement;
      const closeButton = element.querySelector(".commercial-bar__close") as HTMLElement;
      const content = element.querySelector(".commercial-bar__column") as HTMLElement;

      content.querySelectorAll("a")?.forEach(el => {
         el.classList.add("commercial-bar__link");
      })

      closeButton.addEventListener('click', () => {
         element.style.display = 'none';
         const amountOfMinutes = 60;
         setCookie("CommercialBar", true, amountOfMinutes);
      });
   });
};

const setCookie = (cName : string, cValue: boolean, expMin: number) => {
   var timeInMinutes = new Date(new Date().getTime() + expMin * 60 * 1000);
   const expires = "expires=" + timeInMinutes.toUTCString();
   document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

CommericalBar();
