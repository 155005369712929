import { useEffect, useMemo, useState } from "react";

import { getNewsletterSignupErrorEvent, getNewsletterSignupEvent } from "../../../shared/tracking";
import { DepartmentPreference } from "../../../shared/types";
import { NewsletterSignupForm, SitePopup } from "../../../components";
import { NewsletterPopupServerConfig, NewsletterPopupTexts } from "../types";
import styles from "./popup.module.scss";
import { SuccessContent } from "./success-content";
import { formatTemplatedString } from "../../../shared/utils";

interface PopupProps extends Omit<NewsletterPopupServerConfig, "popupDelaySeconds" | "numberOfDaysBetweenPopups"> {
   visible: boolean;
   onSubscribe: () => void;
   onDismiss: () => void;
}

export const Popup = (props: PopupProps) => {
   const {
      visible,
      registerPageUrl,
      directMarketingPageUrl,
      isLoggedIn,
      texts: { heading, intro, ...rest },
      onSubscribe,
      onDismiss,
   } = props;

   const [subscribeResult, setSubscribeResult] = useState<boolean | undefined>();

   useEffect(() => {
      if (subscribeResult) {
         onSubscribe();
      }
   }, [subscribeResult]);

   const handleSubscribed = (success: boolean, email: string, department: DepartmentPreference) => {
      if (success) {
         window.dataLayer.push(getNewsletterSignupEvent("customer bar", department, email));
      } else {
         window.dataLayer.push(getNewsletterSignupErrorEvent("customer bar", department, email));
      }

      setSubscribeResult(success);
   };

   const styledHeading = useMemo(() => {
      return formatTemplatedString(
         heading,
         (placeholder: string) => `<span style="color:#ce0058">${placeholder}</span>`
      );
   }, [heading]);

   return (
      <SitePopup type="newsletterPopup" visible={visible} onClose={onDismiss}>
         <div className={styles.content}>
            {subscribeResult ? (
               <SuccessContent
                  registerPageUrl={registerPageUrl}
                  registerCtaBehavior={isLoggedIn ? "hide" : "redirect"}
                  showContinueShopping
                  texts={rest as NewsletterPopupTexts}
                  onDismiss={onDismiss}
               />
            ) : (
               <>
                  <h1 className={styles.heading} dangerouslySetInnerHTML={{ __html: styledHeading }} />
                  <p className={styles.intro}>{intro}</p>
                  <NewsletterSignupForm
                     directMarketingPolicyPageUrl={directMarketingPageUrl}
                     texts={rest}
                     onSubscribed={handleSubscribed}
                  />
               </>
            )}
         </div>
      </SitePopup>
   );
};
