import { useBreakpoints, useOutsideClick } from "@afound/react";
import { useEffect, useRef, useState } from "react";
import styles from "./search-wrapper.module.scss";
import { SearchForm } from "../search-form";
import classNames from "classnames";
import { SearchServerConfig, SearchItemModel, TopSearchItemType, SearchIconType } from "../types";
import { SearchResults } from "../search-results";
import { useSearch } from "../use-search";
import React from "react";
import { Departments } from "../departments";
import { getCustomSearchEvent } from "../tracking";

type SearchWrapperProps = SearchServerConfig;

const searchItemTypeMap: Record<TopSearchItemType, SearchIconType | undefined> = {
   None: undefined,
   TrendingNow: "stars",
   RecentSearch: "recent-search",
   EsalesSearch: "search",
   ManualSearch: "search",
};

const mapToSearchItems = (items: SearchItemModel[]) =>
   items.map((item) => ({
      phrase: item.phrase,
      url: item.url,
      icon: searchItemTypeMap[item.type],
      type: item.type,
   }));

export const SearchWrapper = (props: SearchWrapperProps) => {
   const {
      searchUrl,
      trendingItems,
      recentSearches,
      topSearches,
      translations,
      searchDepartmentsConfiguration,
      currentDepartmentId,
   } = props;

   const { current } = useBreakpoints(true);

   const [searchBoxVisible, setSearchBoxVisible] = useState(false);
   const [query, setQuery] = useState("");
   const [activeDepartment, setActiveDepartment] = useState<string | undefined>(currentDepartmentId);

   const wrapperClasses = classNames({
      [styles.searchWrapper]: true,
      [styles.isOpen]: searchBoxVisible,
   });

   const searchWrapperRef = useRef<HTMLDivElement | null>(null);

   useEffect(() => {
      const selector =
         current === "desktop" ? ".header__menu-search-button-desktop" : ".header__menu-search-button-mobile";
      const searchButton = document.querySelector(selector);

      if (!searchButton) {
         return;
      }

      searchButton.addEventListener("click", handleSearchButtonClick);

      return () => {
         searchButton.removeEventListener("click", handleSearchButtonClick);
      };
   }, [current]);

   const highlights = mapToSearchItems(trendingItems);
   const defaultItems = mapToSearchItems([...recentSearches, ...topSearches]);

   const filterQuery = searchDepartmentsConfiguration.find((s) => s.departmentGuid === activeDepartment)?.filterQuery;

   const apiItems = useSearch(query, searchUrl, filterQuery || "");

   const handleSearchButtonClick = () => {
      setSearchBoxVisible((prev) => !prev);
   };

   const handleSearchResultClick = () => {
      window.dataLayer.push(getCustomSearchEvent(query, activeDepartment || ""));
   };

   const handleClose = () => {
      setSearchBoxVisible(false);
   };

   useOutsideClick(searchWrapperRef, () => {
      setSearchBoxVisible(false);
   });

   const handleDepartmentSelect = (departmentId: string) => {
      setActiveDepartment(departmentId);
   };

   const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTimeout(() => {
         setQuery(e.target.value || "");
      }, 400);
   };

   const handleSubmit = () => {
      window.dataLayer.push(getCustomSearchEvent(query, activeDepartment || ""));

      const filterQueryParam = filterQuery ? `&department=${filterQuery}` : "";
      window.location.href = `${searchUrl}?q=${query}${filterQueryParam}`;
   };

   return (
      <div ref={searchWrapperRef} className={wrapperClasses}>
         <SearchForm
            onClose={handleClose}
            isVisible={searchBoxVisible}
            {...props}
            onChange={handleOnChange}
            onSubmit={handleSubmit}
         />

         {!!query && (
            <Departments
               departments={searchDepartmentsConfiguration}
               activeDepartment={activeDepartment}
               onDepartmentSelect={handleDepartmentSelect}
            />
         )}

         <SearchResults
            highlights={query ? [] : highlights}
            items={query ? apiItems : defaultItems}
            translations={translations}
            departments={searchDepartmentsConfiguration}
            onDepartmentSelect={handleDepartmentSelect}
            filterQuery={filterQuery}
            onSearchResultClick={handleSearchResultClick}
         />
      </div>
   );
};
