// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JJDU1DE2L2EJ3mEV996e{display:flex;flex-direction:column;margin-bottom:1rem;text-align:center}.JJDU1DE2L2EJ3mEV996e h3{margin-bottom:0;font-size:18px;font-family:\"p12-desc\";font-weight:700}.JJDU1DE2L2EJ3mEV996e .c_6sDJOLXiK3o6MvatlE{display:flex;justify-content:space-between;font-size:16px;font-weight:300;font-family:\"p12-desc\"}.JJDU1DE2L2EJ3mEV996e .c_6sDJOLXiK3o6MvatlE .rIr_yDIWevzNL4UkEfbA{margin:auto;padding-right:24px}.rGRKawYHAnPWZuRGVOD6{margin-bottom:2rem}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/contact-us-with-tracking/tracking/order-details/order-details.module.scss"],"names":[],"mappings":"AAAA,sBACG,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,iBAAkB,CAJrB,yBAMM,eAAgB,CAChB,cAAe,CACf,sBAAuB,CACvB,eAAgB,CATtB,4CAYM,YAAa,CACb,6BAA8B,CAC9B,cAAe,CACf,eAAgB,CAChB,sBAAuB,CAhB7B,kEAkBS,WAAY,CACZ,kBAAmB,CACrB,sBAIJ,kBAAmB","sourcesContent":[".orderDetailsHeader {\r\n   display: flex;\r\n   flex-direction: column;\r\n   margin-bottom: 1rem;\r\n   text-align: center;\r\n   h3 {\r\n      margin-bottom: 0;\r\n      font-size: 18px;\r\n      font-family: \"p12-desc\";\r\n      font-weight: 700;\r\n   }\r\n   .countWrapper {\r\n      display: flex;\r\n      justify-content: space-between;\r\n      font-size: 16px;\r\n      font-weight: 300;\r\n      font-family: \"p12-desc\";\r\n      .orderCount {\r\n         margin: auto;\r\n         padding-right: 24px;\r\n      }\r\n   }\r\n}\r\n.notificationWrapper {\r\n   margin-bottom: 2rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orderDetailsHeader": "JJDU1DE2L2EJ3mEV996e",
	"countWrapper": "c_6sDJOLXiK3o6MvatlE",
	"orderCount": "rIr_yDIWevzNL4UkEfbA",
	"notificationWrapper": "rGRKawYHAnPWZuRGVOD6"
};
export default ___CSS_LOADER_EXPORT___;
