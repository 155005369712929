import { FC, useRef, useEffect, useState } from "react";
import styles from "./order-details.module.scss";
import { Order, OrderTrackingTexts } from "../../types";
import { H3, Notification } from "@afound/react";
import { dispatchCustomEvent } from "../../../../shared/dom";
import { ShowLoginModal } from "../../../login/types";
import { OrderCard } from "./order-card";
import { isAuthenticated } from "../../../../shared/auth";

interface OrderDetailsProps {
   orderDetails: Order[];
   orderTrackingTexts: OrderTrackingTexts;
   ordersHistoryPage: string;
   onBackClick: () => void;
}

export const OrderDetails: FC<OrderDetailsProps> = ({
   orderDetails,
   orderTrackingTexts,
   ordersHistoryPage,
   onBackClick,
}) => {
   const {
      notificationMessageWhenLoggedIn,
      notificationMessageWhenNotLoggedIn,
      orderDetailsCount,
      orderDetailsHeading,
   } = orderTrackingTexts;
   const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
   const notificationWrapperRef = useRef<any>(null);
   const notificationMessage = isLoggedIn ? notificationMessageWhenLoggedIn : notificationMessageWhenNotLoggedIn;
   const orderDetailsCountMessage = orderDetailsCount.replace("{count}", orderDetails.length.toString());
   const commercialId = orderDetails[0].commercialId;
   useEffect(() => {
      const checkAuthentication = async () => {
         const result = await isAuthenticated();
         setIsLoggedIn(result);
      };

      checkAuthentication();
   }, []);
   const handleNotificationWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains("inline-link")) {
         const returnUrl = ordersHistoryPage ? `${ordersHistoryPage}/${commercialId}` : null;
         if (isLoggedIn && returnUrl) {
            window.open(returnUrl, "_self");
         } else {
            const defaultTab = "login";
            dispatchCustomEvent(target, ShowLoginModal, { defaultTab, returnUrl });
         }
      }
   };

   return (
      <>
         <div className={styles.orderDetailsHeader}>
            <H3>
               {orderDetailsHeading} {commercialId}
            </H3>
            <div className={styles.countWrapper}>
               <span className="icon p12icon-arrow-left--big icon--pointer" onClick={onBackClick}></span>
               <span className={styles.orderCount}>{orderDetailsCountMessage}</span>
            </div>
         </div>
         {orderDetails.map((order, index) => {
            return (
               <OrderCard
                  key={order.logisticalId}
                  order={order}
                  packageNo={index + 1}
                  orderTrackingTexts={orderTrackingTexts}
               />
            );
         })}
         <div
            className={styles.notificationWrapper}
            ref={notificationWrapperRef}
            onClick={handleNotificationWrapperClick}
         >
            <Notification
               visible={true}
               alignment="top"
               theme="warning"
               message={notificationMessage}
               closeable={false}
            />
         </div>
      </>
   );
};
