import classNames from "classnames";

import { iconMap } from "./icon-map";
import styles from "./icon.module.scss";

export type IconType = keyof typeof iconMap;

export interface IconProps {
   type: IconType;
   height?: number;
   width?: number;
   rotate?: number;
   className?: string;
}

export const Icon = (props: IconProps) => {
   const { type, height = 24, width, rotate, className } = props;
   const isFlagIcon = (type as string).startsWith("Flag");

   const getClasses = (mappedIconKey: string) =>
      classNames({
         [styles.icon]: true,
         [`icon p12icon-${mappedIconKey}`]: !isFlagIcon,
         [`icon icon--flag flag-icon-${mappedIconKey.replace("Flag", "")}`]: isFlagIcon,
         [className!]: !!className,
      });

   const iconStyle: React.CSSProperties = {
      ...(!isFlagIcon
         ? {
              height,
              width: width || height,
           }
         : {}),
      ...(rotate !== undefined ? { transform: `rotate(${rotate}deg)` } : {}),
   };

   return iconMap.hasOwnProperty(type) ? <span className={getClasses(iconMap[type])} style={iconStyle} /> : null;
};
