import { waitForElement, wrappedDebounce } from "@afound/common";

const toggleAccordion = (element: Element, expanded: boolean) => {
   element.classList.toggle("expanded", expanded);

   const accordionHeaderIcon = element.querySelector(".faq-page__item-heading .icon") as HTMLElement;
   accordionHeaderIcon.classList.toggle("p12icon-arrow-down", !expanded);
   accordionHeaderIcon.classList.toggle("p12icon-arrow-up", expanded);
};

const removeHighlight = (html: string) => {
   return html.replace(new RegExp('<mark class="highlight">([^>]+)</mark>', "gi"), (_, g1) => g1);
};

const highlightText = (searchText: string, html: string) => {
   html = removeHighlight(html);

   if (searchText) {
      const pattern = new RegExp('=".*?"|(' + searchText + ")", "gi");
      html = html.replace(pattern, (match, g1) => (g1 ? "<mark class='highlight'>" + g1 + "</mark>" : match));
   }
   return html;
};

const FaqPage = () => {
   waitForElement(".faq-page").then(async (faqPage) => {
      if (!faqPage) {
         return;
      }

      const elements = {
         container: faqPage.querySelector(".faq-page__faq") as HTMLDivElement,
         form: faqPage.querySelector(".faq-page__header-form") as HTMLFormElement,
         searchInput: faqPage.querySelector(".faq-page__header-input") as HTMLInputElement,
         faqSections: faqPage.querySelectorAll<HTMLDivElement>(".faq-page__faq-section"),
         faqItems: faqPage.querySelectorAll<HTMLElement>(".faq-page__item"),
         noResultContainer: faqPage.querySelector(".faq-page__faq-no-result") as HTMLDivElement,
      };

      const performSearch = (inputText: string) => {
         const { faqSections, faqItems } = elements;

         if (!inputText) {
            faqSections.forEach((f) => f.classList.remove("hidden"));

            faqItems.forEach((f) => {
               f.classList.remove("hidden");
               toggleAccordion(f, false);
               f.innerHTML = removeHighlight(f.innerHTML);
            });

            return;
         }

         const query = inputText.toLowerCase();

         faqItems.forEach((item) => {
            const isMatch = item.textContent?.toLowerCase().includes(query) || false;
            item.classList.toggle("hidden", !isMatch);
            toggleAccordion(item, isMatch);

            item.innerHTML = highlightText(query, item.innerHTML);
         });

         faqSections.forEach((section) => {
            section.classList.toggle("hidden", !section.textContent?.toLowerCase().includes(query));
         });

         const noHits = Array.from(faqSections).every((s) => s.classList.contains("hidden"));
         elements.noResultContainer.classList.toggle("hidden", !noHits);
      };

      const setUpAccordions = () => {
         elements.container.addEventListener("click", (ev) => {
            const target = ev.target as HTMLElement;
            const faqItem = target?.closest(".faq-page__item");

            if (faqItem) {
               toggleAccordion(faqItem, !faqItem.classList.contains("expanded"));
            }
         });
      };

      const handleDebouncedInput = wrappedDebounce((ev: InputEvent) => {
         const target = ev.target as HTMLInputElement;

         if (target.value.length > 2 || target.value.length === 0) {
            performSearch(target.value);
         }
      }, 500);

      const setUpSearchInput = () => {
         elements.form.addEventListener("submit", (e: SubmitEvent) => {
            e.preventDefault();
         });

         elements.searchInput.addEventListener("input", handleDebouncedInput);
      };

      const setInitialExpanded = () => {
         const hash = location.hash;
         if (hash) {
            const target = document.getElementById(hash.replace("#", ""));
            if (target) {
               toggleAccordion(target, true);
            }
         }
      };

      const init = () => {
         setUpAccordions();
         setUpSearchInput();
         setInitialExpanded();
      };

      init();
   });
};

FaqPage();
