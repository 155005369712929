import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { DomLitElement } from "../../../lit-extensions";
import { store } from "../../../store";
import { selectProductListingConfig } from "../product-listing-slice";
import styles from "./filter-clear-button.module.scss";

@customElement("filter-clear-button")
export class FilterClearButton extends DomLitElement {
   @property()
   itemCount: number = 0;

   @property()
   filterLabel?: string;

   render() {
      const {
         translations: { clearAllFilterLabel },
      } = selectProductListingConfig(store.getState());

      return this.itemCount > 0
         ? html`<button class=${styles.button}>${clearAllFilterLabel || this.filterLabel}</button>`
         : null;
   }
}
