import { LitElement } from "lit";

/**
 * The standard base class for our components. Renders the component into the DOM
 * instead of the Shadow DOM to enable the CSS modules pattern as well as inheriting
 * global styles.
 */
export class DomLitElement extends LitElement {
   protected createRenderRoot(): Element | ShadowRoot {
      return this; // Returning this disables shadow root
   }

   /**
    * Dispatches a custom event with optional payload that bubbles up the DOM tree.
    * @param eventName A unique name for the event, should follow the format `[component name]:[event name]`.
    * @param data Any data to include in the event.
    */
   protected dispatchCustomEvent<T>(eventName: string, data?: T) {
      const defaultOptions: CustomEventInit<T> = {
         bubbles: true,
         composed: true,
      };

      this.dispatchEvent(
         new CustomEvent(eventName, {
            ...defaultOptions,
            detail: data,
         })
      );
   }
}

/**
 * Attribute converter for JSON strings, typically used to consume initial data from
 * Razor views.
 * @param value The JSON string.
 * @returns The parsed JSON object.
 */
export const jsonAttributeConverter = (value: string | null) => (value ? JSON.parse(value) : null);

/**
 * Utility type used for typing mixin declarations.
 * @example ```
 *    export delcare class MyMixinInterface {
 *       //...
 *    }
 *    export const MyMixin = <T extends Constructor<LitElement>>(superClass: T) => {
 *        class MyMixinClass extends superClass {
 *         // ...
 *        return MyMixinClass as Constructor<MyMixinInterface> & T;
 *     };
 * ```
 */
export type Constructor<T = {}> = new (...args: any[]) => T;
