import { H2 } from "@afound/react";
import styles from "./contact-us-content.module.scss";
import { HtmlContent } from "@afound/react";
import { FC, useState } from "react";
import { Tracking } from "../tracking";
import { ContactUsServerConfig, Order } from "../types";
import { OrderDetails } from "../tracking/order-details";

const Block: FC<{
   label: string;
   icon: string;
   url?: string;
   onClick?: () => void;
}> = ({ label, icon, url, onClick }) => {
   const inner = (
      <>
         <span className={styles.iconInfo + " " + icon}></span>
         <span className={styles.label}>{label}</span>
      </>
   );

   return url ? (
      <a className={styles.block} href={url} target="_blank" rel="noreferrer" onClick={onClick}>
         {inner}
      </a>
   ) : (
      <div className={styles.block} role="button" onClick={onClick}>
         {inner}
      </div>
   );
};

export const ContactUsContent = (props: ContactUsServerConfig) => {
   const {
      header,
      links: { faqPage, ordersHistoryPage, digitalReturnUrl },
      texts: { heading, contactFaq, contactEmail, contactProfile, replyToEmail, orderTrackingTexts, returnOrderTexts },
      isClosingModeEnabled,
   } = props;

   const [orderDetails, setOrderDetails] = useState<Order[]>([]);

   const handleOrdersFetched = (orders: Order[]) => {
      setOrderDetails(orders);
   };

   const handleBackBtnClick = () => {
      setOrderDetails([]);
   };
   return (
      <div className={styles.content}>
         {!!orderDetails.length ? (
            <OrderDetails
               orderDetails={orderDetails}
               orderTrackingTexts={orderTrackingTexts}
               ordersHistoryPage={ordersHistoryPage}
               onBackClick={handleBackBtnClick}
            />
         ) : (
            <>
               <HtmlContent html={header} className={styles.introHeader} />
               <Tracking
                  onOrdersFetched={handleOrdersFetched}
                  orderTrackingTexts={orderTrackingTexts}
                  returnOrderTexts={returnOrderTexts}
                  digitalReturnUrl={digitalReturnUrl}
                  faqPage={faqPage}
               />
            </>
         )}
         <H2 className={styles.blocksHeading}>{heading}</H2>
         <div className={styles.blocks}>
            {!isClosingModeEnabled && <Block icon="p12icon-faq" label={contactFaq} url={faqPage} />}
            <Block icon="p12icon-profile--big" label={contactProfile} url={ordersHistoryPage} />
            <Block icon="p12icon-envelope--big" label={contactEmail} url={`mailto:${replyToEmail}`} />
         </div>
      </div>
   );
};
