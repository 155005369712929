import { useRef, useState, FC } from "react";
import { Tooltip, TooltipPosition, useOutsideClick } from "@afound/react";

import styles from "./info-tooltip.module.scss";

interface InfoTooltipProps {
   text: string;
   position?: TooltipPosition;
}

export const InfoTooltip: FC<InfoTooltipProps> = ({ text, position = "above" }) => {
   const [visible, setVisible] = useState(false);

   const handleInfoIconClick = () => setVisible((prev) => !prev);

   const tooltipRef = useRef(null);
   useOutsideClick(tooltipRef, () => setVisible(false));

   return (
      <span ref={tooltipRef} className={styles.tooltipWrapper}>
         <span className="icon p12icon-info-gray" onClick={handleInfoIconClick}>
            <Tooltip visible={visible} position={position} className={styles.tooltipText}>
               {text}
            </Tooltip>
         </span>
      </span>
   );
};
