import { FC } from "react";
import { Order, OrderTrackingTexts } from "../../../types";
import styles from "./order-card.module.scss";
import { InfoTooltip } from "../../../../../components/info-tooltip";
import classNames from "classnames";

interface OrderCardProps {
   order: Order;
   packageNo: number;
   orderTrackingTexts: OrderTrackingTexts;
}

export const OrderCard: FC<OrderCardProps> = ({
   order,
   packageNo,
   orderTrackingTexts: { packageLabel, soldAndShippedBy, orderDetailsHeading, trackDelivery, trackYourOrderHelpText },
}) => {
   const handleTrackOrderClick = () => {
      if (order.shippingTrackingUrl) {
         window.open(order.shippingTrackingUrl, "_blank");
      } else {
         return;
      }
   };

   return (
      <div className={styles.orderItem}>
         <div>
            <span className="icon p12icon-box"></span>
            <span className={styles.bold}>
               {packageLabel} {packageNo}
            </span>
         </div>
         <div>
            {soldAndShippedBy}&nbsp;
            <span className={styles.bold}> {order.shopName}</span>
         </div>
         <div>
            <span>
               {orderDetailsHeading}: {order.logisticalId}
            </span>
            <span
               onClick={handleTrackOrderClick}
               className={classNames(styles.trackOrder, { [styles.disabled]: !order.shippingTrackingUrl })}
            >
               {trackDelivery}
               {!order.shippingTrackingUrl && <InfoTooltip text={trackYourOrderHelpText} position="under" />}
            </span>
         </div>
      </div>
   );
};
