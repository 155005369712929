import { Cart } from "@afound/types";
import { BackInStockTexts } from "./back-in-stock/types";

export interface ProductPageServerConfig {
   name: string;
   productId: string;
   productIdEsales: string;
   price: number;
   currency: string;
   discount: number;
   sellerName: string;
   color: string;
   brandName: string;
   offerCode: string;
   selectedSize: string;
   sizes: ProductSize[];
   isLoggedIn: boolean;
   customerEmail: string;
   variantId: string;
   productCode: string;
   offerId: string;
   epiProductKey: string;
   categoryName: string;
   translations: {
      priorPrice: string;
      addToCart: string;
      upsellingTitle: string;
      soldOut: string;
      wasPriceInfo: string;
      priorPriceInfo: string;
      discountInfo: string;
      addedToCart: string;
      memberPromotionTooltip: string;
   } & BackInStockTexts;
   markersText: string;
   productRelationGuid: string;
   hasReviews: boolean;
   currentCulture: string;
   gender: string;
   productConcept: string;
   isKidsDepartment: boolean;
   fbEventId: string;
   sizeGuideCategory: string;
   enableHideBackInStock?: boolean;
}

export interface ProductSize {
   id: string;
   name: string;
   selected: boolean;
   isFewLeft: boolean;
   prices: ProductPrice;
   isSoldOut: boolean;
}

export interface ProductPrice {
   comparisonPriceInfoFormatted: string;
   originalPrice: number;
   previousPrice?: number;
   currentPrice: number;
   discountPercentage: number;
   comparisonPrice?: number;
}

export interface AddToCartRequest {
   offerCode: string;
   recommendationPanel: string | null;
}

export interface AddToCartResponse {
   result: Cart;
   cartSnapshot: CartSnapshot;
   offerCode: string;
   message: string;
   fbEventId: string;
}

export interface CartSnapshot {
   cartId: number;
   currencyCode: string;
   deleted: boolean;
   items: Array<CartItem>;
   marketName: string;
   productCount: number;
   shippingFee: number;
   totalValue: number;
   totalWasValue: number;
   youSaved: number;
}

interface CartItem {
   brand: string;
   discount: number;
   imageUrl: string;
   name: string;
   price: number;
   quantity: number;
   seller: string;
   size: string;
   stock: string;
   url: string;
   variantCode: string;
   wasPrice: number;
   youSaved: number;
}

export interface WishlistResponse {
   exists: boolean;
}

export const AddToCartEvent = "pdp:addToCart";
export interface AddToCartEventArgs {
   sizeInfo: string;
}
