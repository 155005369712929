import { displayWithCurrency, escapeXml } from '../../../Common/util';

const getMarkerTheme = (value) => {
   // value can be both enum or string due
   // to inconsistent json serializer option in BE
   switch (value) {
      case 1:
      case 'Secondary':
         return 'secondary';

      case 2:
      case 'Tertiary':
         return 'tertiary';

      case 3:
      case 'Quaternary':
         return 'quaternary';

      case 10:
      case 'Member':
         return 'member';

      default:
         return 'primary';
   }
}

const createMarker = data => {
   const markerCssClass = `marker marker--${getMarkerTheme(data.Theme)}`;

   return `<div class="${markerCssClass}">${data.Text}</div>`;
};

const createMarkers = data => {
   if (data?.length) {
      return `<div class="markers markers--top-left">
            ${data.map(marker => createMarker(marker)).join('')}
          </div>`
   }

   return "";
};

const createPriceGuaranteeLabel = (translations) => {
   const text = (translations && translations.priceGuarantee) || 'Price guarantee';
   return `<span class="price-guarantee-label">
					${text}
				</span>`;
};

const createPriceGuaranteeAndComparisonPrice = (comparisonPriceInformation, showPriceGuarantee, translations) => {
   const priceGuarantee = showPriceGuarantee && createPriceGuaranteeLabel(translations);
   const comparisonPrice = comparisonPriceInformation && `<span class="price-info__comparison-price">${comparisonPriceInformation}</span>`;

   if (!comparisonPrice && !priceGuarantee) {
      return '';
   }

   return `<div class="afpl-product-additional-info">
            ${priceGuarantee || ''}
            ${comparisonPrice || ''}
          </div>`;
};

const createProductCardHtml = (data, options, translations) => {
   const { PriceLegFirstStrikethrough, PriceLegSecondStrikethrough, PriceLegDiscount, OverlayButtonText } = translations;
   let sizes = data.Sizes && data.Sizes.join(' ');
   let brandName = data.BrandName || 'Unknown Brand';
   let priceGuaranteeAndComparisonPrice = createPriceGuaranteeAndComparisonPrice(
      data.ComparisonPriceInformation,
      data.ShowPriceGuarantee,
      translations);
   let displayWasPrice = data.WasPrice > data.DisplayPrice ? '' : 'style="display:none;"';
   let displayPriorPrice = data.PriorPrice !== null && data.PriorPrice > 0 ? '' : 'style="display:none;"';
   let priorPriceCssClass = data.PriorPrice !== null && data.PriorPrice > 0 ? '' : 'afpl-product-info--noprior';
   let displayPercentOff = data.PercentOff <= 0 || data.HideDiscountPercentage ? 'style="display:none;"' : '';
   let displayPercentAstrix = data.PriorPrice;
   let markers = createMarkers(data.Markers);
   let wishlistMarker = data.HideWishListIcon ? '' : `
    <div class="markers markers--top-right wishlist-button">
      <wishlist-button variantId="${data.VariantCode}" productCode="${data.Code}" epiProductKey="${data.EsalesProductKey}" context="productlisting" ${data.IsInWishlist ? "isWishlistItem" : ""}></wishlist-button>
    </div>`;
   let wasPrice = displayWithCurrency(data.WasPrice, data.CurrencyCode);
   let displayPrice = displayWithCurrency(data.DisplayPrice, data.CurrencyCode);
   let priorPrice = displayWithCurrency(data.PriorPrice, data.CurrencyCode);
   let gtmList = (options && options.gtmList) || '';
   let animationClass = (options && options.fadeIn) ? 'fade-in' : '';
   let memberClass = data.IsLocked ? "afpl-product--member" : "";
   let memberButton = data.IsLocked ? `<button class="btn btn--medium member-overlay-cta">${OverlayButtonText}</button>` : "";

   return `
<div class="afpl-product ${animationClass} ${memberClass}">
    <a href="${data.Url}" class="eS-click eS-t-${data.Ticket}"
       data-gtm-id="${data.Code}" data-gtm-product='${escapeXml(JSON.stringify(data))}' data-gtm-list="${gtmList}">
        <div class="afpl-product-img-container">
            <picture>
                <source media="(max-width: 480px)" srcset="${data.MainImageUrl}?preset=productcard-webp-v2" type="image/webp" />
                <source media="(max-width: 480px)" srcset="${data.MainImageUrl}?preset=productcard">
                <source media="(min-width: 481px)" srcset="${data.MainImageUrl}?preset=productcard-desktop-webp-v2" type="image/webp" />
                <source media="(min-width: 481px)" srcset="${data.MainImageUrl}?preset=productcard-desktop">
                <img class="afpl-product-img" src="${data.MainImageUrl}?preset=productcard" alt="${data.Name}" loading="lazy">
            </picture>
            ${markers}
            ${wishlistMarker}
            <div class="markers markers--bottom-right">
                <div class="percent-off ${displayPercentAstrix ? "" : "percent-off--no-astrix"}" ${displayPercentOff}><span>${data.PercentOff}</span>%</div>
            </div>
        </div>
        <div class="afpl-product-sizes">${sizes}</div>
       
        <div class="afpl-product-info ${priorPriceCssClass}">
            <div class="afpl-text-info">
               <div class="afpl-text-info-position">
                  <span class="afpl-product-brand">${brandName}</span>
                  <br />
                  <span class="afpl-product-title">${data.Name}</span>  
               </div>
            </div>
            <div class="afpl-price-info">
                <div class="price-info-table">
                    <div class="tooltip">
                        <span class="tooltip-icon icon p12icon-info-gray"></span>
                        <div class="tooltip-content">
                            <div class="tooltip-section">
                                <span class="tooltip-dot"></span>
                                <span class="tooltip-typography">
                                    ${PriceLegFirstStrikethrough}
                                </span>
                            </div>
                              <div class="tooltip-section" ${displayPriorPrice}>
                                 <span class="tooltip-dot"></span>
                                 <span class="tooltip-typography">
                                    ${PriceLegSecondStrikethrough}
                                    </span>
                              </div>
                            <div class="tooltip-section" ${displayPriorPrice}>
                                <span class="tooltip-dot"></span>
                                <span class="tooltip-typography">
                                    ${PriceLegDiscount}
                                   </span>
                            </div>
                        </div>
                        <div class="price-info-table-row">
                            <div class="price-info-table-col">
                                <div class="afpl-product-wasprice" ${displayWasPrice}>
                                    <span class="strikethrough">${wasPrice}</span>
                                </div>
                            </div>
                        </div>
                        <div class="price-info-table-row">
                            <div class="price-info-table-col">
                                <div class="afpl-product-prior" ${displayPriorPrice}>
                                    <span class="strikethrough">${priorPrice}</span>
                                </div>
                            </div>
                        </div>
                        <div class="price-info-table-row">
                            <div class="price-info-table-col">
                                <span class="afpl-product-price">${displayPrice}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         ${priceGuaranteeAndComparisonPrice}
       </a>
       ${memberButton}
</div>`;
};

const createProductCarouselCell = (data, translations) => {
   return `
  <div class="carousel-cell">${createProductCardHtml(data, { gtmList: 'AddToCartPopup' }, translations)}</div>
  `;
};

export const createActiveFilterText = (group, value, text) => {
   return `
	<span class="filter-modal__filter--active-group--item"
  data-js-selector="active-filter"
  data-filtergroup="${group}" 
  data-filtervalue="${value}">${text}</span>
`;
};

export const createCategoryTileMobile = (facet, facetGroup) => {
   const selected = facet.Selected ? 'active' : '';
   const hasSubLevel = facet.Children?.length > 0 ? 'has-sublevel' : '';
   const markup = `<li class='mobile-category-navigation-list-item ${selected} ${hasSubLevel}' 
					 data-js-selector="filter-change-trigger" 
					 data-filtergroup="${facetGroup}" 
					 data-filtervalue="${facet.Id}"
                data-filtertext="${facet.Text}"
                data-js-filter-parent-id="${facet.ParentId}"
					 data-js-filter-has-children="${facet.Children.length > 0}"
                data-js-category-filter>
					 ${facet.Text}			
                </li>`;

   const markupDiv = document.createElement('div');
   markupDiv.insertAdjacentHTML('beforeend', markup);
   return markupDiv.firstChild;
};

export const createCategoryTileDesktop = (facet, facetGroup) => {
   const selected = facet.Selected ? 'active' : '';
   const hasSubLevel = facet.Children?.length > 0 ? 'has-sublevel' : '';
   const markup = `<li class='desktop-category-navigation-list-item ${selected} ${hasSubLevel}'> 
					 <a data-filtergroup="${facetGroup}" 
					 data-filtervalue="${facet.Id}"
                data-filtertext="${facet.Text}"
                data-js-filter-parent-id="${facet.ParentId}"
					 data-js-filter-has-children="${facet.Children.length > 0}"
                data-js-category-desktop-filter
	             data-js-selector='filter-change-trigger'>${facet.Text}</a>	
                </li>`;

   const markupDiv = document.createElement('ul');
   markupDiv.insertAdjacentHTML('beforeend', markup);
   return markupDiv.firstChild;
};

export const createCategoryTilesULDesktop = () => {
   const markup = `<ul class='desktop-category-navigation-list' data-js-selector='categories-tiles'>
                </ul>`;
   const markupDiv = document.createElement('ul');
   markupDiv.insertAdjacentHTML('beforeend', markup);
   return markupDiv.firstChild;
}

export const createCarouselProductList = (data, translations) => {
   let markup = data && data.length ? data.map(product => createProductCarouselCell(product, translations)).join('') : '';

   return markup;
};

export const createProductList = (data, options, translations) => {
   let markup = data && data.length ? data.map(product => createProductCardHtml(product, options, translations)).join('') : '';

   return markup;
};

export const createSeoPagingItem = (page, selected, hasDivider, getUrl) => {
   const url = getUrl(page);
   return `
    <a class="tiled-list__item button-select ${selected ? 'active' : ''} ${hasDivider ? 'paging-divider' : ''}" href="${url}">
      ${page}
    </a>`;
};
