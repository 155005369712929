import classNames from "classnames";
import { useEffect, useRef } from "react";

import { BackToTopButton } from "../../../components/back-to-top-button";
import { useAppDispatch } from "../../../store";
import { FilterClearButton } from "../filter-clear-button";
import { setProductListingServerConfig } from "../product-listing-slice";
import { ProductListingServerConfig } from "../types";
import { FilterButton } from "./filter-button";
import { ProductCount } from "./product-count";
import { SortBy } from "./sort-by";
import styles from "./sticky-filter-bar.module.scss";
import { CustomElement } from "./type";
import { useStickyFilterbar } from "./use-sticky-filter-bar";

declare global {
   namespace JSX {
      interface IntrinsicElements {
         ["filter-clear-button"]: CustomElement<FilterClearButton>;
         ["back-to-top-button"]: CustomElement<BackToTopButton>;
         ["filter-button"]: CustomElement<FilterButton>;
         ["product-count"]: CustomElement<ProductCount>;
      }
   }
}

type StickyFilterBarProps = ProductListingServerConfig;

export const StickyFilterBar = (props: StickyFilterBarProps) => {
   const { filterCount, productCount } = props;

   const informationBarElement = useRef<HTMLDivElement>(document.querySelector(".information-bar"));

   const dispatch = useAppDispatch();

   useEffect(() => {
      dispatch(setProductListingServerConfig(props));
   }, []);

   const { isVisible, disableStickyFilter } = useStickyFilterbar();

   const wrapperClass = classNames({
      [styles.wrapper]: true,
      ["product-list-header"]: true,
      [styles.hasTopInformationBar]: !!informationBarElement.current,
      [styles.isVisible]: isVisible,
   });

   return (
      <div className={wrapperClass}>
         <div className={styles.inner}>
            <div className="listing-options-left">
               <filter-button itemCount={filterCount} />
               <filter-clear-button class="listing-options-filter-clear" itemCount={filterCount} />
            </div>
            <back-to-top-button class={styles.backToTop} onClick={disableStickyFilter} />
            <div className="listing-options-right">
               <product-count id="sticky-filter-product-count" itemCount={productCount} />
               <SortBy />
            </div>
         </div>
      </div>
   );
};
