import { sessionStorage } from "@afound/common";
import { useEffect, useState } from "react";

const getPersistedDelayKey = (id: string) => `${id}_persistedDelay`;

/**
 * Provides countdown/delay functionality that is persisted across page loads.
 * ```ts
 * const currentDelay = useDelay(true, 30, "myFeature");
 * ```
 * @param isEnabled Setting to false will result in a no-op (since hooks can't be wrapped in if/else statements).
 * @param delayInSeconds The delay in seconds.
 * @param key A unique key for the consuming feature.
 * @returns The current delay. 0 means the countdown is finished.
 */
export const useDelay = (isEnabled: boolean, delayInSeconds: number, key: string) => {
   const persistedDelayKey = getPersistedDelayKey(key);

   const persistedDelay = sessionStorage.getItem(persistedDelayKey);
   const initialDelay = persistedDelay ? parseInt(persistedDelay) : delayInSeconds;

   // Add 1 to the initial delay compensate for the initial -1 in setInterval on the first cycle
   const [currentDelay, setCurrentDelay] = useState(initialDelay + 1);

   useEffect(() => {
      if (!isEnabled) {
         return;
      }

      const timer = setInterval(() => {
         const newDelay = currentDelay - 1;

         if (newDelay <= 0) {
            clearInterval(timer);
            setCurrentDelay(0);
            sessionStorage.remove(persistedDelayKey);
            return;
         }

         sessionStorage.setItem(persistedDelayKey, newDelay.toString());
         setCurrentDelay(newDelay);
      }, 1000);

      return () => clearInterval(timer);
   }, [isEnabled, currentDelay]);

   return currentDelay;
};
