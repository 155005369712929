import { isString } from "@afound/common";

export const displayWithCurrency = (price: number, currencyCode: string) => {
   const integerFormatterOptions = {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
   };

   const fractionFormatterOptions = {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
   };

   const swedishFractionFormatter = new Intl.NumberFormat("sv-SE", fractionFormatterOptions);
   const euroFractionFormatter = new Intl.NumberFormat("en-GB", fractionFormatterOptions);
   const swedishIntegerFormatter = new Intl.NumberFormat("sv-SE", integerFormatterOptions);
   const euroIntegerFormatter = new Intl.NumberFormat("en-GB", integerFormatterOptions);

   if (!currencyCode) {
      currencyCode = "";
   }

   price = price ? price : 0;
   const priceHasFractions = price % 1 !== 0;

   switch (currencyCode.toLowerCase()) {
      case "eur":
         return (
            "\u20AC" + (priceHasFractions ? euroFractionFormatter.format(price) : euroIntegerFormatter.format(price))
         );
      case "sek":
         return (
            (priceHasFractions ? swedishFractionFormatter.format(price) : swedishIntegerFormatter.format(price)) +
            "\u00A0SEK"
         );
      default:
         return price + " " + currencyCode;
   }
};

/**
 * Formats a "templated" string (a string with a {} placeholder) by replacing the placeholder with the
 * provided string/result returned from the provided function.
 * Example:
 * `const result = formatTemplateString("Size {size} is sold out!", "L");`
 * @param template The string template, must contain exactly one placeholder, marked with {}.
 * @param replacer The replacer, can be either a string literal value or a function.
 * @returns
 */
export const formatTemplatedString = (template: string, replacer: string | ((placeholder: string) => string)) => {
   if (!template) {
      return template;
   }

   const replacerIsString = isString(replacer);
   return template.replace(/{(.+?)}/, (_: string, g1: string) => (replacerIsString ? replacer : replacer(g1)));
};


export const getCookie = (cName: string) => {
   const name = cName + "=";
   const cookieDecoded = decodeURIComponent(document.cookie);
   const cookieArr = cookieDecoded.split('; ');
   let res;
   cookieArr.forEach(val => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
   })
   return res;
}

export const setCookie = (cookieName: string, cookieValue: boolean, expHours: number) => {
   let date = new Date();
   date.setTime(date.getTime() + (expHours * 24 * 60 * 60 * 1000));
   const expires = "expires=" + date.toUTCString();
   document.cookie = cookieName + "=" + cookieValue + "; " + expires + "; path=/";
}
