import { GtmEvent } from "@afound/types";

import { DepartmentPreference } from "./types";

export type MarketSelectorTrigger = "header" | "footer" | "start page";

export const getMarketSelectorGtmEvent = (
   trigger: MarketSelectorTrigger,
   fromMarket: string,
   toMarket: string
): GtmEvent => ({
   event: "countryPickerEvent",
   eventCategory: "Country picker",
   eventAction: trigger,
   eventLabel: `${fromMarket.toLowerCase()} to ${toMarket.toLowerCase()}`,
});

export const getNewsletterSignupEvent = (
   label: string,
   departmentPreference: DepartmentPreference,
   email?: string,
   category: string = "Email"
) => ({
   event: "newsletterSignupEvent",
   eventAction: "newslettersignup",
   eventCategory: category,
   eventLabel: label,
   email,
   departmentPreference,
});

export const getNewsletterSignupErrorEvent = (
   label: string,
   departmentPreference: DepartmentPreference,
   email?: string
) => ({
   ...getNewsletterSignupEvent(label, departmentPreference, email),
   eventAction: "error:newslettersignup",
});
