import { ApiStatus, get } from "@afound/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import ai from "../../../ai";
import { RootState } from "../../../store";
import { MessageSubject, Order, OrderHistory } from "./types";
import { aiFeatureName } from "../types";

interface OrderState {
   status: ApiStatus;
   messageSubjects: MessageSubject[];
   orders: Order[];
}

const initialState: OrderState = {
   status: "idle",
   messageSubjects: [],
   orders: [],
};

export const fetchOrders = createAsyncThunk("order/fetchOrders", async (locale: string) => {
   try {
      return (await get<OrderHistory>(`/api/v1/mypages/orderhistory?locale=${locale}`)) as OrderHistory;
   } catch (err) {
      ai.trackException({
         exception: err as Error,
         properties: { feature: aiFeatureName },
      });
      throw err;
   }
});

export const orderSlice = createSlice({
   name: "order",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(fetchOrders.pending, (state) => {
         state.status = "loading";
      });
      builder.addCase(fetchOrders.fulfilled, (state, action: PayloadAction<OrderHistory>) => {
         const { subjects: messageSubjects, orders } = action.payload;

         return {
            ...state,
            status: "success",
            messageSubjects,
            orders,
         };
      });
      builder.addCase(fetchOrders.rejected, (state) => {
         state.status = "error";
      });
   },
});

export const selectOrders = (s: RootState) => s.order;
export const selectLogisticalOrder = (s: RootState, orderId: string) => {
   return {
      status: s.order.status,
      order: s.order.orders.flatMap((o) => o.orderDeliveries).find((l) => l.header.orderId === orderId),
   };
};
export const selectMessageTopics = (s: RootState) => s.order.messageSubjects;

export default orderSlice.reducer;
