import { Product } from "@afound/types";
import { UpsellingProduct } from "./types";

export const mapUpsellingProductToProduct = (item: UpsellingProduct) => {
   return {
      title: item.name,
      imageUrl: item.mainImageUrl,
      sizes: item.sizes,
      brand: item.brandName,
      actualPrice: item.displayPrice,
      wasPrice: item.wasPrice,
      priorPrice: item.priorPrice,
      offerCode: item.primaryVariantOfferCode,
      productUrl: item.url,
      ticket: item.ticket,
      productCode: item.code,
   } as Product;
};
