import { get } from "@afound/common";
import { useEffect, useState } from "react";
import { ApiSearchItem, SearchItem } from "./types";
import ai from "../../ai";

export const useSearch = (query: string, searchUrl: string, filterQuery: string) => {
   const [results, setResults] = useState<SearchItem[]>([]);

   useEffect(() => {
      const postRequest = async () => {
         try {
            const response = await get<ApiSearchItem[]>(
               `${searchUrl}?handler=suggestions&q=${encodeURIComponent(query)}`
            );

            if (!response) {
               return;
            }

            let mappedItems: SearchItem[];

            mappedItems = response.map<SearchItem>((item) => {
               const url = new URL(item.url);

               if (filterQuery) {
                  url.searchParams.set("department", filterQuery);
               }

               return {
                  phrase: item.name,
                  url: item.label === "brand" ? item.url : url.toString(),
                  icon: item.label === "brand" ? "brand" : "search",
                  type: "None",
               };
            });

            setResults(mappedItems);
         } catch (err) {
            ai.trackException({ exception: err as Error, properties: { feature: "search" } });
         }
      };

      if (query) {
         postRequest();
      } else {
         setResults([]);
      }
   }, [query, filterQuery]);

   return results;
};
