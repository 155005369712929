import styles from "./departments.module.scss";
import { SearchDepartmentConfigurationItem } from "../types";
import { Department } from "./department";

interface DepartmentsProps {
   departments: SearchDepartmentConfigurationItem[];
   activeDepartment?: string;
   onDepartmentSelect: (departmentId: string) => void;
}

export const Departments = ({ departments, activeDepartment, onDepartmentSelect }: DepartmentsProps) => {
   const currentDepartment = departments.find((s) => s.departmentGuid === activeDepartment);

   return (
      <div>
         <ul className={styles.departmentsList}>
            {departments.map((department) => {
               const isActiveDepartment = currentDepartment
                  ? department.departmentGuid === activeDepartment
                  : department.departmentGuid === null;

               return (
                  <Department
                     key={department.departmentGuid}
                     department={department}
                     isActiveDepartment={isActiveDepartment}
                     onDepartmentSelect={onDepartmentSelect}
                  />
               );
            })}
         </ul>
      </div>
   );
};
