import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { store } from "../../store";
import { StickyFilterBar } from "./sticky-filter-bar";

export * from "./member-overlay";

// Product listing web components
export * from "./sticky-filter-bar/filter-button";
export * from "./sticky-filter-bar/product-count";
export * from "./filter-count";
export * from "./filter-clear-button";

export * from "./product-filter";

export * from "./seller";

const stickyFilterRoot = document.getElementById("sticky-filter-bar-root");

if (stickyFilterRoot) {
   createRoot(stickyFilterRoot).render(
      <Provider store={store}>
         <StickyFilterBar {...INITIALDATA.productListing} />
      </Provider>
   );
}
