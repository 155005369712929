import { Loader } from "@afound/react";
import { ReactNode } from "react";

import styles from "./container.module.scss";

interface ContainerProps {
   loading?: boolean;
   className?: string;
   children: ReactNode;
}

export const Container = (props: ContainerProps) => (
   <section className={[styles.container, props.className].join(" ").trimEnd()}>
      {props.loading ? <Loader visible /> : props.children}
   </section>
);
