import { Button, InputField, Popup } from "@afound/react";
import { ProductSize } from "../../../../shared/types";
import { BackInStockServerConfig } from "../types";

import styles from "./back-in-stock-popup.module.scss";
import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { dispatchCustomEvent, validateEmail } from "@afound/common";
import { useBackInStock } from "../../../back-in-stock/use-back-in-stock";
import { useMemo, useRef, useState } from "react";
import { formatTemplatedString } from "../../../../shared/utils";
import { getBackInStockSignupEvent } from "../../../back-in-stock/tracking";
import { LoginModalEventArgs, ShowLoginModal } from "../../../login/types";
import { SitePopup } from "../../../../components";

interface BackInStockPopupProps
   extends Pick<
      BackInStockServerConfig,
      "productCode" | "currentMarket" | "prefilledEmail" | "texts" | "isLoggedIn" | "enableHideBackInStock"
   > {
   visible: boolean;
   selectedSize?: ProductSize;
   onSubscribe: (isSuccess: boolean) => void;
   onClose: () => void;
}

const getValidationSchema = (invalidEmail: string) =>
   yup.object({
      email: yup
         .string()
         .required(invalidEmail)
         .trim()
         .lowercase()
         .test("isValidEmail", invalidEmail, (val?: string) => (val ? validateEmail(val) : false)),
   });

interface BackInStockValues {
   email: string;
}

export const BackInStockPopup = (props: BackInStockPopupProps) => {
   const {
      visible,
      selectedSize,
      productCode,
      currentMarket,
      prefilledEmail,
      texts: {
         backInStockPopupTitle,
         backInStockPopupMessage,
         invalidEmail,
         emailPlaceholder,
         backInStockPopupCta,
         backInStockPopupTitleGuest,
         backInStockPopupMessageGuest,
         backInStockPopupCtaGuest,
      },
      enableHideBackInStock,
      isLoggedIn,
      onSubscribe,
      onClose,
   } = props;

   const popupRef = useRef<HTMLDivElement>(null);

   const [loading, setLoading] = useState(false);

   const { addNotificationSubscriber } = useBackInStock();

   const handleSubmit = async (values: BackInStockValues, helpers: FormikHelpers<BackInStockValues>) => {
      const [variantId] = selectedSize!.id.split("_");

      setLoading(true);

      const isSuccess = await addNotificationSubscriber({
         variantId,
         marketCulture: currentMarket,
         email: values.email,
      });

      setLoading(false);

      if (isSuccess) {
         window.dataLayer.push(getBackInStockSignupEvent("submitted", productCode));
         helpers.resetForm();
      }

      onSubscribe(isSuccess);
   };

   const formattedPopupTitle = useMemo(
      () => (selectedSize ? formatTemplatedString(backInStockPopupTitle, selectedSize!.name) : backInStockPopupTitle),
      [backInStockPopupTitle, selectedSize]
   );

   const handleGuestClick = () => {
      onClose();
      dispatchCustomEvent<LoginModalEventArgs>(popupRef.current!, ShowLoginModal);
   };

   const renderContent = () => {
      const hideBackInStockSignupForm = enableHideBackInStock && !isLoggedIn;

      return hideBackInStockSignupForm ? (
         <>
            <div className={styles.title}>{backInStockPopupTitleGuest}</div>
            <div className={styles.description}>{backInStockPopupMessageGuest}</div>
            <Button type="button" theme="primary" disabled={loading} onClick={handleGuestClick}>
               {backInStockPopupCtaGuest}
            </Button>
         </>
      ) : (
         <>
            <div className={styles.title}>{formattedPopupTitle}</div>
            <div>{backInStockPopupMessage}</div>
            <Formik
               initialValues={{ email: prefilledEmail || "" }}
               validationSchema={getValidationSchema(invalidEmail)}
               onSubmit={handleSubmit}
            >
               <Form className={styles.form}>
                  <InputField type="email" name="email" placeholder={emailPlaceholder} />
                  <Button type="submit" theme="secondary" disabled={loading}>
                     {backInStockPopupCta}
                  </Button>
               </Form>
            </Formik>
         </>
      );
   };

   return (
      <SitePopup
         type="backInStockPopup"
         animationStyle="in"
         visible={visible}
         disableStackingContext={{
            active: visible,
            targetElementQuery: ".new-product-page__container",
         }}
         onClose={onClose}
      >
         <div className={styles.content} ref={popupRef}>
            {renderContent()}
         </div>
      </SitePopup>
   );
};
