import { ApiStatus } from "@afound/common";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../store";

export interface SessionState {
   logoutStatus: ApiStatus;
}

const initialState: SessionState = {
   logoutStatus: "idle",
};

export const logout = createAsyncThunk("customer/logout", async () => {
   const res = await window.fetch("/api/v1/authentication/logout", {
      method: "POST",
      credentials: "include",
   });

   if (res.redirected) {
      window.location.href = res.url;
   }

   return;
});

const sessionSlice = createSlice({
   name: "session",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(logout.pending, (state) => {
         state.logoutStatus = "loading";
      });
      builder.addCase(logout.fulfilled, (state) => {
         state.logoutStatus = "success";
      });
      builder.addCase(logout.rejected, (state) => {
         state.logoutStatus = "error";
      });
   },
});

export const selectSession = (s: RootState) => s.session;

export default sessionSlice.reducer;
