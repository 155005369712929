// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YOAj_XpiCNunAo97WN2i{padding:0 40px;text-align:center}.YOAj_XpiCNunAo97WN2i h4{font-size:20px;margin:16px 0 8px 0}.NoatL7vLuTEMflHHx1N7{margin-bottom:16px}@media (min-width: 992px){.YOAj_XpiCNunAo97WN2i{padding:0 48px}}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/wishlist/wishlist-content/wishlist-content.module.scss"],"names":[],"mappings":"AAAA,sBACC,cAAe,CACf,iBAAkB,CAFnB,yBASE,cAAe,CACf,mBAAoB,CACpB,sBAID,kBAAmB,CAXnB,0BAJD,sBAKE,cAAe,CAOhB","sourcesContent":[".placeholder {\r\n\tpadding: 0 40px;\r\n\ttext-align: center;\r\n\r\n\t@media(min-width: 992px) {\r\n\t\tpadding: 0 48px;\r\n\t}\r\n\r\n\th4 {\r\n\t\tfont-size: 20px;\r\n\t\tmargin: 16px 0 8px 0;\r\n\t}\r\n}\r\n\r\n.notification {\r\n\tmargin-bottom: 16px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": "YOAj_XpiCNunAo97WN2i",
	"notification": "NoatL7vLuTEMflHHx1N7"
};
export default ___CSS_LOADER_EXPORT___;
