import { createProductList } from '../Shared/ProductListing/productListingHtmlCreators';
import pagingFactory from '../Shared/ProductListing/pagingFactory';
import ajax from '../../Common/ajax';

window.Afound = window.Afound || {};

const productPage = () => {
   const paging = pagingFactory();

   const showSoldOutModal = () => {
      const soldoutPopup = document.querySelector('.client-modal-soldout-recommendations');
      if (soldoutPopup == null) {
         return;
      }

      if (INITIALDATA.product && INITIALDATA.product.CurrentSize == null) {
         setTimeout(function () {
            soldoutPopup.classList.add('client-modal--is-open');
         }, 3000);
      } else {
         soldoutPopup.classList.remove('client-modal--is-open');
      }
   };

   const hideSoldOutModal = () => {
      document.querySelector('.client-modal-soldout-recommendations').classList.remove('client-modal--is-open');
   };

   const renderProducts = (products) => {
      const container = document.querySelector('[data-js-selector=pdp-recommendations-container]');
      if (container) {
         const listHtml = createProductList(products, { fadeIn: true }, INITIALDATA.cartTranslations);
         container.insertAdjacentHTML('beforeend', listHtml);
      }
   };

   const updateLoader = (serverPaging) => {
      if (!serverPaging.HasMorePages) {
         const nav = document.querySelector('[data-js-selector="pdp-recommendations-nav"]');
         nav.classList.add('afpl-products__nav--end');
      }
   };

   const loadMoreRecommendations = (idx) => {
      let nextPage = paging.nextPage();
      window.history.replaceState(null, null, '?page=' + nextPage);

      ajax.GET(`${location.pathname}?page=${nextPage}`).then(function (result) {
         paging.update(result.data.Paging);
         updateLoader(result.data.Paging);
         renderProducts(result.data.Products);
      }, function (error) {
         appInsights && appInsights.trackException(error);
      });
   };

   const setListeners = () => {
      const hideSoldOutButton = document.querySelector('[data-js-selector="pdp-hide-sold-out-modal"]');
      if (hideSoldOutButton) {
         hideSoldOutButton.addEventListener('click', function (ev) {
            ev.preventDefault();
            hideSoldOutModal();
         });
      }

      const loadMoreButton = document.querySelectorAll('[data-js-selector="pdp-recommendations-load-more"]');
      if (loadMoreButton) {
         loadMoreButton.forEach(button => button.addEventListener('click', function (ev) {
            ev.preventDefault();
            loadMoreRecommendations();
         }));
      }
   };

   const init = () => {
      showSoldOutModal();
      setListeners();
      const initialPaging = INITIALDATA.newProduct ? INITIALDATA.newProduct.paging : INITIALDATA.product.paging
      paging.update(initialPaging);
   };

   return { init };
};

export default productPage();
