import { ApiError, post } from "@afound/common";
import { PascalCase } from "@afound/types";
import { useCallback } from "react";

import ai from "../../ai";
import { DepartmentPreference } from "../../shared/types";
import { SubscriptionResponse } from "../../features/newsletter-popup/types";

export const useSubscription = () => {
   const subscribeNewsletter = async (
      email: string,
      department: DepartmentPreference
   ): Promise<SubscriptionResponse> => {
      const getFormData = () => {
         const form = new FormData();
         form.append("email", email);
         form.append("DepartmentPreference", department);
         form.append("subscribedFromFunction", "customer bar");
         form.append("SubscribeToNewsletter", "true");
         return form;
      };

      try {
         const response = await post<any, PascalCase<SubscriptionResponse>>(
            "/api/v1/newsletter/subscribe",
            getFormData(),
            {
               requestType: "formdata",
            }
         );

         return response?.Success ? { success: true } : { success: false };
      } catch (err) {
         const error = err as Error;
         ai.trackException({ exception: error });
         return { success: false, message: (error as ApiError<string>).content };
      }
   };

   return {
      subscribeNewsletter: useCallback(subscribeNewsletter, []),
   };
};
