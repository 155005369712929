import { PayloadAction, UnknownAction } from "@reduxjs/toolkit";

/**
 * Type guard factory that facilitates custom reducer matching when using Redux toolkit's`builder.addMatcher` function.
 * @param actionType The action type to filter by - only actions ending with this value will be included.
 * @returns A type guard function expected by `builder.addMatcher` that filters actions based on the `actionType` parameter.
 * @example
 * ```ts
	// Will only match actions ending with "pending"
 	builder.addMatcher(isActionOfType("pending"), (state) => {
	    state.status = "loading";
	});
 * ```
 */
export const isActionOfType =
   <P>(actionType: string) =>
   <T extends PayloadAction<P>>(action: UnknownAction): action is T =>
      action.type.endsWith(`${actionType}`);

/**
 * Type guard factory that facilitates custom reducer matching when using Redux toolkit's`builder.addMatcher` function.
 * @param filter A filter function that recieves the current action.
 * @returns A type guard function expected by `builder.addMatcher` that filters actions based on the provided filter function.
 * @example
 * ```ts
	// Will only match actions ending with "pending"
 	builder.addMatcher(isActionOf(a => a.type.startsWith("abc")), (state) => {
	    state.status = "loading";
	});
 * ```
 */
export const isActionOf =
   <P>(filter: (action: UnknownAction) => boolean) =>
   <T extends PayloadAction<P>>(action: UnknownAction): action is T =>
      filter(action);
