import { SearchResult } from "./search-result";
import { SearchDepartmentConfigurationItem, SearchItem, SearchServerConfig } from "../types";
import styles from "./search-results.module.scss";

interface SearchResultProps extends Pick<SearchServerConfig, "translations"> {
   highlights: SearchItem[];
   items: SearchItem[];
   departments: SearchDepartmentConfigurationItem[];
   activeDepartment?: string;
   onDepartmentSelect: (departmentId: string) => void;
   filterQuery?: string;
   onSearchResultClick?: () => void;
}

export const SearchResults = (props: SearchResultProps) => {
   const { highlights, items, translations, onSearchResultClick } = props;

   return (
      <>
         <div className={styles.resultsContainer}>
            {highlights.length > 0 && (
               <ul className={styles.highlights}>
                  <div className={styles.highlightsHeading}>{translations.trendingItemsTitle}</div>
                  {highlights.map((item, i: number) => (
                     <SearchResult
                        {...props}
                        key={i}
                        icon={item.icon}
                        phrase={item.phrase}
                        url={item.url}
                        itemType={item.type}
                        filterQuery={props.filterQuery}
                     />
                  ))}
               </ul>
            )}
            <ul>
               {items.map((item, i: number) => (
                  <SearchResult
                     {...props}
                     key={i}
                     icon={item.icon}
                     phrase={item.phrase}
                     url={item.url}
                     itemType={item.type}
                     filterQuery={props.filterQuery}
                     onClick={onSearchResultClick}
                  />
               ))}
            </ul>
         </div>
      </>
   );
};
