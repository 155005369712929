// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cEmcHnY1k4RfAANG1LUx{display:inline-flex;transition:transform 0.3s ease-in-out}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/my-pages/components/icon/icon.module.scss"],"names":[],"mappings":"AAAA,sBACG,mBAAoB,CACpB,qCAAsC","sourcesContent":[".icon {\r\n   display: inline-flex;\r\n   transition: transform 0.3s ease-in-out;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "cEmcHnY1k4RfAANG1LUx"
};
export default ___CSS_LOADER_EXPORT___;
