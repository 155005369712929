import { useEffect, useRef, useState } from "react";

export const useStickyFilterbar = () => {
   const containerRef = useRef(document.querySelector('.listing-options'));
   const [isVisible, setIsVisible] = useState(false);

   const toggleStickyFilter = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting || entry.boundingClientRect.top > 0) {
         disableStickyFilter();
      } else {
         setIsVisible(true);
         document.body.classList.add('has-sticky-filters');
      }
   };
   
   const disableStickyFilter = () => {
      setIsVisible(false);
      document.body.classList.remove('has-sticky-filters');
   }

   useEffect(() => {
      const observer = new IntersectionObserver(toggleStickyFilter, { root: null, threshold: 0 });
      if (containerRef.current) {
         observer.observe(containerRef.current);
      }

      return () => {
         if (containerRef.current) {
            observer.unobserve(containerRef.current);
         }
      };
   }, [containerRef]);

   return { isVisible, disableStickyFilter };
};
