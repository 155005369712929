// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".udS0sgCYLyBmd3mctSKB{z-index:202;position:fixed;min-width:calc(100vw - 24px);box-shadow:0px 4px 10px 0px rgba(0,0,0,0.1);opacity:0;visibility:hidden;transition:opacity 0s 0s;background-color:#fff}.udS0sgCYLyBmd3mctSKB.BzJChwSjwa8bYe30JuK1{opacity:1;visibility:visible;transition:opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1)}@media (min-width: 992px){.udS0sgCYLyBmd3mctSKB{position:absolute;min-width:390px;right:40px;top:calc(100% - 16px - 41px)}}@media (max-width: 992px){.udS0sgCYLyBmd3mctSKB{margin-left:12px;top:68px}.has-top-bar .udS0sgCYLyBmd3mctSKB{top:108px}}\n", "",{"version":3,"sources":["webpack://./Assets/src/features/search/search-wrapper/search-wrapper.module.scss"],"names":[],"mappings":"AAAA,sBACG,WAAY,CACZ,cAAe,CACf,4BAA6B,CAC7B,2CAA+C,CAC/C,SAAU,CACV,iBAAkB,CAClB,wBAAyB,CACzB,qBAAsB,CARzB,2CAWM,SAAU,CACV,kBAAmB,CACnB,oDAA+C,CAYlD,0BAzBH,sBA0BM,iBAAkB,CAClB,eAAgB,CAChB,UAAW,CAEX,4BAA6B,CAElC,CAlBG,0BAdJ,sBAiBM,gBAAiB,CACjB,QAAS,CAET,mCACG,SAAU,CACZ","sourcesContent":[".searchWrapper {\r\n   z-index: 202;\r\n   position: fixed;\r\n   min-width: calc(100vw - 24px);\r\n   box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);\r\n   opacity: 0;\r\n   visibility: hidden;\r\n   transition: opacity 0s 0s;\r\n   background-color: #fff;\r\n\r\n   &.isOpen {\r\n      opacity: 1;\r\n      visibility: visible;\r\n      transition: opacity .3s cubic-bezier(.4,0,.2,1);\r\n   }\r\n\r\n   @media(max-width: 992px) {\r\n      margin-left: 12px;\r\n      top: 68px;\r\n\r\n      :global(.has-top-bar) & {\r\n         top: 108px;\r\n      }\r\n   }\r\n\r\n   @media(min-width: 992px) {\r\n      position: absolute;\r\n      min-width: 390px;\r\n      right: 40px;\r\n      //16px is the padding of the header and 41px is the height of the search bar\r\n      top: calc(100% - 16px - 41px);\r\n   }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchWrapper": "udS0sgCYLyBmd3mctSKB",
	"isOpen": "BzJChwSjwa8bYe30JuK1"
};
export default ___CSS_LOADER_EXPORT___;
